import { AxiosResponse, AxiosError } from 'axios';
import Axios from '../Axios/Axios';
import { DeviceConfig } from '../../model/API/DeviceConfig/DeviceConfig';

export default class DeviceConfigRepository {
  public copyDeviceConfig = async (
    toDeviceNumber: string,
    fromDeviceNumber: string
  ): Promise<number> => {
    return await Axios.put(
      `/api/devices/${toDeviceNumber}/copy-config?fromDeviceNumber=${fromDeviceNumber}`
    )
      .then((response: AxiosResponse) => {
        return response.status;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  public getDeviceConfig = async (number: string): Promise<DeviceConfig> => {
    return await Axios.get(`/api/devices/${number}/config`).then(
      (response: AxiosResponse) => response.data
    );
  };
}
