import GridViewIcon from '@mui/icons-material/GridView';
import ArticleIcon from '@mui/icons-material/Article';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AlarmIcon from '@mui/icons-material/Alarm';
import DvrIcon from '@mui/icons-material/Dvr';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import InventoryIcon from '@mui/icons-material/Inventory';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import DevicesIcon from '@mui/icons-material/Devices';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SettingsIcon from '@mui/icons-material/Settings';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import BarChartIcon from '@mui/icons-material/BarChart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import InsightsIcon from '@mui/icons-material/Insights';
import ThreePIcon from '@mui/icons-material/ThreeP';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import TuneIcon from '@mui/icons-material/Tune';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import EuroIcon from '@mui/icons-material/Euro';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { GaEventAction } from '../../common/GaEventAction';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { MenuConfig } from '../../model/Navigation/MenuConfig';
import { PageIds } from '../../model/Navigation/PageIds';
import { MenuItem } from '../../model/Navigation/MenuItem';
import _ from 'lodash';

export const getUpdatedMenuListWithFeatureFlags = (
  enabledFeatureFlagsArray: string[],
  isMainMenu: boolean,
  hidePages: Partial<Record<PageIds, boolean>>
): MenuItem[] => {
  const updatedMenuConfig: MenuConfig = { ...MenuConfigList };
  const insertMenuItem = (
    featureFlag: string,
    newMenuItem: MenuItem,
    insertIndex: number
  ): void => {
    const isFeatureFlagOn = _.includes(enabledFeatureFlagsArray, featureFlag);

    if (isFeatureFlagOn) {
      const isMenuItemAlreadyAdded = _.some(updatedMenuConfig.mainMenu, { id: newMenuItem.id });

      if (isMenuItemAlreadyAdded === false) {
        updatedMenuConfig.mainMenu.splice(insertIndex, 0, newMenuItem);
      }
    }
  };

  insertMenuItem(
    'BILLING_REPORT_PAGE',
    {
      id: PageIds.LINK_BILLING_REPORT,
      name: 'link_billing_report',
      icon: ReceiptLongIcon,
      path: '/app/BillingReport',
      roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      ga_id: GaEventAction.MENU_BILLING_REPORT_PAGE_CLICK,
    },
    2
  );

  insertMenuItem(
    'NEW_GRAPH_PAGE',
    {
      id: PageIds.LINK_NEW_GRAPH,
      name: 'link_graph',
      icon: ShowChartIcon,
      path: '/app/newGraph',
      roles: [],
      ga_id: GaEventAction.MENU_NEW_GRAPH_PAGE_CLICK,
    },
    4
  );

  insertMenuItem(
    'LICENSE_MANAGEMENT_FEATURE_FLAG',
    {
      id: PageIds.LINK_LICENSES,
      name: 'link_licenses',
      icon: ReceiptIcon,
      path: '/app/Licenses',
      roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      ga_id: GaEventAction.MENU_MANAGE_ACCOUNTS_PAGE_CLICK,
    },
    5
  );

  _.remove(updatedMenuConfig.deviceMenu, (menuItem: MenuItem) => hidePages[menuItem.id]);
  _.remove(updatedMenuConfig.mainMenu, (menuItem: MenuItem) => hidePages[menuItem.id]);

  return isMainMenu ? updatedMenuConfig.mainMenu : updatedMenuConfig.deviceMenu;
};

export const MenuConfigList: MenuConfig = {
  mainMenu: [
    {
      id: PageIds.LINK_DASHBOARD,
      name: 'link_dashboard',
      icon: GridViewIcon,
      path: '/app',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
      id: PageIds.LINK_CUSTOMER_BILLING_REPORT,
      name: 'link_customer_billing_report',
      icon: SummarizeIcon,
      path: '/app/CustomerBillingReport',
      roles: ['ROLE_BILLING', 'ROLE_SUPER_ADMIN'],
      ga_id: GaEventAction.MENU_CUSTOMER_PAGE_CLICK,
    },
    {
      id: PageIds.LINK_REPORTS,
      name: 'link_reports',
      icon: ArticleIcon,
      path: '/app/ReportsExport',
      roles: [],
      ga_id: GaEventAction.MENU_REPORTS_PAGE_CLICK,
    },
    {
      id: PageIds.LINK_EXPORT,
      name: 'link_export',
      icon: FileOpenIcon,
      path: '/app/DeviceCountersExport',
      roles: [],
      ga_id: GaEventAction.MENU_EXPORT_PAGE_CLICK,
    },
    {
      id: PageIds.LINK_ALARMS,
      name: 'link_alarms',
      icon: AlarmIcon,
      path: '/app/AllAlarms',
      roles: [],
      ga_id: GaEventAction.MENU_ALL_ALARMS_PAGE_CLICK,
    },
    {
      id: PageIds.SUBMENU_DEVICES,
      name: 'submenu_devices',
      icon: DvrIcon,
      path: '',
      roles: [],
      subMenu: [
        {
          id: PageIds.LINK_DEVICES,
          name: 'link_devices',
          icon: DevicesIcon,
          path: '/app/Devices',
          roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_DEVICES_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_MAP,
          name: 'link_map',
          icon: PinDropIcon,
          path: '/app/DeviceLocation',
          roles: [],
          ga_id: GaEventAction.MENU_MAP_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_TRANSMISSIONS,
          name: 'link_transmissions',
          icon: PodcastsIcon,
          path: '/app/Transmissions',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_TRANSMISSIONS_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_INVENTORY,
          name: 'link_inventory',
          icon: InventoryIcon,
          path: '/app/DeviceInventory',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_INVENTORY_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_LINK_DEVICE,
          name: 'link_link_device',
          icon: InsertLinkIcon,
          path: '/app/LinkDevices',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_LINK_DEVICE_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_BATTERY,
          name: 'link_battery',
          icon: BatteryChargingFullIcon,
          path: '/app/DevicesBattery',
          roles: [],
        },
        {
          id: PageIds.LINK_SIGNAL,
          name: 'link_signal',
          icon: SignalWifi4BarIcon,
          path: '/app/DevicesSignal',
          roles: [],
        },
        {
          id: PageIds.LINK_DEVICE_GROUPS,
          name: 'device_groups',
          icon: FolderCopyIcon,
          path: '/app/DeviceGroups',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        },
      ],
    },
    {
      id: PageIds.SUBMENU_ACCOUNT,
      name: 'submenu_account',
      icon: PeopleIcon,
      path: '',
      roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      subMenu: [
        {
          id: PageIds.LINK_SHOW_USERS,
          name: 'link_show_users',
          icon: AccountCircleIcon,
          path: '/app/Users',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_USERS_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_ACCOUNTS,
          name: 'link_accounts',
          icon: SupervisedUserCircleIcon,
          path: '/app/Accounts',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_ACCOUNTS_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_UTILITY_RATE,
          name: 'link_utility_rate',
          icon: EuroIcon,
          path: '/app/UtilityRates',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_UTILITY_RATE_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_DEVICE_ACCOUNT_LOOKUP,
          name: 'link_device_account_lookup',
          icon: PersonSearchIcon,
          path: '/app/DeviceCompanyLookup',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        },
        {
          id: PageIds.LINK_ACCOUNTS_MANAGER,
          name: 'link_accounts_manager',
          icon: ManageAccountsIcon,
          path: '/app/AccountsManager',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_MANAGE_ACCOUNTS_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_ICONS,
          name: 'link_icons',
          icon: ModeEditIcon,
          path: '/app/Icons',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_MANAGE_ICONS_PAGE_CLICK,
        },
      ],
    },
  ],
  deviceMenu: [
    {
      id: PageIds.SUBMENU_DEVICE_REPORTS,
      name: 'submenu_device_reports',
      icon: PieChartOutlineIcon,
      path: '',
      roles: [],
      subMenu: [
        {
          id: PageIds.LINK_READINGS_DEVICE,
          name: 'link_readings',
          icon: BarChartIcon,
          path: '/app/DeviceCounters/{deviceNumber}',
          roles: [],
          ga_id: GaEventAction.MENU_READINGS_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_FLOW_DEVICE,
          name: 'link_flow',
          icon: QueryStatsIcon,
          path: '/app/DeviceCountersGraph/{deviceNumber}',
          roles: [],
          ga_id: GaEventAction.MENU_FLOW_GRAPH_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_NEW_GRAPH_DEVICE,
          name: 'link_new_graph',
          icon: ShowChartIcon,
          path: '/app/Graph/{deviceNumber}',
          roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        },
        {
          id: PageIds.LINK_STATISTICS_DEVICE,
          name: 'link_statistics',
          icon: StackedLineChartIcon,
          path: '/app/DeviceCountersStatistics/{deviceNumber}',
          roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        },
        {
          id: PageIds.LINK_DEVICE_PREDICTION_DEVICE,
          name: 'device_prediction',
          icon: InsightsIcon,
          path: '/app/DevicePrediction/{deviceNumber}',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        },
      ],
    },
    {
      id: PageIds.LINK_ALARMS_DEVICE,
      name: 'link_alarms',
      icon: AlarmIcon,
      path: '/app/Alarms/{deviceNumber}',
      roles: [],
    },
    {
      id: PageIds.LINK_MAP_DEVICE,
      name: 'link_map',
      icon: PinDropIcon,
      path: '/app/DeviceLocation/{deviceNumber}',
      roles: [],
    },
    {
      id: PageIds.LINK_CUSTOMER_DEVICE,
      name: 'link_customer',
      icon: ThreePIcon,
      path: '/app/CustomerInfo/{deviceNumber}',
      roles: [],
    },
    {
      id: PageIds.SUBMENU_SETTINGS,
      name: 'submenu_settings',
      icon: SettingsIcon,
      path: '',
      roles: [],
      subMenu: [
        {
          id: PageIds.LINK_ALARM_CONFIG_DEVICE,
          name: 'link_alarm_config',
          icon: EditNotificationsIcon,
          path: '/app/AlarmConfig/{deviceNumber}',
          roles: [],
          ga_id: GaEventAction.MENU_ALARM_CONFIG_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_CONFIG_DEVICE,
          name: 'link_config',
          icon: TuneIcon,
          path: '/app/DeviceConfig/{deviceNumber}',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_CONFIG_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_INITIAL_CONFIG_DEVICE,
          name: 'link_initial_config',
          icon: AppSettingsAltIcon,
          path: '/app/DeviceInitialConfig/{deviceNumber}',
          roles: [],
          ga_id: GaEventAction.MENU_INITIAL_CONFIG_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_SENSOR_CONFIG_DEVICE,
          name: 'link_sensor_config',
          icon: PermDataSettingIcon,
          path: '/app/SensorsConfig/{deviceNumber}',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_SENSOR_CONFIG_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_COMMANDS_DEVICE,
          name: 'link_commands',
          icon: IntegrationInstructionsIcon,
          path: '/app/Commands/{deviceNumber}',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_DEVICE_COMMANDS_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_COPY_CONFIG_DEVICE,
          name: 'link_copy_config',
          icon: ContentPasteIcon,
          path: '/app/CopyConfig/{deviceNumber}',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_DEVICE_COPY_CONFIG_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_SIGNAL_DEVICE,
          name: 'link_signal',
          icon: SignalWifi4BarIcon,
          path: '/app/DeviceSignals/{deviceNumber}',
          roles: [],
          ga_id: GaEventAction.MENU_DEVICE_SIGNAL_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_BATTERY_DEVICE,
          name: 'link_battery',
          icon: BatteryChargingFullIcon,
          path: '/app/DeviceBattery/{deviceNumber}',
          roles: [],
          ga_id: GaEventAction.MENU_DEVICE_BATTERY_PAGE_CLICK,
        },
        {
          id: PageIds.LINK_VALUE_RANGES_DEVICE,
          name: 'link_value_ranges',
          icon: LegendToggleIcon,
          path: '/app/EventValueRange/{deviceNumber}',
          roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          ga_id: GaEventAction.MENU_VALUE_RANGE_PAGE_CLICK,
        },
      ],
    },
  ],
};
