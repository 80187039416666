import React, { ReactNode, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { InputLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';

const FractionSelector = ({
  className,
  fractionOptions = [1, 2, 3, 4, 5, 6, 7],
  fraction,
  onFractionChange,
}: FractionSelectorProps): ReactNode => {
  const { t } = useTranslation();

  return (
    <div className={clsx(className && className)}>
      <InputLabel className="font-extrabold text-black">{t('device_digits_fraction')}</InputLabel>
      <ToggleButtonGroup color="primary" value={fraction} exclusive onChange={onFractionChange}>
        {fractionOptions.map((fractionOption: number) => {
          return <ToggleButton value={fractionOption}>{fractionOption}</ToggleButton>;
        })}
      </ToggleButtonGroup>
    </div>
  );
};

interface FractionSelectorProps {
  className?: string;
  fractionOptions?: number[];
  fraction: number;
  onFractionChange: (event: MouseEvent<HTMLElement>, value: number | null) => void;
}

export default FractionSelector;
