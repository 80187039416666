import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxState } from '../../../../../reducers';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useModal } from '@thingslog/ui-components';

const DeleteGroupModal: React.FC<DeleteGroupModalProps> = (props: DeleteGroupModalProps) => {
  const { deviceGroupToModify, handleOnDeleteDeviceGroup } = props;

  const { t } = useTranslation();
  const { closeModal } = useModal();

  const companyId = useSelector((state: ReduxState) => state.company.id);

  return (
    <div className="flex justify-end mt-6">
      <Button
        onClick={(): Promise<void> => handleOnDeleteDeviceGroup(deviceGroupToModify, companyId!)}
        autoFocus
      >
        {t<string>('device_groups_btn_delete')}
      </Button>
      <Button onClick={(): void => closeModal()}>{t<string>('device_groups_btn_cancel')}</Button>
    </div>
  );
};

interface DeleteGroupModalProps {
  deviceGroupToModify: string;
  handleOnDeleteDeviceGroup: (deviceGroupName: string, companyId: number) => Promise<void>;
}

export default DeleteGroupModal;
