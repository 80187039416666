import { useMutation, useQuery } from '@tanstack/react-query';

import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import {
  EventValueRange,
  EventValueRangeConfig,
  MutateEventValueRange,
  MutationResult,
  QueryResult,
} from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions, MutationOptions } from '@thingslog/repositories';

export interface EventValueRangeQueryClient {
  getEventValueRangesForDevice: (deviceNumber: string) => Promise<EventValueRangeConfig>;
  useDeviceEventValueRangesData: (
    deviceNumber: string,
    options?: QueryOptions<EventValueRangeConfig>
  ) => QueryResult<EventValueRangeConfig>;
  getSpecificEventValueRangeById: (
    deviceNumber: string,
    sensorIndex: string | number,
    valueRangeName: string
  ) => Promise<EventValueRange>;
  useEventValueRangeData: (
    deviceNumber: string,
    sensorIndex: string | number,
    valueRangeName: string,
    options?: QueryOptions<EventValueRange>
  ) => QueryResult<EventValueRange>;
  addValueRange: (
    deviceNumber: string,
    sensorIndex: number,
    body: MutateEventValueRange
  ) => Promise<EventValueRange>;
  useAddValueRangeData: (
    deviceNumber: string,
    sensorIndex: number,
    options?: MutationOptions<EventValueRange, MutateEventValueRange>
  ) => MutationResult<EventValueRange, MutateEventValueRange>;
  updateValueRange: (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string,
    body: MutateEventValueRange
  ) => Promise<EventValueRange>;
  useUpdateValueRangeData: (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string,
    options?: MutationOptions<EventValueRange, MutateEventValueRange>
  ) => MutationResult<EventValueRange, MutateEventValueRange>;
  batchAddValueRanges: (
    deviceNumber: string,
    sensorIndex: number,
    body: EventValueRange[]
  ) => Promise<void>;
  useBatchAddValueRangesData: (
    options?: MutationOptions<void, BatchCreateValueRangeParams>
  ) => MutationResult<void, BatchCreateValueRangeParams>;
  updateValueRangePriority: (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string,
    direction: 'UP' | 'DOWN'
  ) => Promise<void>;
  useUpdateValueRangePriority: (
    options?: MutationOptions<void, UpdateValueRangePriorityParams>
  ) => MutationResult<void, UpdateValueRangePriorityParams>;
  deleteValueRange: (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string
  ) => Promise<void>;
  useDeleteValueRangeData: (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string,
    options?: MutationOptions<void, void>
  ) => MutationResult<void, void>;
}

export function createEventValueRangeQueryClient(axios: AxiosInstance): EventValueRangeQueryClient {
  return new EventValueRangeQueryClientImp(axios);
}

class EventValueRangeQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getEventValueRangesForDevice = async (
    deviceNumber: string
  ): Promise<EventValueRangeConfig> => {
    return await this.axios
      .get(`/api/devices/${deviceNumber}/event-value-ranges`)
      .then((response: AxiosResponse) => response.data);
  };

  public useDeviceEventValueRangesData = (
    deviceNumber: string,
    options?: QueryOptions<EventValueRangeConfig>
  ): QueryResult<EventValueRangeConfig> => {
    return useQuery<EventValueRangeConfig, AxiosError>(
      [QueryKeys.EventValueRangesForDevice, deviceNumber],
      () => this.getEventValueRangesForDevice(deviceNumber),
      options
    );
  };

  public getSpecificEventValueRangeById = async (
    deviceNumber: string,
    sensorIndex: string | number,
    valueRangeName: string | number
  ): Promise<EventValueRange> => {
    const encodedValueRangeName = encodeURIComponent(valueRangeName);
    return await this.axios
      .get(
        `/api/devices/${deviceNumber}/sensors/${sensorIndex}/event-value-ranges/${encodedValueRangeName}`
      )
      .then((response: AxiosResponse) => response.data);
  };

  public useEventValueRangeData = (
    deviceNumber: string,
    sensorIndex: string | number,
    valueRangeName: string,
    options?: QueryOptions<EventValueRange>
  ): QueryResult<EventValueRange> => {
    return useQuery<EventValueRange, AxiosError>(
      [QueryKeys.SpecificEventValueRange, deviceNumber, sensorIndex, valueRangeName],
      () => this.getSpecificEventValueRangeById(deviceNumber, sensorIndex, valueRangeName),
      options
    );
  };

  public addValueRange = async (
    deviceNumber: string,
    sensorIndex: number,
    body: MutateEventValueRange
  ): Promise<EventValueRange> => {
    return await this.axios
      .post(`/api/devices/${deviceNumber}/sensors/${sensorIndex}/event-value-ranges`, body)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public useAddValueRangeData = (
    deviceNumber: string,
    sensorIndex: number,
    options?: MutationOptions<EventValueRange, MutateEventValueRange>
  ): MutationResult<EventValueRange, MutateEventValueRange> => {
    return useMutation<EventValueRange, AxiosError, MutateEventValueRange>(
      [QueryKeys.AddValueRange, deviceNumber, sensorIndex],
      (variables: MutateEventValueRange) =>
        this.addValueRange(deviceNumber, sensorIndex, variables),
      options
    );
  };

  public updateValueRange = async (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string,
    body: MutateEventValueRange
  ): Promise<EventValueRange> => {
    const encodedValueRangeName = encodeURIComponent(valueRangeName);
    return await this.axios
      .put(
        `/api/devices/${deviceNumber}/sensors/${sensorIndex}/event-value-ranges/${encodedValueRangeName}`,
        body
      )
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public useUpdateValueRangeData = (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string,
    options?: MutationOptions<EventValueRange, MutateEventValueRange>
  ): MutationResult<EventValueRange, MutateEventValueRange> => {
    return useMutation<EventValueRange, AxiosError, MutateEventValueRange>(
      [QueryKeys.UpdateValueRange, deviceNumber, sensorIndex, valueRangeName],
      (variables: MutateEventValueRange) =>
        this.updateValueRange(deviceNumber, sensorIndex, valueRangeName, variables),
      options
    );
  };

  public batchAddValueRanges = async (
    deviceNumber: string,
    sensorIndex: number,
    body: EventValueRange[]
  ): Promise<void> => {
    return await this.axios
      .put(`/api/devices/${deviceNumber}/sensors/${sensorIndex}/event-value-ranges/default`, body)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public useBatchAddValueRangesData = (
    options?: MutationOptions<void, BatchCreateValueRangeParams>
  ): MutationResult<void, BatchCreateValueRangeParams> => {
    return useMutation<void, AxiosError, BatchCreateValueRangeParams>(
      [QueryKeys.BatchAddValueRanges],
      (variables: BatchCreateValueRangeParams) =>
        this.batchAddValueRanges(
          variables.deviceNumber,
          variables.sensorIndex,
          variables.valueRanges
        ),
      options
    );
  };

  public updateValueRangePriority = async (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string,
    direction: 'UP' | 'DOWN'
  ): Promise<void> => {
    const encodedValueRangeName = encodeURIComponent(valueRangeName);
    return await this.axios
      .put(
        `/api/devices/${deviceNumber}/sensors/${sensorIndex}/event-value-ranges/${encodedValueRangeName}/priority`,
        undefined,
        { params: { direction: direction } }
      )
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public useUpdateValueRangePriority = (
    options?: MutationOptions<void, UpdateValueRangePriorityParams>
  ): MutationResult<void, UpdateValueRangePriorityParams> => {
    return useMutation<void, AxiosError, UpdateValueRangePriorityParams>(
      [QueryKeys.UpdateValueRangePriority],
      (variables: UpdateValueRangePriorityParams) =>
        this.updateValueRangePriority(
          variables.deviceNumber,
          variables.sensorIndex,
          variables.valueRangeName,
          variables.direction
        ),
      options
    );
  };

  public deleteValueRange = async (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string
  ): Promise<void> => {
    const encodedValueRangeName = encodeURIComponent(valueRangeName);
    return await this.axios
      .delete(
        `/api/devices/${deviceNumber}/sensors/${sensorIndex}/event-value-ranges/${encodedValueRangeName}`
      )
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public useDeleteValueRangeData = (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string,
    options?: MutationOptions<void, void>
  ): MutationResult<void, void> => {
    return useMutation<void, AxiosError, void>(
      [QueryKeys.DeleteValueRange, deviceNumber, sensorIndex, valueRangeName],
      () => this.deleteValueRange(deviceNumber, sensorIndex, valueRangeName),
      options
    );
  };
}

interface UpdateValueRangePriorityParams {
  deviceNumber: string;
  sensorIndex: number;
  valueRangeName: string;
  direction: 'UP' | 'DOWN';
}

interface BatchCreateValueRangeParams {
  deviceNumber: string;
  sensorIndex: number;
  valueRanges: EventValueRange[];
}
