import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { FlowTimeUnits } from '../../model/FlowTimeUnits/FlowTimeUnits';
import { MeasurementUnits } from '../../model/MeasurementUnits/MeasurementUnits';

import {
  MenuItem,
  Select,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  SelectChangeEvent,
} from '@mui/material';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { ViewProps } from 'react-device-detect';

const FlowConfiguration: React.FC<FlowConfigurationProps> = (props: FlowConfigurationProps) => {
  const {
    handleFlowUnitsChanged,
    handleFlowTimeUnitsChanged,
    handleFlowCoefficientChanged,
    sensorIndex,
    coefficient,
    flowTimeUnit,
    flowUnit,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <ControlLabel>{t('flow_configuration_label')}</ControlLabel>
      <FormGroup id="Flow Configuration">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControl id="Flow Units" fullWidth size="small">
              <InputLabel id="flow-units-select-label">{t('flow_measurement_units')}</InputLabel>
              <Select
                value={flowUnit}
                id="flow-units"
                label={t('flow_measurement_units')}
                labelId="flow-units-select-label"
                onChange={(event: SelectChangeEvent): void =>
                  handleFlowUnitsChanged(sensorIndex, event.target.value)
                }
                endAdornment={
                  flowUnit ? (
                    <IconButton onClick={(): void => handleFlowUnitsChanged(sensorIndex, null)}>
                      <ClearIcon />
                    </IconButton>
                  ) : null
                }
                style={{ textAlign: 'left' }}
              >
                {Object.keys(MeasurementUnits)
                  .sort()
                  .map((keyName: string, keyIndex: number) => (
                    <MenuItem value={keyName} key={keyName} id={keyName}>
                      {MeasurementUnits[keyName]}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl id="Flow Time Units" fullWidth size="small">
              <InputLabel id="flow-time-units-select-label">{t('flow_time_units')}</InputLabel>
              <Select
                disabled={!flowUnit}
                value={flowTimeUnit}
                id="flow-time-units"
                label={t('flow_time_units')}
                labelId="flow-time-units-select-label"
                onChange={(event: SelectChangeEvent): void =>
                  handleFlowTimeUnitsChanged(sensorIndex, event.target.value)
                }
                endAdornment={
                  flowTimeUnit ? (
                    <IconButton onClick={(): void => handleFlowTimeUnitsChanged(sensorIndex, null)}>
                      <ClearIcon />
                    </IconButton>
                  ) : null
                }
                style={{ textAlign: 'left' }}
              >
                {Object.keys(FlowTimeUnits).map((keyName: string, keyIndex: number) => (
                  <MenuItem value={keyName} key={keyName} id={keyName}>
                    {FlowTimeUnits[keyName]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl id="Coefficient" fullWidth size="small">
              <TextField
                required={flowTimeUnit !== FlowTimeUnits.HOURS && flowTimeUnit ? true : false}
                value={coefficient}
                id="outlined-basic"
                label={t('flow_coefficient')}
                variant="outlined"
                size="small"
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  handleFlowCoefficientChanged(sensorIndex, event)
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </FormGroup>
    </>
  );
};

interface FlowConfigurationProps {
  flowTimeUnit?: FlowTimeUnits;
  flowUnit?: MeasurementUnits;
  coefficient?: Number;
  sensorIndex: Number;
  handleFlowCoefficientChanged: (
    sensorIndex: Number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleFlowTimeUnitsChanged: (sensorIndex: Number, event: string | null) => void;
  handleFlowUnitsChanged: (sensorIndex: Number, event: string | null) => void;
}

export default FlowConfiguration;
