import { Theme } from '@mui/material';
import { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { cyan } from '@mui/material/colors';

export const styles: StyleRulesCallback<Theme, any> = (theme: Theme) =>
  createStyles({
    grid: {
      width: 400,
    },
    paper: {
      padding: 20,
    },
  });
