import * as actions from './../../actions/index';

import { Button, CircularProgress, Container } from '@mui/material';
import { GridFilterItem, GridFilterModel } from '@thingslog/ui-components/src/DataGrid';
import { GridToolbar, useToast } from '@thingslog/ui-components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BillingReportTable from './BillingReportTable';
import Header from '../../components/header';
import JwtValidator from '../../common/JwtValidator';
import PeriodPicker from '../../components/DatePicker/PeriodPicker';
import { ReduxState } from '../../reducers';
import { clientCountQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { useTranslation } from 'react-i18next';

const BillingReport: FC<BillingReportProps> = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const dispatch = useDispatch();

  const { useClientCount } = useMemo(() => clientCountQueryClient, []);
  const { decodedToken } = useMemo(() => new JwtValidator(), []);

  const [companyId, setCompanyId] = useState<number | null>(null);

  const companyIdRedux = useSelector((state: ReduxState) => state.company.id);
  const toDate = useSelector((state: ReduxState) => state.period.toDateTz);
  const fromDate = useSelector((state: ReduxState) => state.period.fromDateTz);
  const dataGridFilters: GridFilterItem[] = useSelector(
    (state: ReduxState) => state.dev.dataGridFilters
  );
  useEffect(() => {
    if (companyIdRedux) {
      setCompanyId(companyIdRedux);
    } else if (decodedToken?.companyId) {
      setCompanyId(Number(decodedToken.companyId));
    } else {
      setCompanyId(null);
    }
  }, [companyIdRedux, decodedToken]);

  const handleOnFilterModelChange = (model: GridFilterModel): void => {
    if (model.items.some((item: GridFilterItem) => item.value !== '')) {
      dispatch(actions.filterChanged(model.items));
    } else {
      dispatch(actions.filterChanged([]));
    }
  };

  const {
    refetch: refetchClientCount,
    isInitialLoading: isClientCountInitLoading,
    isFetching: isClientCountFetching,
    data: clientCountData,
  } = useClientCount(companyId, fromDate, toDate, {
    onError: () => {
      toast({
        type: 'error',
        message: t('billing_report_error'),
      });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <Header>
      <Container>
        <section className="flex justify-center mb-6">
          <div className="text-2xl font-bold">{t('billing_report_header')}</div>
        </section>
        <section className="flex justify-between flex-wrap mb-5">
          <div className="flex flex-wrap md:space-x-2">
            <PeriodPicker alwaysShowDatePickers />
            <Button
              className="mt-2 md:mt-0"
              variant="contained"
              color="primary"
              disableElevation
              startIcon={
                isClientCountInitLoading || isClientCountFetching ? (
                  <CircularProgress size={20} />
                ) : null
              }
              onClick={(): void => {
                refetchClientCount();
              }}
              disabled={isClientCountInitLoading || isClientCountFetching}
            >
              {t('billing_report_btn_generate')}
            </Button>
          </div>
        </section>

        <BillingReportTable
          autoHeight={true}
          clientCountData={clientCountData ? clientCountData : null}
          headerHeight={70}
          filterModel={{ items: dataGridFilters }}
          onFilterModelChange={handleOnFilterModelChange}
          components={{ Toolbar: GridToolbar }}
          density="compact"
          pagination
          checkboxSelection
        />
      </Container>
    </Header>
  );
};

export default BillingReport;
interface BillingReportProps {}
