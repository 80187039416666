import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { MutationOptions, MutationResult, PortsConfig, QueryResult } from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions } from '@thingslog/repositories';

export function createInitialConfigQueryClient(axios: AxiosInstance): InitialConfigQueryClient {
  return new InitialConfigQueryClientImp(axios);
}

class InitialConfigQueryClientImp {
  public constructor(private axios: AxiosInstance) {}
  public useGetDevicesPortsConfigsBatch = (
    options?: MutationOptions<Record<string, PortsConfig>, string[]>
  ): MutationResult<Record<string, PortsConfig>, string[]> => {
    return useMutation<Record<string, PortsConfig>, AxiosError, string[]>(
      [QueryKeys.GetDevicesPortsConfigsBatch],
      (props: string[]) => this.getDevicesPortsConfigsBatch(props),
      options
    );
  };

  public useDevicePortsConfigData = (
    deviceNumber: string,
    options?: QueryOptions<PortsConfig>
  ): QueryResult<PortsConfig> => {
    return useQuery<PortsConfig, AxiosError>(
      [QueryKeys.DevicePortsConfig, deviceNumber],
      () => this.getDevicePortsConfig(deviceNumber),
      options
    );
  };

  public getDevicePortsConfig = async (deviceNumber: string): Promise<PortsConfig> => {
    return await this.axios
      .get(`/api/devices/${deviceNumber}/initial-config/ports`)
      .then((response: AxiosResponse) => response.data);
  };

  public getDevicesPortsConfigsBatch = async (
    deviceNumbers: string[]
  ): Promise<Record<string, PortsConfig>> => {
    return await this.axios
      .post(`/api/devices/batch/initial-config/ports`, deviceNumbers)
      .then((response: AxiosResponse) => response.data);
  };
}

export interface InitialConfigQueryClient {
  useDevicePortsConfigData: (
    deviceNumber: string,
    options?: QueryOptions<PortsConfig>
  ) => QueryResult<PortsConfig>;
  useGetDevicesPortsConfigsBatch: (
    options?: MutationOptions<Record<string, PortsConfig>, string[]>
  ) => MutationResult<Record<string, PortsConfig>, string[]>;

  getDevicePortsConfig: (deviceNumber: string) => Promise<PortsConfig>;
  getDevicesPortsConfigsBatch: (deviceNumbers: string[]) => Promise<Record<string, PortsConfig>>;
}
