import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import { useStyles } from '../../DeviceGroupsStyles.styles';
import { useTranslation } from 'react-i18next';
import * as client from '../../../../clients/DeviceGroup/DeviceGroupClient';
import AddGroupModal from './modals/AddGroupModal';
import DeleteGroupModal from './modals/DeleteGroupModal';
import EditGroupModal from './modals/EditGroupModal';

import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DeviceGroup, { DeviceSensor } from '../../../../model/API/DeviceGroup/DeviceGroup';
import { ReduxState } from '../../../../reducers';
import { useModal } from '@thingslog/ui-components';

//Component
const DeviceGroupTable: React.FC<DeviceGroupTableProps> = (props: DeviceGroupTableProps) => {
  const { selectedDeviceGroupName, deviceGroups, showErrorMessage, selectGroup, refreshTable } =
    props;
  const companyId = useSelector((state: ReduxState) => state.company.id);

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { modal, closeModal } = useModal();

  //Device groups states
  const [deviceGroupsPage, setDeviceGroupsPage] = React.useState<number>(0);
  const [deviceGroupsPageSize, setDeviceGroupsPageSize] = React.useState<number>(15);
  const [displayedDeviceGroups, setDisplayedDeviceGroups] = React.useState<DeviceGroup[]>([]);

  useEffect(() => {
    if (deviceGroups && companyId) {
      setDisplayedDeviceGroups(
        paginateDeviceGroups(deviceGroups, deviceGroupsPage, deviceGroupsPageSize)
      );
    }
  }, [deviceGroups]);

  //Device groups table related funcions
  const paginateDeviceGroups = (
    array: DeviceGroup[],
    page: number,
    size: number
  ): DeviceGroup[] => {
    return array.slice(page * size).slice(0, size);
  };

  const changeDeviceGroupsPage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    changeDisplayedDeviceGroups(page, deviceGroupsPageSize);
  };

  const changeRowsPerDeviceGroupsPage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    changeDisplayedDeviceGroups(0, Number(e.target.value));
  };

  const changeDisplayedDeviceGroups = (page: number, size: number): void => {
    setDeviceGroupsPage(page);
    setDeviceGroupsPageSize(size);
    setDisplayedDeviceGroups(paginateDeviceGroups(deviceGroups, page, size));
  };

  const handleAddDeviceGroupClick = (): void => {
    modal({
      title: t('device_groups_add_device_group'),
      content: (
        <div className="md:w-[400px] overflow-y-auto" style={{ maxHeight: '80vh' }}>
          <AddGroupModal
            handleOnCreateDeviceGroup={handleOnCreateDeviceGroup}
            deviceGroups={deviceGroups}
          />
        </div>
      ),
    });
  };

  const updateDeviceGroup = (deviceGroup: DeviceGroup): void => {
    modal({
      title: t('device_groups_edit_device_group'),
      content: (
        <div className="md:w-[400px] overflow-y-auto" style={{ maxHeight: '80vh' }}>
          <EditGroupModal
            deviceGroupToModify={deviceGroup.deviceGroupName}
            parentDeviceGroup={deviceGroup.parentDeviceGroupName}
            handleOnUpdateDeviceGroup={handleOnUpdateDeviceGroup}
            deviceGroups={deviceGroups}
            selectedDeviceGroupColor={deviceGroup.deviceGroupColor}
          />
        </div>
      ),
    });
  };

  const deleteDeviceGroup = (deviceGroup: DeviceGroup): void => {
    modal({
      title: `${t('device_groups_delete_device_group')}: ${deviceGroup.deviceGroupName}`,
      content: (
        <div className="md:w-[400px] overflow-y-auto" style={{ maxHeight: '80vh' }}>
          <DeleteGroupModal
            deviceGroupToModify={deviceGroup.deviceGroupName}
            handleOnDeleteDeviceGroup={handleOnDeleteDeviceGroup}
          />
        </div>
      ),
    });
  };

  //Client calls
  const handleOnCreateDeviceGroup = async (deviceGroup: DeviceGroup): Promise<void> => {
    try {
      const createDeviceGroupResponse = await client.createDeviceGroup(deviceGroup);
      refreshTable(companyId!);
      closeModal();
    } catch (error) {
      let errorResponse = client.handleError(error);
      showErrorMessage(errorResponse, true);
    }
  };

  const handleOnDeleteDeviceGroup = async (
    deviceGroupName: string,
    companyId: number
  ): Promise<void> => {
    const response = await client.deleteDeviceGroup(deviceGroupName, companyId);
    refreshTable(companyId);
    closeModal();
    selectGroup(null, undefined);
  };

  const handleOnUpdateDeviceGroup = async (
    deviceGroupToUpdate: string,
    deviceGroup: DeviceGroup
  ): Promise<void> => {
    const createUserResponse = await client.updateDeviceGroup(deviceGroupToUpdate, deviceGroup);
    refreshTable(companyId!);
    closeModal();
  };

  return (
    <>
      <TableContainer style={{ marginRight: 20 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.tableHeader}>
                <Typography variant="subtitle1">
                  <Box fontWeight={600}>
                    {t<string>('device_groups_table_sensor_group_name_header')}
                  </Box>
                </Typography>
              </TableCell>
              <TableCell size="small" className={classes.tableHeader}>
                <Typography variant="subtitle1">
                  <Box fontWeight={600}>
                    {t<string>('device_groups_table_parent_group_name_header')}
                  </Box>
                </Typography>
              </TableCell>
              <TableCell size="small" className={classes.tableHeader} align="center">
                <Typography variant="subtitle1">
                  <Box fontWeight={600}>{t<string>('device_groups_table_device_count_header')}</Box>
                </Typography>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                className={`${classes.inlineFlexIcons} ${classes.tableHeader}`}
              >
                <Typography variant="subtitle1">
                  <Tooltip
                    title={<span>{t<string>('device_groups_btn_add')}</span>}
                    arrow
                    placement="top"
                  >
                    <IconButton aria-label="Add" size="large" onClick={handleAddDeviceGroupClick}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedDeviceGroups.map((deviceGroup: DeviceGroup) => {
              return (
                <TableRow
                  hover
                  className={
                    selectedDeviceGroupName == deviceGroup.deviceGroupName
                      ? classes.selectedDeviceGroupRow
                      : ''
                  }
                  key={deviceGroup.deviceGroupName}
                  onClick={(): void =>
                    selectGroup(deviceGroup.deviceGroupName, deviceGroup.deviceSensors)
                  }
                >
                  <TableCell size="small">
                    <Tooltip title={deviceGroup.deviceGroupName} arrow placement="top">
                      <Typography variant="subtitle1">{deviceGroup.deviceGroupName}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell size="small">
                    <Tooltip
                      title={
                        deviceGroup.parentDeviceGroupName
                          ? deviceGroup.parentDeviceGroupName
                          : 'None'
                      }
                      arrow
                      placement="top"
                    >
                      <Typography variant="subtitle1">
                        {deviceGroup.parentDeviceGroupName
                          ? deviceGroup.parentDeviceGroupName
                          : 'None'}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <Tooltip
                      title={deviceGroup.deviceSensors ? deviceGroup.deviceSensors.length : 0}
                      arrow
                      placement="top"
                    >
                      <Typography variant="subtitle1">
                        {deviceGroup.deviceSensors ? deviceGroup.deviceSensors.length : 0}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell size="small">
                    <Box className={classes.inlineFlexIcons}>
                      <Tooltip
                        title={<span>{t<string>('device_groups_btn_edit')}</span>}
                        arrow
                        placement="top"
                      >
                        <IconButton
                          className={classes.editBtn}
                          aria-label="Update"
                          size="large"
                          onClick={(): void => updateDeviceGroup(deviceGroup)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={<span>{t<string>('device_groups_btn_delete')}</span>}
                        arrow
                        placement="top"
                      >
                        <IconButton
                          aria-label="Delete"
                          size="large"
                          onClick={(): void => deleteDeviceGroup(deviceGroup)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          style={{ marginRight: 35 }}
          rowsPerPageOptions={[10, 15, 25, 50, 100]}
          component="div"
          count={deviceGroups.length}
          rowsPerPage={deviceGroupsPageSize}
          page={deviceGroupsPage}
          onPageChange={changeDeviceGroupsPage}
          onRowsPerPageChange={changeRowsPerDeviceGroupsPage}
        />
      </TableContainer>
    </>
  );
};

interface DeviceGroupTableProps {
  selectedDeviceGroupName: string | null;
  deviceGroups: DeviceGroup[];
  selectGroup: (groupName: string | null, sensors: DeviceSensor[] | undefined) => void;
  showErrorMessage: (errorMessage: string, showError: boolean) => void;
  refreshTable: (companyId: number) => void;
}

export default DeviceGroupTable;
