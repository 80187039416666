import { Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  tableHeaderCell: {
    backgroundColor: 'lightgray',
  },
  actionButtons: {
    width: 115,
  },
  addUserTitle: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  addUserDialog: {
    width: 500,
    borderRadius: '25px',
  },
  editUserTitle: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  editUserDialog: {
    width: 500,
    borderRadius: '25px',
  },
  inlineFlexItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inlineFlexIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  closeModalIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  selectedDeviceGroupRow: {
    backgroundColor: theme.palette.grey[200],
  },
  selectDeviceGroupMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editBtn: {
    marginLeft: '-2px',
    marginRight: '5px',
  },
  sensorIndexTypography: {
    width: 80,
  },
  hiddenAddIcon: {
    visibility: 'hidden',
  },
}));
