export const checkUserRoleForGetDevicesPermissions = (
  companyId: number | null,
  isSuperAdmin: boolean
): boolean => {
  if (isSuperAdmin) {
    return companyId ? true : false;
  }

  return true;
};

export const getSelectedCompanyOrAccoutCompanyId = (
  companyId: number | null,
  decodedTokenCompanyId: number,
  isSuperAdmin: boolean,
  isAdmin: boolean
): number | null => {
  if (isSuperAdmin) {
    return companyId ? companyId : null;
  } else if (isAdmin) {
    return companyId ? companyId : decodedTokenCompanyId;
  } else {
    return decodedTokenCompanyId;
  }
};
