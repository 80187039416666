import React, { Dispatch, SetStateAction, useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import { useStyles } from '../../DeviceGroupsStyles.styles';
import { useTranslation } from 'react-i18next';
import * as client from '../../../../clients/DeviceGroup/DeviceGroupClient';
import DeleteSensorModal from './modals/DeleteSensorModal';
import AddSensorModal from './modals/AddSensorModal';

import {
  Box,
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  Snackbar,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeviceSensor } from '../../../../model/API/DeviceGroup/DeviceGroup';
import { ReduxState } from '../../../../reducers';

//Component
const SensorsTable: React.FC<SensorsTableProps> = (props: SensorsTableProps) => {
  const { sensorsByDeviceGroup, selectedDeviceGroupName, refreshTable, showErrorMessage } = props;
  const companyId = useSelector((state: ReduxState) => state.company.id);
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  //Sensors by group states
  const [sensorsByGroup, setSensorsByGroup] = React.useState<DeviceSensor[] | undefined>([]);
  const [sensorsByGroupPage, setSensorsByGroupPage] = React.useState<number>(0);
  const [sensorsByGroupPageSize, setSensorsByGroupPageSize] = React.useState<number>(15);
  const [displayedSensorsByGroup, setDisplayedSensorsByGroup] = React.useState<
    DeviceSensor[] | undefined
  >([]);
  const [selectedSensor, setSelectedSensor] = React.useState<DeviceSensor>({
    deviceNumber: '',
    sensorIndex: 0,
    sensorName: '',
  });

  //Modal States
  const [isOpenedDeleteSensorModal, setIsOpenedDeleteSensorModal] = React.useState<boolean>(false);
  const [isOpenedAddSensorModal, setIsOpenedAddSensorModal] = React.useState<boolean>(false);

  //Dropdown sensor modal arrays
  const [devicesFromCompany, setDevicesFromCompany] = React.useState<string[]>([]);
  //TODO - #190 ticket for interface
  const [devicesConfigs, setDevicesConfigs] = React.useState<any>([]);

  useEffect(() => {
    setSensorsByGroup(sensorsByDeviceGroup);
    setDisplayedSensorsByGroup(
      paginateSensorsByGroup(sensorsByDeviceGroup, sensorsByGroupPage, sensorsByGroupPageSize)
    );
  }, [sensorsByDeviceGroup]);

  useEffect(() => {
    loadDevicesFromServer(companyId!);
  }, [companyId]);

  const loadDevicesFromServer = async (companyId: number): Promise<void> => {
    let getDevicesResponse = await client.getAllDevices(companyId);
    setDevicesConfigs(getDevicesResponse.content);

    let devicesList = getDevicesResponse.content.map((device: any) => {
      return device.number;
    });

    setDevicesFromCompany(devicesList);
  };

  //Devices by group table related functions
  const paginateSensorsByGroup = (
    array: DeviceSensor[] | undefined,
    page: number,
    size: number
  ): DeviceSensor[] => {
    if (array) {
      return array.slice(page * size).slice(0, size);
    } else {
      return [];
    }
  };

  const changeSensorsPerGroupPage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    changeDisplayedSensorsByGroup(page, sensorsByGroupPageSize);
  };

  const changeRowsPerSensorByGroup = (e: React.ChangeEvent<HTMLInputElement>): void => {
    changeDisplayedSensorsByGroup(0, Number(e.target.value));
  };

  const changeDisplayedSensorsByGroup = (page: number, size: number): void => {
    setSensorsByGroupPage(page);
    setSensorsByGroupPageSize(size);
    setDisplayedSensorsByGroup(paginateSensorsByGroup(sensorsByGroup, page, size));
  };

  //Event handlers
  const handleOnCloseModal = (): void => {
    setIsOpenedAddSensorModal(false);
    setIsOpenedDeleteSensorModal(false);
  };

  const addSensorClick = (): void => {
    setIsOpenedAddSensorModal(true);
  };

  const deleteSensor = (sensor: DeviceSensor): void => {
    setSelectedSensor(sensor);
    setIsOpenedDeleteSensorModal(true);
  };

  //Client calls
  const handleOnDeleteDeviceAndSensorFromGroup = async (
    companyId: number,
    deviceGroupName: string,
    deviceNumber: string,
    sensorIndex: number
  ): Promise<void> => {
    const response = await client.deleteDeviceAndSensorFromGroup(
      companyId,
      deviceGroupName,
      deviceNumber,
      sensorIndex
    );
    handleOnCloseModal();
    refreshTable(companyId);
  };

  const handleOnCreateSensorDeviceToGroup = async (
    companyId: number,
    deviceGroupName: string | null,
    deviceNumber: string | undefined,
    sensorIndex: number | undefined
  ): Promise<void> => {
    if (deviceGroupName !== null) {
      try {
        const response = await client.createDeviceAndSensorToGroup(
          companyId,
          deviceGroupName,
          deviceNumber!,
          sensorIndex!
        );
        handleOnCloseModal();
        refreshTable(companyId);
      } catch (error) {
        let errorResponse = client.handleError(error);
        showErrorMessage(errorResponse, true);
      }
    }
  };
  return (
    <>
      <TableContainer style={{ marginLeft: 20 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.tableHeader}>
                <Typography variant="subtitle1">
                  <Box fontWeight={600}>
                    {t<string>('device_groups_sensor_table_device_number_header')}
                  </Box>
                </Typography>
              </TableCell>
              <TableCell size="small" className={classes.tableHeader}>
                <Typography variant="subtitle1">
                  <Box fontWeight={600}>
                    {t<string>('device_groups_sensor_table_sensor_name_header')}
                  </Box>
                </Typography>
              </TableCell>
              <TableCell size="small" className={classes.tableHeader}>
                <Typography variant="subtitle1">
                  <Box fontWeight={600}>
                    {t<string>('device_groups_sensor_table_sensor_index_header')}
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                className={`${classes.inlineFlexIcons} ${classes.tableHeader}`}
                style={{ paddingLeft: 9 }}
              >
                <Typography variant="subtitle1">
                  <Tooltip
                    title={<span>{t<string>('device_groups_btn_add')}</span>}
                    arrow
                    placement="top"
                  >
                    <IconButton
                      aria-label="Add"
                      size="large"
                      onClick={addSensorClick}
                      disabled={selectedDeviceGroupName !== null ? false : true}
                    >
                      <AddIcon
                        className={clsx(null, {
                          [classes.hiddenAddIcon]: selectedDeviceGroupName == null,
                        })}
                      />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sensorsByGroup &&
              sensorsByGroup.map((sensorDeviceGroup: DeviceSensor) => {
                return (
                  <TableRow hover>
                    <TableCell size="small">
                      <Tooltip title={sensorDeviceGroup.deviceNumber} arrow placement="top">
                        <Typography variant="subtitle1">
                          {sensorDeviceGroup.deviceNumber}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell size="small">
                      <Tooltip title={sensorDeviceGroup.sensorName} arrow placement="top">
                        <Typography variant="subtitle1">{sensorDeviceGroup.sensorName}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell size="small" align="center">
                      <Tooltip title={sensorDeviceGroup.sensorIndex} arrow placement="top">
                        <Typography variant="subtitle1" className={classes.sensorIndexTypography}>
                          {sensorDeviceGroup.sensorIndex}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell size="small" align="center">
                      <Box className={classes.inlineFlexIcons}>
                        <Tooltip
                          title={<span>{t<string>('device_groups_btn_delete')}</span>}
                          arrow
                          placement="top"
                        >
                          <IconButton
                            aria-label="Delete"
                            size="large"
                            onClick={(): void => deleteSensor(sensorDeviceGroup)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {selectedDeviceGroupName === null ? (
          <>
            <Box marginTop={20} marginBottom={20} className={classes.selectDeviceGroupMessage}>
              <Typography variant="h5">
                {t<string>('device_groups_sensor_table_select_message')}
              </Typography>
            </Box>
          </>
        ) : null}
        <TablePagination
          style={{ marginRight: 35 }}
          rowsPerPageOptions={[10, 15, 25, 50, 100]}
          component="div"
          count={sensorsByGroup ? sensorsByGroup.length : 0}
          rowsPerPage={sensorsByGroupPageSize}
          page={sensorsByGroupPage}
          onPageChange={changeSensorsPerGroupPage}
          onRowsPerPageChange={changeRowsPerSensorByGroup}
        />
      </TableContainer>
      <DeleteSensorModal
        isOpenedDeleteSensorModal={isOpenedDeleteSensorModal}
        selectedSensor={selectedSensor}
        selectedDeviceGroupName={selectedDeviceGroupName}
        handleOnCloseModal={handleOnCloseModal}
        handleOnDeleteDeviceAndSensorFromGroup={handleOnDeleteDeviceAndSensorFromGroup}
      />
      <AddSensorModal
        isOpenedAddSensorModal={isOpenedAddSensorModal}
        selectedDeviceGroupName={selectedDeviceGroupName}
        devicesFromCompany={devicesFromCompany}
        devicesConfigs={devicesConfigs}
        handleOnCloseModal={handleOnCloseModal}
        handleOnCreateSensorDeviceToGroup={handleOnCreateSensorDeviceToGroup}
      />
    </>
  );
};

interface SensorsTableProps {
  sensorsByDeviceGroup: DeviceSensor[] | undefined;
  selectedDeviceGroupName: string | null;
  refreshTable: (companyId: number) => void;
  showErrorMessage: (errorMessage: string, showError: boolean) => void;
}

export default SensorsTable;
