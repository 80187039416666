import React, { InputHTMLAttributes } from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';

type InputTextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
};

export const InputTextField: React.FC<InputTextFieldProps> = (props: InputTextFieldProps) => {
  const [field, { error }] = useField(props);
  return (
    <TextField
      id={field.name.toLocaleLowerCase() + '_txtf'}
      label={props.label}
      placeholder={props.placeholder}
      error={!!error}
      helperText={error ? error : null}
      variant="outlined"
      fullWidth
      size="small"
      type={props.type}
      {...field}
    />
  );
};
