import { AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';
import Command, { AddCommand, CommandState, EditCommand } from '../../model/API/Command/Command';
import {
  CommandTypeParameterEnum,
  CommandTypeParameterRange,
} from '../../model/API/Command/CommandTypeParameter';

export const getAllCommands = async (deviceNumber: string): Promise<Command[]> => {
  return await Axios.get(`/api/v2/devices/${deviceNumber}/commands`).then(
    (response: AxiosResponse) => {
      return response.data;
    }
  );
};

export const getCommand = async (commandId: number): Promise<Command> => {
  return await Axios.get(`/api/v2/commands/${commandId}`).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const getCommandTypes = async (): Promise<string[]> => {
  return await Axios.get(`/api/v2/command_descriptors`).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const getCommandTypeParameters = async (
  commandType: string
): Promise<(CommandTypeParameterEnum | CommandTypeParameterRange)[]> => {
  return await Axios.get(`/api/v2/commands_descriptors/${commandType}/parameters`).then(
    (response: AxiosResponse) => {
      return response.data;
    }
  );
};

export const addCommand = async (deviceNumber: string, command: AddCommand): Promise<void> => {
  return await Axios.post(`/api/v2/devices/${deviceNumber}/commands`, command).then(
    (response: AxiosResponse) => {
      return response.data;
    }
  );
};

export const editCommand = async (
  deviceNumber: string,
  commandId: number,
  command: EditCommand
): Promise<void> => {
  return await Axios.put(`/api/v2/devices/${deviceNumber}/commands/${commandId}`, command).then(
    (response: AxiosResponse) => {
      return response.data;
    }
  );
};
