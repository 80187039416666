import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeviceConfigRepository from '../clients/DeviceConfig/DeviceConfigRepository';
import { DeviceConfig } from '../model/API/DeviceConfig/DeviceConfig';

const useFetchDeviceConfig = (number?: string): UseFetchDeviceConfigProps => {
  const [deviceConfig, setDeviceConfig] = useState<DeviceConfig | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const deviceConfigRepository = new DeviceConfigRepository();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);
      setError(null);

      try {
        const deviceConfig = await deviceConfigRepository.getDeviceConfig(number!);
        setDeviceConfig(deviceConfig);
      } catch {
        setDeviceConfig(null);
        setError(t('error_cannot_fetch_device_config'));
      }
      setLoading(false);
    };
    number && fetchData();
  }, [number]);

  return { deviceConfig: deviceConfig, deviceConfigLoading: loading, deviceConfigError: error };
};

interface UseFetchDeviceConfigProps {
  deviceConfig: DeviceConfig | null;
  deviceConfigError: string | null;
  deviceConfigLoading: boolean;
}

export default useFetchDeviceConfig;
