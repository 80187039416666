import { useQuery, useQueries } from '@tanstack/react-query';

import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import {
  FormatAcceptHeaders,
  AlarmDto,
  QueryOptions,
  AlarmsExportParams,
  QueryResult,
  AlarmStatusResponseDto,
} from '@thingslog/repositories';

export interface AlarmsExportQueryClient {
  getAlarmsExportCSV: (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    alarmType?: string
  ) => Promise<ArrayBuffer>;
  getAlarmsExportExcel: (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    alarmType?: string
  ) => Promise<ArrayBuffer>;
  getAlarmsExportJSON: (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    showOnlyActiveAlarms?: boolean,
    alarmType?: string
  ) => Promise<AlarmDto[]>;
  getActiveAlarmsForCompany: (
    companyId?: number,
    alarmTypes?: string[]
  ) => Promise<AlarmStatusResponseDto>;
  getNumberOfActiveAlarms: (companyId: number, alarmTypes?: string[]) => Promise<number>;
  useAlarmsExportCSV: (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    alarmType?: string,
    options?: QueryOptions<ArrayBuffer>
  ) => QueryResult<ArrayBuffer>;
  useAlarmsExportExcel: (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    alarmType?: string,
    options?: QueryOptions<ArrayBuffer>
  ) => QueryResult<ArrayBuffer>;
  useAlarmsExportJSON: (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    showOnlyActiveAlarms?: boolean,
    alarmType?: string,
    options?: QueryOptions<AlarmDto[]>
  ) => QueryResult<AlarmDto[]>;
  useActiveAlarmsForCompany: (
    companyId?: number,
    alarmTypes?: string[],
    options?: QueryOptions<AlarmStatusResponseDto>
  ) => QueryResult<AlarmStatusResponseDto>;
  useNumberOfActiveAlarms: (
    companyId: number,
    alarmTypes?: string[],
    options?: QueryOptions<number>
  ) => QueryResult<number>;
  useAlarmsExportQueries: (args: AlarmsExportParams<AlarmDto[]>[]) => QueryResult<AlarmDto[]>[];
}

export function createAlarmsExportQueryClient(axios: AxiosInstance): AlarmsExportQueryClient {
  return new AlarmsExportImp(axios);
}

class AlarmsExportImp {
  public constructor(private axios: AxiosInstance) {}

  public getAlarmsExportCSV = async (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    alarmType?: string
  ): Promise<ArrayBuffer> => {
    let acceptHeader = FormatAcceptHeaders.CSV;

    return this.axios
      .get(deviceNumber ? `/api/devices/${deviceNumber}/alarms` : '/api/devices/alarms', {
        params: { fromDate, toDate, companyId, alarmType },
        headers: { Accept: acceptHeader },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse<ArrayBuffer>) => {
        return response.data;
      });
  };

  public getAlarmsExportExcel = async (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    alarmType?: string
  ): Promise<ArrayBuffer> => {
    let acceptHeader = FormatAcceptHeaders.XLSX;

    return this.axios
      .get(deviceNumber ? `/api/devices/${deviceNumber}/alarms` : '/api/devices/alarms', {
        params: { fromDate, toDate, companyId, alarmType },
        headers: { Accept: acceptHeader },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse<ArrayBuffer>) => {
        return response.data;
      });
  };

  public getAlarmsExportJSON = async (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    showOnlyActiveAlarms?: boolean,
    alarmType?: string
  ): Promise<AlarmDto[]> => {
    let acceptHeader = FormatAcceptHeaders.JSON;

    return this.axios
      .get(deviceNumber ? `/api/devices/${deviceNumber}/alarms` : '/api/devices/alarms', {
        params: { fromDate, toDate, companyId, showOnlyActiveAlarms, alarmType },
        headers: { Accept: acceptHeader },
      })
      .then((response: AxiosResponse<AlarmDto[]>) => {
        return response.data;
      });
  };

  public getActiveAlarmsForCompany = async (
    companyId?: number,
    alarmTypes?: string[]
  ): Promise<AlarmStatusResponseDto> => {
    return this.axios
      .get(`/api/alarms/status`, {
        params: { companyId, alarmTypes: alarmTypes?.join(',') },
      })
      .then((response: AxiosResponse<AlarmStatusResponseDto>) => {
        return response.data;
      });
  };

  public getNumberOfActiveAlarms = async (
    companyId?: number,
    alarmTypes?: string[]
  ): Promise<number> => {
    return this.axios
      .get('/api/alarms/status/count', {
        params: { companyId, alarmTypes: alarmTypes?.join(',') },
      })
      .then((response: AxiosResponse<number>) => {
        return response.data;
      });
  };

  public useAlarmsExportCSV = (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    alarmType?: string,
    options?: QueryOptions<ArrayBuffer>
  ): QueryResult<ArrayBuffer> => {
    return useQuery<ArrayBuffer, AxiosError>(
      [QueryKeys.GetAlarmsExport, 'CSV', fromDate, toDate, companyId, deviceNumber, alarmType],
      () => this.getAlarmsExportCSV(fromDate, toDate, companyId, deviceNumber, alarmType),
      options
    );
  };

  public useAlarmsExportExcel = (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    alarmType?: string,
    options?: QueryOptions<ArrayBuffer>
  ): QueryResult<ArrayBuffer> => {
    return useQuery<ArrayBuffer, AxiosError>(
      [QueryKeys.GetAlarmsExport, 'Excel', fromDate, toDate, companyId, deviceNumber, alarmType],
      () => this.getAlarmsExportExcel(fromDate, toDate, companyId, deviceNumber, alarmType),
      options
    );
  };

  public useAlarmsExportJSON = (
    fromDate: Date,
    toDate: Date,
    companyId?: number | null,
    deviceNumber?: string,
    showOnlyActiveAlarms?: boolean,
    alarmType?: string,
    options?: QueryOptions<AlarmDto[]>
  ): QueryResult<AlarmDto[]> => {
    return useQuery<AlarmDto[], AxiosError>(
      [QueryKeys.GetAlarmsExport, 'JSON', fromDate, toDate, companyId, deviceNumber, alarmType],
      () =>
        this.getAlarmsExportJSON(
          fromDate,
          toDate,
          companyId,
          deviceNumber,
          showOnlyActiveAlarms,
          alarmType
        ),
      options
    );
  };

  public useActiveAlarmsForCompany = (
    companyId?: number,
    alarmTypes?: string[],
    options?: QueryOptions<AlarmStatusResponseDto>
  ): QueryResult<AlarmStatusResponseDto> => {
    return useQuery<AlarmStatusResponseDto, AxiosError>(
      [QueryKeys.GetActiveAlarmsForCompany, companyId, alarmTypes],
      () => this.getActiveAlarmsForCompany(companyId, alarmTypes),
      options
    );
  };

  public useNumberOfActiveAlarms = (
    companyId: number,
    alarmTypes?: string[],
    options?: QueryOptions<number>
  ): QueryResult<number> => {
    return useQuery<number, AxiosError>(
      [QueryKeys.GetNumberOfActiveAlarms, companyId, alarmTypes],
      () => this.getNumberOfActiveAlarms(companyId, alarmTypes),
      options
    );
  };

  public useAlarmsExportQueries = (
    args: AlarmsExportParams<AlarmDto[]>[]
  ): QueryResult<AlarmDto[]>[] =>
    useQueries({
      queries: args.map(
        ({
          fromDate,
          toDate,
          companyId,
          deviceNumber,
          sensorIndex,
          showOnlyActiveAlarms,
          options,
        }: AlarmsExportParams<AlarmDto[]>) => ({
          queryKey: [
            QueryKeys.GetAlarmsExport,
            'JSON',
            fromDate,
            toDate,
            companyId,
            deviceNumber,
            sensorIndex,
            options,
            showOnlyActiveAlarms,
          ],
          queryFn: () =>
            this.getAlarmsExportJSON(
              fromDate,
              toDate,
              companyId,
              deviceNumber,
              showOnlyActiveAlarms,
              undefined
            ),
          ...options,
        })
      ),
    });
}
