import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions, Pagination, DeviceGroupDto } from '@thingslog/repositories';

export interface DeviceGroupsQueryClient {
  getDeviceGroups: (companyId: number | null) => Promise<DeviceGroupDto[]>;
  getDeviceGroupsFromParent: (
    companyId: number,
    deviceGroupName: string
  ) => Promise<DeviceGroupDto[]>;

  useDeviceGroupsData: (
    companyId: number | null,
    options?: QueryOptions<DeviceGroupDto[]>
  ) => UseQueryResult<DeviceGroupDto[], AxiosError>;
  useDeviceGroupsFromParent: (
    companyId: number,
    deviceGroupName: string,
    options?: QueryOptions<DeviceGroupDto[]>
  ) => UseQueryResult<DeviceGroupDto[], AxiosError>;
}

export function createDeviceGroupsQueryClient(axios: AxiosInstance): DeviceGroupsQueryClient {
  return new DeviceGroupImp(axios);
}

class DeviceGroupImp {
  public constructor(private axios: AxiosInstance) {}

  public getDeviceGroups = async (companyId: number | null): Promise<DeviceGroupDto[]> => {
    return this.axios
      .get(`/api/v2/device-groups`, {
        params: {
          companyId: companyId ? companyId : undefined,
        },
      })
      .then((response: AxiosResponse<DeviceGroupDto[]>) => {
        return response.data;
      });
  };

  public getDeviceGroupsFromParent = async (
    companyId: number,
    deviceGroupName: string
  ): Promise<DeviceGroupDto[]> => {
    return this.axios
      .get(`/api/v2/device-groups/device-group-name/${deviceGroupName}/all`, {
        params: {
          companyId,
        },
      })
      .then((response: AxiosResponse<DeviceGroupDto[]>) => {
        return response.data;
      });
  };

  public useDeviceGroupsData = (
    companyId: number | null,
    options?: QueryOptions<DeviceGroupDto[]>
  ): UseQueryResult<DeviceGroupDto[], AxiosError> => {
    return useQuery<DeviceGroupDto[], AxiosError>(
      [QueryKeys.GetDeviceGroupsByCompanyId, companyId],
      () => this.getDeviceGroups(companyId),
      options
    );
  };

  public useDeviceGroupsFromParent = (
    companyId: number,
    deviceGroupName: string,
    options?: QueryOptions<DeviceGroupDto[]>
  ): UseQueryResult<DeviceGroupDto[], AxiosError> => {
    return useQuery<DeviceGroupDto[], AxiosError>(
      [QueryKeys.GetDeviceGroupsFromParent, companyId, deviceGroupName],
      () => this.getDeviceGroupsFromParent(companyId, deviceGroupName),
      options
    );
  };
}
