import { AxiosError, AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';
import DeviceGroup, { CreateOrUpdateDeviceGroup } from '../../model/API/DeviceGroup/DeviceGroup';

export const getAllDeviceGroups = async (companyId?: number): Promise<DeviceGroup[]> => {
  let url = `/api/v2/device-groups`;

  if (companyId !== undefined) {
    const searchParams = new URLSearchParams();
    searchParams.append('companyId', `${companyId}`);
    url += '?' + searchParams.toString();
  }

  return await Axios.get(url).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const getAllDevices = async (companyId?: number): Promise<any> => {
  let url = `/api/v2/devices`;

  const searchParams = new URLSearchParams();

  if (companyId !== undefined) {
    searchParams.append('companyId', `${companyId}`);
  }

  searchParams.append('configDetails', 'true');
  searchParams.append('page', '0');
  searchParams.append('size', '9999');
  url += '?' + searchParams.toString();

  return await Axios.get(url).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const getDeviceGroup = async (
  deviceGroupName: string,
  companyId?: number
): Promise<DeviceGroup> => {
  let url = `/api/v2/device-group`;

  const searchParams = new URLSearchParams();
  searchParams.append('deviceGroupName', deviceGroupName);
  if (companyId !== undefined) {
    searchParams.append('companyId', `${companyId}`);
  }

  url += '?' + searchParams.toString();

  return await Axios.get(url).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const createDeviceGroup = async (deviceGroup: CreateOrUpdateDeviceGroup): Promise<void> => {
  return await Axios.post(`/api/v2/device-groups`, deviceGroup).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const updateDeviceGroup = async (
  originalDeviceGroupName: string,
  updatedDeviceGroup: CreateOrUpdateDeviceGroup
): Promise<void> => {
  return await Axios.put(
    `/api/v2/device-groups/${originalDeviceGroupName}`,
    updatedDeviceGroup
  ).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const deleteDeviceGroup = async (
  deviceGroupName: string,
  companyId?: number
): Promise<void> => {
  let url = `/api/v2/device-groups/${deviceGroupName}`;

  if (companyId !== undefined) {
    const searchParams = new URLSearchParams();
    searchParams.append('companyId', `${companyId}`);
    url += '?' + searchParams.toString();
  }

  return await Axios.delete(url).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const deleteDeviceAndSensorFromGroup = async (
  companyId: number,
  deviceGroupName: string,
  deviceNumber: string,
  sensorIndex: number
): Promise<void> => {
  let url = `/api/v2/device-groups/${deviceGroupName}/device-sensors/${deviceNumber}/${sensorIndex}`;

  if (companyId !== undefined) {
    const searchParams = new URLSearchParams();
    searchParams.append('companyId', `${companyId}`);
    url += '?' + searchParams.toString();
  }

  return await Axios.delete(url).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const createDeviceAndSensorToGroup = async (
  companyId: number,
  deviceGroupName: string,
  deviceNumber: string,
  sensorIndex: number
): Promise<void> => {
  let url = `/api/v2/device-groups/${deviceGroupName}/device-sensors`;

  let postObj = {
    deviceNumber: deviceNumber,
    sensorIndex: sensorIndex,
  };

  if (companyId !== undefined) {
    const searchParams = new URLSearchParams();
    searchParams.append('companyId', `${companyId}`);
    url += '?' + searchParams.toString();
  }

  return await Axios.post(url, postObj).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const handleError = (error: AxiosError): string => {
  if (error.response === undefined) {
    throw error;
  }

  if (error.response.data) {
    return error.response.data.message;
  } else {
    throw error;
  }
};
