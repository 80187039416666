import * as React from 'react';

import { Form, Formik, FormikState } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../reducers';
import Button from '@mui/material/Button';
import { InputTextField } from '../../../../../components/InputTextField/InputTextField';
import { Box, Autocomplete, TextField, AutocompleteRenderInputParams } from '@mui/material';
import DeviceGroup from '../../../../../model/API/DeviceGroup/DeviceGroup';
import { useEffect, useState } from 'react';
import ColorsPicker from './components/ColorsPicker';

const EditGroupModal: React.FC<EditGroupModalProps> = (props: EditGroupModalProps) => {
  const {
    deviceGroups,
    handleOnUpdateDeviceGroup,
    deviceGroupToModify,
    parentDeviceGroup,
    selectedDeviceGroupColor,
  } = props;

  const flatProps = {
    options: deviceGroups.map((deviceGroup: DeviceGroup) => deviceGroup.deviceGroupName),
  };

  const { t } = useTranslation();

  const companyId = useSelector((state: ReduxState) => state.company.id);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [parentGroupName, setParentGroupName] = React.useState<string | undefined>(
    parentDeviceGroup
  );
  const [deviceGroupColor, setDeviceGroupColor] = useState<string>(selectedDeviceGroupColor);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);

  useEffect(() => {
    setDeviceGroupColor(selectedDeviceGroupColor);
  }, [selectedDeviceGroupColor]);

  return (
    <Formik
      initialValues={{
        deviceGroupName: deviceGroupToModify,
      }}
      onSubmit={async (values: FormikEditGroupModalState): Promise<void> => {
        if (values.deviceGroupName) {
          let deviceGroup = {
            deviceGroupName: values.deviceGroupName,
            deviceSensors: [],
            parentDeviceGroupName: parentGroupName,
            deviceGroupColor: deviceGroupColor,
            companyId: companyId!,
          };
          handleOnUpdateDeviceGroup(deviceGroupToModify, deviceGroup);
        } else {
          setErrorMsg(t('device_groups_sensors_add_modal_device_group_name_required'));
        }
      }}
    >
      {({ isSubmitting }: FormikState<FormikEditGroupModalState>): React.ReactNode => {
        return (
          <Form>
            <Box marginTop={1}>
              <InputTextField
                name="deviceGroupName"
                label={t<string>('device_groups_group_name')}
                required
              />
            </Box>
            <Box marginTop={2}>
              <Autocomplete
                value={parentDeviceGroup}
                {...flatProps}
                onChange={(event: any): void => {
                  //The event renders a list of <li> options with innerText = the name of the deviceGroup
                  setParentGroupName(event.target.innerText);
                }}
                renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
                  <TextField
                    name="parentDeviceGroupName"
                    {...params}
                    label={t<string>('device_groups_parent_device_group')}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Box>
            <Box marginTop={2} display="flex" justifyContent={'center'} alignItems={'center'}>
              <ColorsPicker
                isColorPickerOpen={isColorPickerOpen}
                setIsColorPickerOpen={setIsColorPickerOpen}
                color={deviceGroupColor}
                onColorChange={(color: string): void => setDeviceGroupColor(color)}
              />
            </Box>
            <div className="flex justify-center w-full">
              {!!errorMsg && (
                <span className="text-red-500 font-semibold">
                  <span>{errorMsg}</span>
                </span>
              )}
            </div>
            {!isColorPickerOpen && (
              <Box marginTop={3}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  disableElevation
                >
                  {t<string>('device_groups_btn_edit')}
                </Button>
              </Box>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

interface EditGroupModalProps {
  deviceGroups: DeviceGroup[];
  handleOnUpdateDeviceGroup: (
    deviceGroupToUpdate: string,
    deviceGroup: DeviceGroup
  ) => Promise<void>;
  deviceGroupToModify: string;
  parentDeviceGroup: string | undefined;
  selectedDeviceGroupColor: string;
}

interface FormikEditGroupModalState {
  deviceGroupName: string;
}

export default EditGroupModal;
