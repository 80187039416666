import React, { FC } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PumpSymbol from '../../resources/sensor-icons/pump-symbol.svg';
import BarometerSymbol from '../../resources/sensor-icons/barometer-symbol.svg';
import DoorClosedSymbol from '../../resources/sensor-icons/door-closed-symbol.svg';
import DoorOpenSymbol from '../../resources/sensor-icons/door-open-symbol.svg';
import ElectricitySymbol from '../../resources/sensor-icons/electricity-symbol.svg';
import EnergyMeterSymbol from '../../resources/sensor-icons/energy-meter-symbol.svg';
import GasFlowMeterSymbol from '../../resources/sensor-icons/gas-flow-meter-symbol.svg';
import GasSymbol from '../../resources/sensor-icons/gas-symbol.svg';
import GenericParticleMeterSymbol from '../../resources/sensor-icons/generic-particulate-meter-symbol.svg';
import GenericSymbol from '../../resources/sensor-icons/generic-symbol.svg';
import HeatMeterSymbol from '../../resources/sensor-icons/heat-meter-symbol.svg';
import HumiditySymbol from '../../resources/sensor-icons/humidity-symbol.svg';
import LeafMoistureSymbol from '../../resources/sensor-icons/leaf-moisture-symbol.svg';
import LevelMeteringSymbol from '../../resources/sensor-icons/level-metering-symbol.svg';
import PivotSymbol from '../../resources/sensor-icons/pivot-symbol.svg';
import PressureSymbol from '../../resources/sensor-icons/pressure-symbol.svg';
import RadiationSymbol from '../../resources/sensor-icons/radiation-symbol.svg';
import RainSymbol from '../../resources/sensor-icons/rain-symbol.svg';
import SoilMoistureSymbol from '../../resources/sensor-icons/soil-moisture-symbol.svg';
import TemperatureSymbol from '../../resources/sensor-icons/temperature-symbol.svg';
import WaterMeterSymbol from '../../resources/sensor-icons/water-meter-symbol.svg';
import WaterSymbol from '../../resources/sensor-icons/water-symbol.svg';
import WindDirectionSymbol from '../../resources/sensor-icons/wind-direction-symbol.svg';
import WindSpeedSymbol from '../../resources/sensor-icons/wind-speed-symbol.svg';
import ApartmentIcon from '../../resources/sensor-icons/apartment-building-symbol.svg';
import BaseStationIcon from '../../resources/sensor-icons/base-station-symbol.svg';
import ClinicIcon from '../../resources/sensor-icons/clinic-symbol.svg';
import HospitalIcon from '../../resources/sensor-icons/hospital-symbol.svg';
import HouseIcon from '../../resources/sensor-icons/house-symbol.svg';
import MayorOfficeIcon from '../../resources/sensor-icons/mayor-office-symbol.svg';
import PoliceStationIcon from '../../resources/sensor-icons/police-station-symbol.svg';
import PublicRestroomIcon from '../../resources/sensor-icons/public-restroom-symbol.svg';
import StadiumIcon from '../../resources/sensor-icons/stadium-symbol.svg';

const IconSelector: FC<IconSelectorProps> = ({
  iconName,
  setIconName,
  selectorLabel,
}: IconSelectorProps) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth size="small">
      <InputLabel>{selectorLabel}</InputLabel>
      <Select
        size="small"
        value={iconName}
        label={selectorLabel}
        onChange={(event: SelectChangeEvent<string | null>): void =>
          setIconName(event.target.value ? event.target.value : null)
        }
      >
        <MenuItem value={''}>{t('icon_no_icon')}</MenuItem>
        <MenuItem value={'barometer-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={BarometerSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_barometer')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'door-closed-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={DoorClosedSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_door_closed')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'door-open-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={DoorOpenSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_door_open')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'electricity-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={ElectricitySymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_electricity')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'energy-meter-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={EnergyMeterSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_energy_meter')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'gas-flow-meter-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={GasFlowMeterSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_gas_flow_meter')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'gas-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={GasSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_gas')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'generic-particulate-meter-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={GenericParticleMeterSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_generic_particle_meter')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'generic-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={GenericSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_generic')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'heat-meter-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={HeatMeterSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_heat_meter')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'humidity-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={HumiditySymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_humidity')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'leaf-moisture-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={LeafMoistureSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_leaf_moisture')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'level-metering-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={LevelMeteringSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_level_metering')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'pivot-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={PivotSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_pivot')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'pressure-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={PressureSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_pressure')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'radiation-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={RadiationSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_radiation')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'rain-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={RainSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_rain')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'soil-moisture-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={SoilMoistureSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_soil_moisture')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'temperature-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={TemperatureSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_temperature')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'water-meter-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={WaterMeterSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_water_meter')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'water-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={WaterSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_water')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'wind-direction-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={WindDirectionSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_wind_direction')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'wind-speed-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={WindSpeedSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_wind_speed')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'pump-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={PumpSymbol} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_pump')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'apartment-building-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={ApartmentIcon} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_apartment')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'base-station-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={BaseStationIcon} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_base_station')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'clinic-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={ClinicIcon} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_clinic')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'hospital-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={HospitalIcon} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_hospital')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'house-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={HouseIcon} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_house')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'mayor-office-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={MayorOfficeIcon} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_mayor_office')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'police-station-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={PoliceStationIcon} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_police_station')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'public-restroom-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={PublicRestroomIcon} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_public_restroom')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value={'stadium-symbol'}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Box component="img" sx={{ width: 27, height: 27 }} src={StadiumIcon} />
            <Typography style={{ paddingLeft: 10 }}>{t('icon_stadium')}</Typography>
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

interface IconSelectorProps {
  iconName: string | null;
  setIconName: (iconName: string | null) => void;
  selectorLabel: string;
}

export default IconSelector;
