import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import { useStyles } from './DeviceGroupsStyles.styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import DeviceGroupTable from './components/DeviceGroupTable/DeviceGroupTable';
import SensorsTable from './components/SensorsTable/SensorsTable';
import Header from '../../components/header';
import DeviceGroup from '../../model/API/DeviceGroup/DeviceGroup';
import { ReduxState } from '../../reducers';
import { DeviceSensor } from '../../model/API/DeviceGroup/DeviceGroup';
import * as client from '../../clients/DeviceGroup/DeviceGroupClient';

//Alert component
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props: React.PropsWithChildren<AlertProps>,
  ref: React.ForwardedRef<HTMLDivElement>
): JSX.Element {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeviceGroups: React.FC = () => {
  const companyId = useSelector((state: ReduxState) => state.company.id);

  const [refreshPage, setRefreshPage] = React.useState<boolean>(false);
  const [deviceGroups, setDeviceGroups] = React.useState<DeviceGroup[]>([]);
  const [selectedDeviceGroupName, setSelectDeviceGroupName] = React.useState<string | null>(null);
  const [sensorsByDeviceGroup, setSensorsByDeviceGroup] = React.useState<
    DeviceSensor[] | undefined
  >(undefined);

  //Snackbar states
  const [showError, setShowError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    if (companyId !== null) {
      refreshTable(companyId);
    }
  }, [companyId]);

  const selectGroup = (groupName: string | null, sensors: DeviceSensor[] | undefined): void => {
    setSensorsByDeviceGroup(sensors);
    setSelectDeviceGroupName(groupName);
  };

  const handleCloseErrorMessage = (event: React.SyntheticEvent | Event, reason?: string): void => {
    setShowError(false);
  };

  const showErrorMessage = (errorMessage: string, showError: boolean): void => {
    setErrorMessage(errorMessage);
    setShowError(showError);
  };

  //Refresh function
  const refreshTable = (companyId: number): void => {
    loadDeviceGroups(companyId);
  };

  //Client calls
  const loadDeviceGroups = async (companyId: number): Promise<void> => {
    let deviceGroups = await client.getAllDeviceGroups(companyId);
    setDeviceGroups(deviceGroups);

    //If we have hit refresh state, select once again the selected device group and refresh the sensor table
    if (selectedDeviceGroupName !== '') {
      let mappedDeviceGroup = deviceGroups.find(
        (x: DeviceGroup) => x.deviceGroupName == selectedDeviceGroupName
      );
      if (mappedDeviceGroup) {
        selectGroup(selectedDeviceGroupName!, mappedDeviceGroup.deviceSensors);
      }
    }
  };

  if (!companyId) {
    return (
      <Header>
        <Box marginTop={35}>
          <Typography variant="h4">{t('device_groups_select_company_header')}</Typography>
          <Typography variant="h5">
            {t('device_groups_select_company_from')}
            <Link to={`/app/Accounts`}>{t('device_groups_select_company_accounts_page_link')}</Link>
          </Typography>
        </Box>
      </Header>
    );
  }

  return (
    <Header>
      <Grid container spacing={4}>
        <Grid item lg={6} md={12} pl={1}>
          <DeviceGroupTable
            deviceGroups={deviceGroups}
            selectedDeviceGroupName={selectedDeviceGroupName}
            showErrorMessage={showErrorMessage}
            selectGroup={selectGroup}
            refreshTable={refreshTable}
          />
        </Grid>
        <Grid item lg={6} md={12} pr={4}>
          <SensorsTable
            sensorsByDeviceGroup={sensorsByDeviceGroup}
            selectedDeviceGroupName={selectedDeviceGroupName}
            refreshTable={refreshTable}
            showErrorMessage={showErrorMessage}
          />
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showError}
        autoHideDuration={6000}
        onClose={handleCloseErrorMessage}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Header>
  );
};

export default DeviceGroups;
