import { Box, Button, Paper, Typography, Alert, Avatar } from '@mui/material';
import { Form, Formik, FormikState } from 'formik';
import React, { FC, ReactNode, useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputTextField } from '../../components/InputTextField/InputTextField';
import { Wrapper } from '../../components/Wrapper/Wrapper';
import { global_env } from '../../App';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, Link } from 'react-router-dom';
import * as authActionsCreators from '../../state_management/actions/AuthActionCreator';
import { bindActionCreators } from 'redux';
import { ReduxState } from '../../reducers';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/LockOutlined';
import { setSolutionSettings } from '../../actions';
import { ReCaptchaV3 } from './ReCaptchaV3';

const Signin: FC<SigninProps> = (props: SigninProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginUser } = bindActionCreators(authActionsCreators, dispatch);
  const authenticated = useSelector((state: ReduxState) => state.auth.authenticated);
  const error = useSelector((state: ReduxState) => state.auth.error);
  let recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const [refreshReCaptchaHandler, setRefreshReCaptchaHandler] = useState<
    (() => Promise<void>) | null
  >(null);

  useEffect(() => {
    dispatch(setSolutionSettings({ data: null }));
  }, []);

  useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated]);

  const handleRefreshReCaptchaCallback = useCallback(
    (handleReCaptchaVerify: () => Promise<void>) => {
      setRefreshReCaptchaHandler(() => handleReCaptchaVerify);
    },
    []
  );

  return (
    <Box
      sx={{ backgroundColor: 'primary.dark' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Wrapper variant="small">
        <Paper
          elevation={0}
          style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 30, paddingBottom: 30 }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Avatar sx={{ backgroundColor: 'primary.main' }}>
              <LockIcon />
            </Avatar>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingTop={1}
            paddingBottom={4}
          >
            <Typography variant="h5">Sign In</Typography>
          </Box>
          <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={async (values: FormikLoginState): Promise<void> => {
              loginUser(values.username, values.password, recaptcha);

              if (global_env.recaptchaV3) {
                refreshReCaptchaHandler && refreshReCaptchaHandler();
              } else {
                recaptchaRef.current?.reset();
              }
            }}
          >
            {({ isSubmitting }: FormikState<FormikLoginState>): ReactNode => {
              return (
                <Form>
                  <Box>
                    <InputTextField name="username" label="Username" required />
                  </Box>
                  <Box marginTop={2}>
                    <InputTextField name="password" label="Password" type="password" required />
                  </Box>
                  {global_env.recaptchaRequired &&
                    (global_env.recaptchaV3 ? (
                      <ReCaptchaV3
                        className="mt-2 text-left"
                        action="login"
                        refreshReCaptchaCallback={handleRefreshReCaptchaCallback}
                        onReCaptchaExecute={setRecaptcha}
                      />
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        paddingTop={3}
                        paddingBottom={1}
                      >
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={global_env.recaptcha}
                          onExpired={(): void => {
                            setRecaptcha(null);
                          }}
                          onChange={(token: string | null): void => {
                            setRecaptcha(token);
                          }}
                        />
                      </Box>
                    ))}

                  <Box marginTop={2}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      disableElevation
                    >
                      Login
                    </Button>
                  </Box>
                  <Box marginTop={2} textAlign="right">
                    <Link to={`/app/ResetPassword`}>{t<string>('login_form_forgot_password')}</Link>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Paper>
        {error && (
          <Box marginTop={2}>
            <Alert severity="error">
              <strong>Error: </strong>
              {error}
            </Alert>
          </Box>
        )}
      </Wrapper>
    </Box>
  );
};

interface SigninProps {}

interface FormikLoginState {
  username: string;
  password: string;
}

export default Signin;
