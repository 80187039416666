import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxState } from '../../../../../reducers';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { DeviceSensor } from '../../../../../model/API/DeviceGroup/DeviceGroup';

const DeleteSensorModal: React.FC<DeleteSensorModalProps> = (props: DeleteSensorModalProps) => {
  const {
    isOpenedDeleteSensorModal,
    selectedSensor,
    selectedDeviceGroupName,
    handleOnCloseModal,
    handleOnDeleteDeviceAndSensorFromGroup,
  } = props;

  const { t } = useTranslation();

  const companyId = useSelector((state: ReduxState) => state.company.id);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'backdropClick') {
      return;
    }

    handleOnCloseModal();
  };

  return (
    <>
      <Dialog
        open={isOpenedDeleteSensorModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>
            {t<string>('device_groups_delete_device_group')}: <b>{selectedSensor.sensorName}</b>?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t<string>('device_groups_sensors_delete_description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): Promise<void> =>
              handleOnDeleteDeviceAndSensorFromGroup(
                companyId!,
                selectedDeviceGroupName!,
                selectedSensor.deviceNumber,
                selectedSensor.sensorIndex
              )
            }
            autoFocus
          >
            {t<string>('device_groups_btn_delete')}
          </Button>
          <Button onClick={handleClose}>{t<string>('device_groups_btn_cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface DeleteSensorModalProps {
  isOpenedDeleteSensorModal: boolean;
  selectedSensor: DeviceSensor;
  selectedDeviceGroupName: string | null;
  handleOnCloseModal: () => void;
  handleOnDeleteDeviceAndSensorFromGroup: (
    companyId: number,
    deviceGroupName: string,
    deviceNumber: string,
    sensorIndex: number
  ) => Promise<void>;
}

export default DeleteSensorModal;
