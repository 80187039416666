import React, { ChangeEvent, FC, ReactNode, useState } from 'react';
import { Form, Formik, FormikState } from 'formik';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../Users.styles';
import { ReduxState } from '../../../reducers';
import Button from '@mui/material/Button';
import { InputTextField } from '../../../components/InputTextField/InputTextField';
import { Box, Chip, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from 'react-redux';
import { JwtRole } from '@thingslog/repositories/src/jwt/JWT';
import { UserDto, UserDtoRole } from '@thingslog/repositories';

const EditUserModal: FC<EditModalProps> = (props: EditModalProps) => {
  const { t } = useTranslation();

  const currentUserRoles = useSelector((state: ReduxState) => state.auth.roles);

  const { selectedUser, onCloseModal, onUpdateUser } = props;

  const classes = useStyles();

  const [userRoles, setUserRoles] = useState<UserDtoRole[]>(selectedUser.roles);
  const [dashboardEnabled, setDashboardEnabled] = useState<boolean>(selectedUser.dashboardEnabled);
  const [isContactPerson, setIsContactPerson] = useState<boolean>(selectedUser.isContactPerson);

  const assignRole = (role: UserDtoRole): void => {
    let currentRoles = [...userRoles];
    if (currentRoles.includes(role)) {
      currentRoles = currentRoles.filter((x: UserDtoRole) => x != role);
    } else {
      currentRoles.push(role);
    }
    setUserRoles(currentRoles);
  };

  const handleDashboardCheckbox = (event: ChangeEvent<HTMLInputElement>): void => {
    setDashboardEnabled(event.target.checked);
  };

  const handleIsContactPersonChecked = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsContactPerson(event.target.checked);
  };

  const checkAdminRights = (adminRole: string): boolean => {
    let result = currentUserRoles!.some((element: JwtRole) => {
      if (element.authority === adminRole) return true;
      return false;
    });

    return result;
  };

  return (
    <>
      <div className="w-full">
        <Formik
          initialValues={{
            firstName: selectedUser.firstName || '',
            lastName: selectedUser.lastName || '',
            password: '',
            confirmPassword: '',
          }}
          onSubmit={async (values: FormikEditUserState): Promise<void> => {
            let user: UserDto = {
              username: selectedUser.username,
              language: selectedUser.language,
              firstName: values.firstName,
              lastName: values.lastName,
              email: selectedUser.email,
              password: values.password,
              companyName: selectedUser.companyName,
              roles: userRoles,
              dashboardEnabled,
              isContactPerson,
            };

            onUpdateUser(user);
            setUserRoles([]);
            onCloseModal();
          }}
        >
          {({ isSubmitting, values }: FormikState<FormikEditUserState>): ReactNode => {
            return (
              <Form>
                <Box marginTop={2} className={classes.inlineFlexItems}>
                  <div style={{ marginRight: 10, width: '100%' }}>
                    <InputTextField name="firstName" label={t('users_table_first_name')} required />
                  </div>
                  <div style={{ marginLeft: 10, width: '100%' }}>
                    <InputTextField name="lastName" label={t('users_table_last_name')} required />
                  </div>
                </Box>
                <Box marginTop={2}>
                  <InputTextField name="password" label={t('password')} type="password" required />
                </Box>
                <Box marginTop={2}>
                  <InputTextField
                    name="confirmPassword"
                    label={t('confirm_password')}
                    type="password"
                    required
                  />
                </Box>
                <Box
                  height={75}
                  justifyContent="space-around"
                  marginLeft={5}
                  marginRight={5}
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                >
                  <Chip
                    color="primary"
                    size="small"
                    variant="filled"
                    icon={userRoles.includes('USER') ? <CheckCircleOutlineIcon /> : undefined}
                    onClick={(): void => assignRole('USER')}
                    label={t('role_user')}
                  />
                  <Chip
                    color="primary"
                    size="small"
                    variant="filled"
                    icon={userRoles.includes('PUBLIC') ? <CheckCircleOutlineIcon /> : undefined}
                    onClick={(): void => assignRole('PUBLIC')}
                    label={t('role_public')}
                  />
                  <Chip
                    color="primary"
                    size="small"
                    variant="filled"
                    icon={userRoles.includes('ADMIN') ? <CheckCircleOutlineIcon /> : undefined}
                    onClick={(): void => assignRole('ADMIN')}
                    label={t('role_admin')}
                  />
                  <Chip
                    color="primary"
                    size="small"
                    variant="filled"
                    icon={userRoles.includes('RESTRICTED') ? <CheckCircleOutlineIcon /> : undefined}
                    onClick={(): void => assignRole('RESTRICTED')}
                    label={t('role_restricted')}
                  />
                  {checkAdminRights('ROLE_SUPER_ADMIN') && (
                    <Chip
                      size="small"
                      color="primary"
                      variant="filled"
                      icon={
                        userRoles.includes('SUPER_ADMIN') ? (
                          <CheckCircleOutlineIcon />
                        ) : (
                          <span></span>
                        )
                      }
                      onClick={(): void => assignRole('SUPER_ADMIN')}
                      label={t('role_super_admin')}
                    />
                  )}
                </Box>
                <Box justifyContent="center" alignItems="center" display="flex" flexDirection="row">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dashboardEnabled}
                          onChange={handleDashboardCheckbox}
                          name="isDashboardEnabled"
                        />
                      }
                      label={t('users_is_dashboard_enabled')}
                    />
                  </FormGroup>
                  {checkAdminRights('ROLE_SUPER_ADMIN') && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isContactPerson"
                            onChange={handleIsContactPersonChecked}
                            checked={isContactPerson}
                          />
                        }
                        label={t('users_is_contact_person')}
                      />
                    </FormGroup>
                  )}
                </Box>
                <Box marginTop={2}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || values.password !== values.confirmPassword}
                    disableElevation
                  >
                    {t<string>('capital_update_btn')}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

interface EditModalProps {
  onCloseModal: () => void;
  selectedUser: UserDto;
  onUpdateUser: (user: UserDto) => void;
}

interface FormikEditUserState {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

export default EditUserModal;
