import { Typography, IconButton, Chip, Tooltip } from '@mui/material';
import { EventValueRange, Port } from '@thingslog/repositories';
import EventNotificationType from '@thingslog/repositories/src/valueRanges/EventNotificationType';
import LineChartStyles from '@thingslog/repositories/src/valueRanges/LineChartStyles';
import MarkerStyles from '@thingslog/repositories/src/valueRanges/MarkerStyles';
import { t } from 'i18next';
import React, { FC, useEffect, useState } from 'react';
import getDefaultSensorName from '../../../common/SensorNameHelper';
import { lineColors } from '../../graphV2/GraphColors';
import {
  translateEventFunction,
  translateEventNotification,
} from '../utils/ValueRangeTranslationUtil';
import ValueRangePeriodVisualizer from './ValueRangePeriodVisualizer';
import PlaceIcon from '@mui/icons-material/Place';
import SensorsIcon from '@mui/icons-material/Sensors';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Styles } from '@thingslog/repositories/src/valueRanges/Styles';
import DeviceIconStyles from '@thingslog/repositories/src/valueRanges/DeviceIconStyles';
import SensorIconStyles from '@thingslog/repositories/src/valueRanges/SensorIconStyles';

const EventValueRangeItem: FC<EventValueRangeItemProps> = ({
  timezone,
  eventValueRange,
  portsConfig,
  onEditClick,
  onDeleteClick,
}: EventValueRangeItemProps) => {
  const [lineColors, setLineColors] = useState<LineChartStyles[]>([]);
  const [deviceIcons, setDeviceIcons] = useState<DeviceIconStyles[]>([]);
  const [sensorIcons, setSensorIcons] = useState<SensorIconStyles[]>([]);

  useEffect(() => {
    const lineColors = eventValueRange.styles.filter(
      (style: Styles) => style['@type'] === 'line'
    ) as LineChartStyles[];
    setLineColors(lineColors);

    const deviceIconStyles = eventValueRange.styles.filter(
      (style: Styles) => style['@type'] === 'device-icon'
    ) as DeviceIconStyles[];
    setDeviceIcons(deviceIconStyles);

    const sensorIconStyles = eventValueRange.styles.filter(
      (style: Styles) => style['@type'] === 'sensor-icon'
    ) as SensorIconStyles[];
    setSensorIcons(sensorIconStyles);
  }, [eventValueRange]);

  return (
    <section className="rounded-md mb-2 border border-solid border-gray-200 px-3 py-2">
      <div className="flex justify-between flex-1 items-center pb-2">
        <div className="flex items-center space-x-1">
          <div className="flex flex-col justify-start items-start">
            <div className="flex items-center space-x-2">
              <Typography variant="body1">Name:</Typography>
              <Typography variant="body1" fontWeight="bold">
                {eventValueRange.name}
              </Typography>
            </div>
            <div className="flex items-center space-x-2">
              <Typography variant="body1">Sensor:</Typography>
              <Typography variant="body1" fontWeight="bold">
                {portsConfig[eventValueRange.sensorIndex]?.sensor?.name ||
                  getDefaultSensorName(eventValueRange.sensorIndex)}
              </Typography>
            </div>
          </div>
          <IconButton
            size="small"
            onClick={(): void => {
              onEditClick(eventValueRange);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={(): void => {
              onDeleteClick(eventValueRange);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          {lineColors.map((style: LineChartStyles) => (
            <>
              <TimelineIcon className="text-gray-300" />
              <Tooltip title={`${t('value_ranges_line_color')}`} placement="top" arrow>
                <span
                  className={`h-5 w-5 rounded-md`}
                  style={{ backgroundColor: style.lineColor }}
                />
              </Tooltip>
              <Tooltip title={`${t('value_ranges_node_color')}`} placement="top" arrow>
                <span className="h-5 w-5 rounded-md" style={{ backgroundColor: style.nodeColor }} />
              </Tooltip>
            </>
          ))}
          {deviceIcons.map((style: DeviceIconStyles) => (
            <>
              <PlaceIcon className="text-gray-300" />
              <Tooltip title={`${t('value_ranges_foreground_color')}`} placement="top" arrow>
                <span
                  className={`h-5 w-5 rounded-md`}
                  style={{ backgroundColor: style.foregroundColor }}
                />
              </Tooltip>
              <Tooltip title={`${t('value_ranges_background_color')}`} placement="top" arrow>
                <span
                  className="h-5 w-5 rounded-md"
                  style={{ backgroundColor: style.backgroundColor }}
                />
              </Tooltip>
            </>
          ))}
          {sensorIcons.map((style: SensorIconStyles) => (
            <>
              <SensorsIcon className="text-gray-300" />
              <Tooltip title={`${t('value_ranges_foreground_color')}`} placement="top" arrow>
                <span
                  className={`h-5 w-5 rounded-md`}
                  style={{ backgroundColor: style.foregroundColor }}
                />
              </Tooltip>
              <Tooltip title={`${t('value_ranges_background_color')}`} placement="top" arrow>
                <span
                  className="h-5 w-5 rounded-md"
                  style={{ backgroundColor: style.backgroundColor }}
                />
              </Tooltip>
            </>
          ))}
        </div>
        <div>
          <ValueRangePeriodVisualizer
            startDay={eventValueRange.period?.startDay || null}
            startMonth={eventValueRange.period?.startMonth || null}
            startHour={
              eventValueRange.period?.startHour !== undefined &&
              eventValueRange.period?.startHour !== null
                ? Number(eventValueRange.period.startHour)
                : null
            }
            startMinute={
              eventValueRange.period?.startMinute !== undefined &&
              eventValueRange.period?.startMinute !== null
                ? Number(eventValueRange.period.startMinute)
                : null
            }
            endDay={eventValueRange.period?.endDay || null}
            endMonth={eventValueRange.period?.endMonth || null}
            endHour={
              eventValueRange.period?.endHour !== undefined &&
              eventValueRange.period?.endHour !== null
                ? Number(eventValueRange.period.endHour)
                : null
            }
            endMinute={
              eventValueRange.period?.endMinute !== undefined &&
              eventValueRange.period?.endMinute !== null
                ? Number(eventValueRange.period?.endMinute)
                : null
            }
          />
          {timezone}
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="w-1/4 ">
          <Typography variant="subtitle2" className="line-clamp-3 text-start">
            {eventValueRange.description}
          </Typography>
        </div>
        <div className="w-3/4 grid grid-flow-col grid-rows-2 text-start space-y-1">
          <div className="flex space-x-2 items-center">
            <Typography variant="subtitle2">{t('value_ranges_trigger')}:</Typography>
            <span>{eventValueRange.min !== null && eventValueRange.min + ' <'}</span>
            <Chip
              label={translateEventFunction(eventValueRange.function, t)}
              size="small"
              className="mx-1"
            />
            <span>{eventValueRange.max !== null && '< ' + eventValueRange.max}</span>
          </div>
          <div className="flex space-x-2">
            <Typography variant="subtitle2">{t('value_ranges_notifications')}:</Typography>
            {eventValueRange.eventsEnabled ? (
              eventValueRange.eventNotificationTypes.map((notification: EventNotificationType) => (
                <Chip label={translateEventNotification(notification, t)} size="small" />
              ))
            ) : (
              <Chip label={t('value_ranges_disabled')} size="small" />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

interface EventValueRangeItemProps {
  timezone: string;
  eventValueRange: EventValueRange;
  portsConfig: Record<number, Port>;
  onEditClick: (eventValueRange: EventValueRange) => void;
  onDeleteClick: (eventValueRange: EventValueRange) => void;
}

export default EventValueRangeItem;
