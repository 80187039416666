import React, { FC, useState, useEffect, SyntheticEvent, ReactNode } from 'react';

import { Form, Formik, FormikState } from 'formik';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../DeviceGroupsStyles.styles';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../reducers';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  IconButton,
  Autocomplete,
  TextField,
  AutocompleteRenderInputParams,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Port } from '@thingslog/repositories';
import getDefaultSensorName from '../../../../../common/SensorNameHelper';

const AddSensorModal: FC<AddSensorModalProps> = (props: AddSensorModalProps) => {
  const {
    isOpenedAddSensorModal,
    selectedDeviceGroupName,
    devicesFromCompany,
    devicesConfigs,
    handleOnCloseModal,
    handleOnCreateSensorDeviceToGroup,
  } = props;

  const { t } = useTranslation();

  const companyId = useSelector((state: ReduxState) => state.company.id);

  const [sensorsByDevice, setSensorsByDevice] = useState<sensorNameMapList[]>([]);

  const classes = useStyles();

  const deviceDropdownProps = {
    options: devicesFromCompany,
  };

  const sensorsDropdownProps = {
    options: sensorsByDevice,
  };

  //Dropdown selections
  const [selectedDeviceNumber, setSelectedDeviceNumber] = useState<string | null>(null);
  const [selectedSensor, setSelectedSensor] = useState<number | null>(null);

  useEffect(() => {
    if (devicesFromCompany.length !== 0 && devicesConfigs.length !== 0) {
      if (selectedDeviceNumber) {
        let sensors = devicesConfigs.filter((x: any) => x.number === selectedDeviceNumber)[0].config
          .ports;
        let mappedArr = mapSensorListFunc(sensors);
        setSensorsByDevice(mappedArr);
      } else {
        let firstDeviceInList = devicesFromCompany[0];
        let sensors = devicesConfigs.filter((x: any) => x.number === firstDeviceInList)[0].config
          .ports;
        let mappedArr = mapSensorListFunc(sensors);
        setSensorsByDevice(mappedArr);
      }
    }
  }, [selectedDeviceNumber, devicesFromCompany]);

  const mapSensorListFunc = (sensors: Record<number, Port>): sensorNameMapList[] => {
    let sensorObjectEntries = Object.entries(sensors);
    let mappedList: sensorNameMapList[] = [];
    sensorObjectEntries.map((x: [string, Port]) => {
      let sensorIndex = Number(x[0]);
      let label = x[1].sensor.name || getDefaultSensorName(sensorIndex);
      if (x[1].enabled) {
        mappedList.push({ label: label, id: sensorIndex });
      }
    });
    return mappedList;
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'backdropClick') {
      return;
    }

    handleOnCloseModal();
    setSelectedDeviceNumber(null);
    setSelectedSensor(null);
  };

  return (
    <>
      <Dialog
        open={isOpenedAddSensorModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.addUserDialog}>
          <DialogTitle id="alert-dialog-title">
            <div className={classes.inlineFlexItems}>
              <Typography className={classes.addUserTitle}>
                {t<string>('device_grous_add_sensor_device')}
              </Typography>
              <div>
                <IconButton size="small" onClick={handleClose} className={classes.closeModalIcon}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                deviceGroupName: '',
              }}
              onSubmit={async (values: FormikAddSensorModalState): Promise<void> => {
                if (
                  companyId &&
                  selectedDeviceGroupName &&
                  selectedDeviceNumber != null &&
                  selectedSensor != null
                ) {
                  handleOnCreateSensorDeviceToGroup(
                    companyId,
                    selectedDeviceGroupName,
                    selectedDeviceNumber,
                    selectedSensor
                  );
                  setSelectedDeviceNumber(null);
                  setSelectedSensor(null);
                }
              }}
            >
              {({ isSubmitting }: FormikState<FormikAddSensorModalState>): ReactNode => {
                return (
                  <Form>
                    <Box className={classes.inlineFlexItems} justifyContent="space-evenly">
                      <Autocomplete
                        style={{ margin: 10 }}
                        fullWidth
                        {...deviceDropdownProps}
                        onChange={(event: any): void => {
                          //The event renders a list of <li> options with innerText = the name of the deviceGroup
                          setSelectedDeviceNumber(event.target.innerText);
                        }}
                        renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                          <TextField
                            name="parentDeviceGroupName"
                            {...params}
                            label={t<string>('device_groups_sensors_add_modal_device_placeholder')}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                      <Autocomplete
                        fullWidth
                        style={{ margin: 10 }}
                        disabled={selectedDeviceNumber ? false : true}
                        {...sensorsDropdownProps}
                        onChange={(
                          event: SyntheticEvent,
                          value: sensorNameMapList | null
                        ): void => {
                          if (value) {
                            setSelectedSensor(value.id);
                          }
                        }}
                        renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                          <TextField
                            name="parentDeviceGroupName"
                            {...params}
                            label={t<string>('device_groups_sensors_add_modal_sensor_placeholder')}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </Box>
                    <Box marginTop={2}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={
                          selectedDeviceNumber == null || selectedSensor == null
                            ? true
                            : isSubmitting
                        }
                        disableElevation
                      >
                        {t<string>('device_groups_btn_add')}
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

interface AddSensorModalProps {
  isOpenedAddSensorModal: boolean;
  selectedDeviceGroupName: string | null;
  devicesFromCompany: string[];
  devicesConfigs: any[];
  handleOnCloseModal: () => void;
  handleOnCreateSensorDeviceToGroup: (
    companyId: number,
    deviceGroupName: string | null,
    deviceNumber: string | undefined,
    sensorIndex: number
  ) => Promise<void>;
}

interface FormikAddSensorModalState {
  deviceGroupName: string;
}

interface sensorNameMapList {
  label: string;
  id: number;
}

export default AddSensorModal;
