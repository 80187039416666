import { ConsumptionBillingReports, QueryOptions } from '@thingslog/repositories';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKeys } from '../enums/QueryKeys';

export interface ConsumptionBillingQueryClient {
  getConsumptionBillingReport: (
    deviceGroupName: string,
    fromDateIso: string,
    toDateIso: string
  ) => Promise<ConsumptionBillingReports>;
  useConsumptionBillingReport: (
    deviceGroupName: string,
    fromDate: Date,
    toDate: Date,
    identifier: string | null,
    options?: QueryOptions<ConsumptionBillingReports>
  ) => UseQueryResult<ConsumptionBillingReports, AxiosError>;
}

export function createConsumptionBillingReportQueryClient(
  axios: AxiosInstance
): ConsumptionBillingQueryClient {
  return new ConsumptionBillingImplementation(axios);
}

class ConsumptionBillingImplementation {
  public constructor(private axios: AxiosInstance) {}

  public getConsumptionBillingReport = async (
    deviceGroupName: string,
    fromDateIso: string,
    toDateIso: string
  ): Promise<ConsumptionBillingReports> => {
    return this.axios
      .get(`/api/billing-report/device-group-hierarchy/${deviceGroupName}`, {
        params: {
          fromDate: fromDateIso,
          toDate: toDateIso,
        },
      })
      .then((response: AxiosResponse<ConsumptionBillingReports>) => {
        return response.data;
      });
  };

  public useConsumptionBillingReport = (
    deviceGroupName: string,
    fromDate: Date,
    toDate: Date,
    identifier: string | null,
    options?: QueryOptions<ConsumptionBillingReports>
  ): UseQueryResult<ConsumptionBillingReports, AxiosError> => {
    return useQuery<ConsumptionBillingReports, AxiosError>(
      [
        QueryKeys.GetConsumptionBillingReport,
        deviceGroupName,
        identifier,
        fromDate.toISOString(),
        toDate.toISOString(),
      ],
      () =>
        this.getConsumptionBillingReport(
          deviceGroupName,
          fromDate.toISOString(),
          toDate.toISOString()
        ),
      options
    );
  };
}
