import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { t } from 'i18next';
import { differenceInDays } from 'date-fns';
import { useToast } from '@thingslog/ui-components';
import {
  AlarmDto,
  Device,
  TimeSeriesData,
  TimeSeriesPredictionJson,
  DeviceFlowQueryParams,
  DeviceFlowAvgQueryParams,
  DevicesReadingsAvgQueryParams,
  DevicesReadingsQueryParams,
  AlarmsExportParams,
  TimeSeriesPredictionQueryParams,
  DeviceGroupDto,
} from '@thingslog/repositories';
import Header from '../../components/header';
import DeviceSensorGraphSelector from './DeviceSensorGraphSelector';
import DeviceSensorGraphData from './model/DeviceSensorGraphData';
import {
  deviceConfigQueryClient,
  deviceGroupsQueryClient,
  initialConfigQueryClient,
  deviceFlowQueryClient,
  deviceCountersQueryClient,
  timeSeriesPredictionQueryClient,
  alarmsQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { GraphDataWrapper } from './components/graph-data-grid/GraphDataWrapper';
import SelectedSensors from './SelectedSensors';
import { LineColors } from './model/LineChartColors';
import { lineColors } from './GraphColors';
import { MultiDeviceDataLineChart } from './components/MultiDeviceDataLineChart';
import JwtValidator from '../../common/JwtValidator';
import PortUtil from '../graph/utils/PortUtil';
import { getSelectedCompanyOrAccoutCompanyId } from '../../components/auth/devicesHelper';
import { QueryKeys } from '@thingslog/queries/src/enums/QueryKeys';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

const GraphV2: React.FC = () => {
  // #region Hooks
  const [selectableDevices, setSelectableDevices] = useState<Device[]>([]);
  const [deviceSensorGraphData, setDeviceSensorGraphData] = useState<DeviceSensorGraphData[]>([]);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<DeviceGroupDto | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [every, setEvery] = useState<number | null>(null);
  const [fromAverage, setFromAverage] = useState<Date | null>(null);
  const [toAverage, setToAverage] = useState<Date | null>(null);
  const [forwardDays, setForwardDays] = useState<number | null>(null);
  const [isAddDeviceGroupModalOpen, setIsDeviceGroupModalOpen] = useState<boolean>(false);
  const [autoRefreshIntervalMilliseconds, setAutoRefreshIntervalMilliseconds] = useState<
    number | null
  >(null);
  const [flowQueries, setFlowQueries] = useState<DeviceFlowQueryParams<TimeSeriesData<number>[]>[]>(
    []
  );
  const [avgFlowQueries, setAverageFlowQueries] = useState<
    DeviceFlowAvgQueryParams<TimeSeriesData<number>[]>[]
  >([]);
  const [readingsQueries, setReadingsQueries] = useState<
    DevicesReadingsQueryParams<TimeSeriesData<number>[]>[]
  >([]);
  const [avgReadingsQueries, setAvgReadingsQueries] = useState<
    DevicesReadingsAvgQueryParams<TimeSeriesData<number>[]>[]
  >([]);
  const [predictionQueries, setPredictionQueries] = useState<
    TimeSeriesPredictionQueryParams<TimeSeriesPredictionJson>[]
  >([]);
  const [alarmsQueries, setAlarmsQueries] = useState<AlarmsExportParams<AlarmDto[]>[]>([]);

  const companyIdRedux = useSelector((state: ReduxState) => state.company.id);
  const devices = useSelector((state: ReduxState) => state.dev.devicesArray);
  const fromDate = useSelector((state: ReduxState) => state.period.fromDateTz);
  const toDate = useSelector((state: ReduxState) => state.period.toDateTz);

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { hasRole, decodedToken } = useMemo(() => new JwtValidator(), []);
  const { useDeviceGroupsData, useDeviceGroupsFromParent } = useMemo(
    () => deviceGroupsQueryClient,
    []
  );
  const { useGetDevicesConfigsBatch } = useMemo(() => deviceConfigQueryClient, []);
  const { useGetDevicesPortsConfigsBatch } = useMemo(() => initialConfigQueryClient, []);
  const { useDevicesFlowQueries, useDevicesFlowAvgQueries } = useMemo(
    () => deviceFlowQueryClient,
    []
  );
  const { useDevicesReadingsQueries, useDevicesReadingsAvgQueries } = useMemo(
    () => deviceCountersQueryClient,
    []
  );
  const { useAlarmsExportQueries } = useMemo(() => alarmsQueryClient, []);
  const { useTimeSeriesPredictionQueries } = useMemo(() => timeSeriesPredictionQueryClient, []);

  const deviceNumbers = useMemo(() => {
    return _.map(selectableDevices, 'number');
  }, [selectableDevices]);

  // #endregion

  // #region Queries
  const { data: deviceGroupsForCompany, isSuccess: isDeviceGroupsSuccess } = useDeviceGroupsData(
    companyId,
    {
      enabled: companyId !== null,
      onError: () => {
        handleOnError('new_graph_fetch_device_groups_error');
      },
    }
  );

  const { data: deviceGroupsFromParent, isFetching: isDeviceGroupsFromParentFetching } =
    useDeviceGroupsFromParent(companyId!, selectedDeviceGroup?.deviceGroupName!, {
      enabled: companyId !== null && selectedDeviceGroup !== null,
      refetchOnWindowFocus: false,
      onSuccess: (deviceGroupsFromParent: DeviceGroupDto[]) => {
        const parentAndChildDeviceNumbers = _.chain(deviceGroupsFromParent)
          .flatMap('deviceSensors')
          .map('deviceNumber')
          .uniq()
          .value();

        const devicesInParentAndChild = _.filter(devices, (device: Device) =>
          _.includes(parentAndChildDeviceNumbers, device.number)
        );

        setSelectableDevices(devicesInParentAndChild);
      },
    });

  const {
    data: deviceConfigs,
    isLoading: isDevicesConfigsLoading,
    mutate: getDevicesConfigs,
  } = useGetDevicesConfigsBatch();

  const {
    data: portConfigs,
    isLoading: isPortsConfigsLoading,
    mutate: getDevicesPortsConfigs,
  } = useGetDevicesPortsConfigsBatch();

  useDevicesFlowQueries(flowQueries);
  useDevicesFlowAvgQueries(avgFlowQueries);
  useDevicesReadingsQueries(readingsQueries);
  useDevicesReadingsAvgQueries(avgReadingsQueries);
  useAlarmsExportQueries(alarmsQueries);
  useTimeSeriesPredictionQueries(predictionQueries);
  // #endregion

  //#region Effects

  useEffect(() => {
    setCompanyId(
      getSelectedCompanyOrAccoutCompanyId(
        companyIdRedux,
        Number(decodedToken?.companyId),
        hasRole('ROLE_SUPER_ADMIN'),
        hasRole('ROLE_ADMIN')
      )
    );
  }, [companyIdRedux, decodedToken]);

  useEffect(() => {
    if (selectedDeviceGroup === null) {
      setSelectableDevices(devices);
    }
  }, [devices, selectedDeviceGroup]);

  useEffect(() => {
    if (fromAverage === null && toAverage === null) {
      const updatedDeviceSensorGraphData = [...deviceSensorGraphData];
      deviceSensorGraphData.forEach((deviceSensorData: DeviceSensorGraphData, index: number) => {
        updatedDeviceSensorGraphData[index] = { ...deviceSensorData, averageFlowData: [] };
      });
      setDeviceSensorGraphData(updatedDeviceSensorGraphData);
    }
  }, [fromAverage, toAverage]);

  useEffect(() => {
    if (forwardDays === null) {
      const updatedDeviceSensorGraphData = [...deviceSensorGraphData];
      deviceSensorGraphData.forEach((deviceSensorData: DeviceSensorGraphData, index: number) => {
        updatedDeviceSensorGraphData[index] = { ...deviceSensorData, predictionData: [] };
      });
      setDeviceSensorGraphData(updatedDeviceSensorGraphData);
    }
  }, [forwardDays]);

  useEffect(() => {
    const flowQueries: DeviceFlowQueryParams<TimeSeriesData<number>[]>[] = [];
    const readingsQueries: DevicesReadingsQueryParams<TimeSeriesData<number>[]>[] = [];
    const alarmsQueries: AlarmsExportParams<AlarmDto[]>[] = [];
    const updatedDeviceSensorGraphData = [...deviceSensorGraphData];

    deviceSensorGraphData.map((deviceSensorData: DeviceSensorGraphData, index: number) => {
      const { deviceNumber, sensorIndex, every, device, port } = deviceSensorData;
      const isPortOnOffInput = port['@type'] === 'on_off_input_port';

      flowQueries.push({
        deviceNumbers: [deviceNumber],
        sensorIndex: sensorIndex,
        fromDate: fromDate,
        toDate: toDate,
        every: every!,
        options: {
          enabled: every !== null && PortUtil.isDigitalPort(port['@type']),
          refetchOnWindowFocus: false,
          refetchIntervalInBackground: true,
          refetchInterval: autoRefreshIntervalMilliseconds
            ? autoRefreshIntervalMilliseconds
            : false,
          onSuccess: (data: TimeSeriesData<number>[]): void => {
            updatedDeviceSensorGraphData[index].flowData = data;
            setDeviceSensorGraphData(updatedDeviceSensorGraphData);
          },
          onError: (): void => {
            handleOnError('new_graph_fetch_device_flow_error', device.name);
          },
        },
      });

      readingsQueries.push({
        deviceNumber: deviceNumber,
        sensorIndex: sensorIndex,
        fromDate: fromDate,
        toDate: toDate,
        every: every!,
        options: {
          enabled: every !== null && PortUtil.isAnalogPort(port['@type']),
          refetchOnWindowFocus: false,
          refetchIntervalInBackground: true,
          refetchInterval: autoRefreshIntervalMilliseconds
            ? autoRefreshIntervalMilliseconds
            : false,
          onSuccess: (data: TimeSeriesData<number>[]): void => {
            updatedDeviceSensorGraphData[index].flowData = data;
            setDeviceSensorGraphData(updatedDeviceSensorGraphData);
          },
          onError: (): void => {
            handleOnError('new_graph_fetch_device_readings_error', device.name);
          },
        },
      });

      alarmsQueries.push({
        deviceNumber: deviceNumber,
        sensorIndex: sensorIndex,
        fromDate: fromDate,
        toDate: toDate,
        companyId: companyId,
        options: {
          enabled: !hasRole('ROLE_PUBLIC') && isPortOnOffInput,
          refetchOnWindowFocus: false,
          refetchIntervalInBackground: true,
          refetchInterval: autoRefreshIntervalMilliseconds
            ? autoRefreshIntervalMilliseconds
            : false,
          onSuccess: (data: AlarmDto[]): void => {
            const alarmsForSensor = data.filter(
              (entry: AlarmDto) => entry.sensorIndex === sensorIndex
            );
            const alarmsForSensorTimeSeriesData = alarmsForSensor.map((entry: AlarmDto) => {
              return { timestamp: new Date(entry.date).getTime(), value: entry.value };
            });
            updatedDeviceSensorGraphData[index].flowData = alarmsForSensorTimeSeriesData;
            setDeviceSensorGraphData(updatedDeviceSensorGraphData);
          },
          onError: (): void => {
            handleOnError('new_graph_fetch_device_on_off_alarms_error', device.name);
          },
        },
      });
    });

    setFlowQueries(flowQueries);
    setReadingsQueries(readingsQueries);
    setAlarmsQueries(alarmsQueries);
  }, [deviceSensorGraphData, fromDate, toDate, autoRefreshIntervalMilliseconds]);

  useEffect(() => {
    const avgFlowQueries: DeviceFlowAvgQueryParams<TimeSeriesData<number>[]>[] = [];
    const avgReadingsQueries: DevicesReadingsAvgQueryParams<TimeSeriesData<number>[]>[] = [];
    const updatedDeviceSensorGraphData = [...deviceSensorGraphData];

    deviceSensorGraphData.map((deviceSensorData: DeviceSensorGraphData, index: number) => {
      const { deviceNumber, sensorIndex, every, device, port } = deviceSensorData;

      avgFlowQueries.push({
        deviceNumbers: [deviceNumber],
        sensorIndex: sensorIndex,
        fromDate: fromDate,
        toDate: toDate,
        avgFromDate: fromAverage!,
        avgEndDate: toAverage!,
        every: every!,
        options: {
          enabled:
            (fromAverage && toAverage && every) !== null && PortUtil.isDigitalPort(port['@type']),
          refetchOnWindowFocus: false,
          refetchIntervalInBackground: true,
          refetchInterval: autoRefreshIntervalMilliseconds
            ? autoRefreshIntervalMilliseconds
            : false,
          onSuccess: (data: TimeSeriesData<number>[]): void => {
            updatedDeviceSensorGraphData[index].averageFlowData = data;
            setDeviceSensorGraphData(updatedDeviceSensorGraphData);
          },
          onError: (): void => {
            handleOnError('new_graph_fetch_device_flow_avg_error', device.name);
          },
        },
      });

      avgReadingsQueries.push({
        deviceNumber: deviceNumber,
        sensorIndex: sensorIndex,
        fromDate: fromDate,
        toDate: toDate,
        avgFromDate: fromAverage!,
        avgEndDate: toAverage!,
        every: every!,
        options: {
          enabled:
            (fromAverage && toAverage && every) !== null && PortUtil.isAnalogPort(port['@type']),
          refetchOnWindowFocus: false,
          refetchIntervalInBackground: true,
          refetchInterval: autoRefreshIntervalMilliseconds
            ? autoRefreshIntervalMilliseconds
            : false,
          onSuccess: (data: TimeSeriesData<number>[]): void => {
            updatedDeviceSensorGraphData[index].averageFlowData = data;
            setDeviceSensorGraphData(updatedDeviceSensorGraphData);
          },
          onError: (): void => {
            handleOnError('new_graph_fetch_device_readings_avg_error', device.name);
          },
        },
      });
    });

    setAverageFlowQueries(avgFlowQueries);
    setAvgReadingsQueries(avgReadingsQueries);
  }, [
    deviceSensorGraphData,
    fromDate,
    toDate,
    fromAverage,
    toAverage,
    autoRefreshIntervalMilliseconds,
  ]);

  useEffect(() => {
    const predictionQueries: TimeSeriesPredictionQueryParams<TimeSeriesPredictionJson>[] = [];
    const updatedDeviceSensorGraphData = [...deviceSensorGraphData];

    deviceSensorGraphData.map((deviceSensorData: DeviceSensorGraphData, index: number) => {
      const { deviceNumber, sensorIndex, every, port, device } = deviceSensorData;

      predictionQueries.push({
        deviceNumber: deviceNumber,
        sensorIndex: sensorIndex,
        startDate: fromDate,
        endDate: toDate,
        backwardDays: 0,
        forwardDays: forwardDays! + differenceInDays(toDate, fromDate),
        every: every!,
        options: {
          enabled:
            (forwardDays && every) !== null &&
            (PortUtil.isDigitalPort(port['@type']) || PortUtil.isAnalogPort(port['@type'])),
          refetchOnWindowFocus: false,
          refetchIntervalInBackground: true,
          refetchInterval: autoRefreshIntervalMilliseconds
            ? autoRefreshIntervalMilliseconds
            : false,
          onSuccess: (json: TimeSeriesPredictionJson): void => {
            const predictionData: TimeSeriesData<number>[] = json.data.map((element: number[]) => ({
              timestamp: element[0],
              value: element[1],
            }));
            updatedDeviceSensorGraphData[index].predictionData = predictionData;
            setDeviceSensorGraphData(updatedDeviceSensorGraphData);
          },
          onError: (): void => {
            handleOnError(`new_graph_fetch_device_prediction_error`, device.name);
          },
        },
      });
    });

    setPredictionQueries(predictionQueries);
  }, [deviceSensorGraphData, fromDate, toDate, forwardDays, autoRefreshIntervalMilliseconds]);
  // #endregion

  //#region Functions
  const handleOnError = (errorMessage: string, deviceName?: string): void => {
    toast({
      type: 'error',
      message: t(errorMessage, { deviceName: deviceName }),
      duration: 5000,
    });
  };

  const handleSensorDeselect = (deviceNumber: string, sensorIndex: number): void => {
    const deviceSensorGraphDataAfterSensorDeselect = deviceSensorGraphData.filter(
      (entry: DeviceSensorGraphData) =>
        !(entry.deviceNumber === deviceNumber && entry.sensorIndex === sensorIndex)
    );

    for (let index = 0; index < deviceSensorGraphDataAfterSensorDeselect.length; index++) {
      const color = LineColors[index % Object.keys(lineColors).length];
      deviceSensorGraphDataAfterSensorDeselect[index].graphLineColor =
        lineColors[LineColors[color]];
    }

    setDeviceSensorGraphData(deviceSensorGraphDataAfterSensorDeselect);
  };

  const handleGraphRefresh = (): void => {
    queryClient.invalidateQueries([QueryKeys.GetDevicesFlow]);
    queryClient.invalidateQueries([QueryKeys.GetDevicesFlowAvg]);
    queryClient.invalidateQueries([QueryKeys.GetDevicesReadings]);
    queryClient.invalidateQueries([QueryKeys.GetDevicesReadingsAvg]);
    queryClient.invalidateQueries([QueryKeys.GetAlarmsExport]);
    queryClient.invalidateQueries([QueryKeys.GetTimeSeriesPrediction]);
  };
  // #endregion

  //#region Render
  return (
    <Header>
      <DeviceSensorGraphSelector
        fromDate={fromDate}
        toDate={toDate}
        deviceGroups={isDeviceGroupsSuccess ? deviceGroupsForCompany : []}
        selectedDeviceGroup={selectedDeviceGroup}
        selectableDevices={selectableDevices}
        deviceGroupsFromParent={deviceGroupsFromParent || []}
        isDeviceGroupsFromParentFetching={isDeviceGroupsFromParentFetching}
        onSelectedDeviceGroupChange={(deviceGroup: DeviceGroupDto | null): void =>
          setSelectedDeviceGroup(deviceGroup)
        }
        selectedDevice={selectedDevice}
        onSelectedDeviceChange={(device: Device | null): void => {
          setSelectedDevice(device);
          if (device) {
            getDevicesConfigs([device.number]);
            getDevicesPortsConfigs([device.number]);
          }
        }}
        devicesConfigs={deviceConfigs || null}
        portsConfigs={portConfigs || null}
        deviceSensorGraphData={deviceSensorGraphData}
        onDeviceSensorGraphDataChange={(deviceSensorGraphData: DeviceSensorGraphData[]): void =>
          setDeviceSensorGraphData(deviceSensorGraphData)
        }
        every={every}
        onEveryChange={setEvery}
        onAveragePeriodChange={(fromAverage: Date | null, toAverage: Date | null): void => {
          setFromAverage(fromAverage);
          setToAverage(toAverage);
        }}
        onPredictionPeriodChange={(forwardDays: number | null): void => {
          setForwardDays(forwardDays);
        }}
        autoRefreshIntervalMilliseconds={autoRefreshIntervalMilliseconds}
        onAutoRefreshIntervalMillisecondsChange={(
          autoRefreshIntervalMilliseconds: number | null
        ): void => {
          setAutoRefreshIntervalMilliseconds(autoRefreshIntervalMilliseconds);
        }}
        onRefreshButtonClick={handleGraphRefresh}
        isAddDeviceGroupModalOpen={isAddDeviceGroupModalOpen}
        onModalOpened={(): void => {
          getDevicesConfigs(deviceNumbers);
          getDevicesPortsConfigs(deviceNumbers);
        }}
        isDevicesConfigsLoading={isDevicesConfigsLoading}
        isPortsConfigsLoading={isPortsConfigsLoading}
      />
      <SelectedSensors
        deviceSensorGraphData={deviceSensorGraphData}
        onSensorDeselect={handleSensorDeselect}
        onAllSensorsDeselect={(): void => setDeviceSensorGraphData([])}
      />
      {deviceSensorGraphData.length > 0 && (
        <>
          <MultiDeviceDataLineChart data={deviceSensorGraphData} />
          <div className="mt-7">
            <GraphDataWrapper data={deviceSensorGraphData} />
          </div>
        </>
      )}
      {companyId === null && (
        <div className="text-2xl m-auto mt-20">{t('new_graph_select_company_message')}</div>
      )}
    </Header>
  );
  //#endregion
};

export default GraphV2;
