import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouterProps, withRouter } from '../../common/withRouter';
import Header from '../../components/header';
import { styles } from './CopyConfigPage.styles';
import Snackbar from '@mui/material/Snackbar';
import DeviceConfigRepository from '../../clients/DeviceConfig/DeviceConfigRepository';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers';
import { Device } from '@thingslog/repositories';

class CopyConfigPage extends React.Component<CopyConfigPageProps, CopyConfigPageState> {
  public state: CopyConfigPageState = {
    fromDeviceNumber: '',
    snackbarOpen: false,
    snackbarMessage: '',
  };

  private deviceConfigRepository = new DeviceConfigRepository();

  public componentDidMount = (): void => {
    if (
      !this.props.match.params.deviceNumber ||
      this.props.match.params.deviceNumber.length !== 8
    ) {
      alert('Device Number missing or wrong.');
      this.props.history.push('/app');
    }
  };

  private handleFromDeviceNumberChange = (e: React.ChangeEvent): void => {
    this.setState({
      fromDeviceNumber: `${e.target.value}`,
    });
  };

  private handleOnSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    try {
      await this.deviceConfigRepository.copyDeviceConfig(
        this.props.match.params.deviceNumber,
        this.state.fromDeviceNumber
      );

      this.setState({
        snackbarOpen: true,
        snackbarMessage: `${this.props.t('copy_config_toast_copied_success')}`,
      });
      this.props.history.push(`/app/DeviceConfig/${this.props.match.params.deviceNumber}`);
    } catch (error) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: error.response.data.message,
      });
    }
  };

  public render = (): React.ReactNode => {
    const { classes, t } = this.props;

    return (
      <Header>
        <Container>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper className={classes.paper}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                className={classes.grid}
              >
                <Grid item>
                  <Typography variant="h6">{t('copy_config_copy_config')}</Typography>
                  {this.props.selectedDevice && (
                    <Typography variant="subtitle1">
                      {t('copy_config_to_device_number_with_name', {
                        device_name: this.props.selectedDevice.name,
                        device_number: this.props.selectedDevice.number,
                        context: this.props.selectedDevice.name ? 'present' : 'absent',
                      })}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    value={this.state.fromDeviceNumber}
                    onChange={this.handleFromDeviceNumberChange}
                    variant="outlined"
                    label={`${t<string>('copy_config_from_device_number')}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={this.handleOnSubmit}
                  >
                    {t<string>('copy_config_btn_copy')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Snackbar
            open={this.state.snackbarOpen}
            message={this.state.snackbarMessage}
            autoHideDuration={4000}
            onClose={(): void => this.setState({ snackbarOpen: false })}
          />
        </Container>
      </Header>
    );
  };
}

interface CopyConfigPageProps extends WithTranslation, WithStyles<typeof styles>, WithRouterProps {}

interface CopyConfigPageState {
  fromDeviceNumber: string;
  snackbarOpen: boolean;
  snackbarMessage: string;
}

const mapStateToProps = (state: ReduxState): { selectedDevice: Device } => {
  return {
    selectedDevice: state.dev.selectedDevice,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(withStyles(styles, { withTheme: true })(withTranslation()(CopyConfigPage)))
);
