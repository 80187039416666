import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import {
  MutationOptions,
  MutationResult,
  QueryOptions,
  QueryResult,
} from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';

import SettingsDto from '@thingslog/repositories/src/settings/SettingsDto';

export interface SettingsQueryClient {
  getSettings: (companyId: string | null) => Promise<SettingsDto>;
  useSettings: (
    companyId: string | null,
    options?: QueryOptions<SettingsDto>
  ) => QueryResult<SettingsDto>;
  updateSettings: (companyId: string | null, body: SettingsDto) => Promise<void>;
  useUpdateSettings: (
    companyId: string | null,
    options?: MutationOptions<void, SettingsDto>
  ) => MutationResult<void, SettingsDto>;
}

export function createSettingsQueryClient(axios: AxiosInstance): SettingsQueryClient {
  return new SettingsQueryClientImp(axios);
}

class SettingsQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getSettings = async (companyId: string | null): Promise<SettingsDto> => {
    return await this.axios
      .get(`/api/settings`, {
        params: { companyId: companyId === null ? undefined : companyId },
      })
      .then((response: AxiosResponse) => response.data);
  };

  public useSettings = (
    companyId: string | null,
    options?: QueryOptions<SettingsDto>
  ): QueryResult<SettingsDto> => {
    return useQuery<SettingsDto, AxiosError>(
      [QueryKeys.getDashboard, companyId],
      () => this.getSettings(companyId),
      options
    );
  };

  public updateSettings = async (companyId: string | null, body: SettingsDto): Promise<void> => {
    return await this.axios
      .put(`/api/settings`, body, {
        params: { companyId: companyId === null ? undefined : companyId },
      })
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public useUpdateSettings = (
    companyId: string | null,
    options?: MutationOptions<void, SettingsDto>
  ): MutationResult<void, SettingsDto> => {
    return useMutation<void, AxiosError, SettingsDto>(
      [QueryKeys.UpdateSettings, companyId],
      (variables: SettingsDto) => this.updateSettings(companyId, variables),
      options
    );
  };
}
