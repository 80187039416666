import { Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  tableHeaderCell: {
    backgroundColor: 'lightgray',
  },
  addButton: {
    marginTop: 22,
  },
  actionButtons: {
    width: 115,
  },
  addUserTitle: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  addUserDialog: {
    width: 500,
    borderRadius: '25px',
  },
  editUserTitle: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  editUserDialog: {
    width: 500,
    borderRadius: '25px',
  },
  inlineFlexItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inlineFlexIcons: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableUserIcon: {
    marginTop: 1,
    marginRight: 8,
    fontSize: 20,
  },
  closeModalIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}));
