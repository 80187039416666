import { useMutation } from '@tanstack/react-query';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { MutationOptions, MutationResult } from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { FormulaValidationResult } from '@thingslog/repositories';

export interface FormulaValidatorQueryClient {
  validateFormula: (formula: string, params: string[]) => Promise<FormulaValidationResult>;
  useValidateFormula: (
    options?: MutationOptions<FormulaValidationResult, FormulaValidationProps>
  ) => MutationResult<FormulaValidationResult, FormulaValidationProps>;
}

export function createFormulaValidatorQueryClient(
  axios: AxiosInstance
): FormulaValidatorQueryClient {
  return new FormulaValidatorQueryClientImp(axios);
}

class FormulaValidatorQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public validateFormula = async (
    formula: string,
    params: string[]
  ): Promise<FormulaValidationResult> => {
    return await this.axios
      .post(`/api/formula-validator`, {
        formula: formula,
        params: params,
      })
      .then((response: AxiosResponse) => response.data);
  };

  public useValidateFormula = (
    options?: MutationOptions<FormulaValidationResult, FormulaValidationProps>
  ): MutationResult<FormulaValidationResult, FormulaValidationProps> => {
    return useMutation<FormulaValidationResult, AxiosError, FormulaValidationProps>(
      [QueryKeys.ValidateFormula],
      (props: FormulaValidationProps) => this.validateFormula(props.formula, props.params),
      options
    );
  };
}

interface FormulaValidationProps {
  formula: string;
  params: string[];
}
