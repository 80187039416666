import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  AutocompleteRenderInputParams,
  CircularProgress,
  Button,
  Input,
} from '@mui/material';
import React, { FC, useState, ReactNode, useEffect, SyntheticEvent } from 'react';
import Header from '../../components/header';
import { Device, Pagination, Port, UpdateIconDto } from '@thingslog/repositories';
import { SelectChangeEvent } from '@mui/material';
import {
  devicesQueryClient,
  iconsQueryClient,
  initialConfigQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { Autocomplete } from '@mui/material';
import IconsTable from './IconsTable';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { DeviceDropdownOptions, SensorDropdownOptions } from './models';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useModal, useToast } from '@thingslog/ui-components';
import { DeviceIconDto } from '@thingslog/repositories/src/icons';
import EditIconModal from './components/EditIconModal';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@thingslog/queries/src/enums/QueryKeys';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

export const IconsPage: FC<IconsPageProps> = ({}: IconsPageProps) => {
  const { useGetIcons, useUpdateIcon } = iconsQueryClient;
  const { useDevicesData } = devicesQueryClient;
  const { useDevicePortsConfigData } = initialConfigQueryClient;
  const { modal, closeModal } = useModal();
  const { toast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // #region State
  const [selectedDevice, setSelectedDevice] = useState<DeviceDropdownOptions | null>(null);
  const [deviceOptions, setDeviceOptions] = useState<DeviceDropdownOptions[]>([]);
  const [selectedSensor, setSelectedSensor] = useState<SensorDropdownOptions | null>(null);
  const [sensorOptions, setSelectedOptions] = useState<SensorDropdownOptions[]>([]);
  const reduxCompanyId = useSelector((state: ReduxState) => state.company.id);
  // #endregion

  // #region Queries
  const devicesQuery = useDevicesData(reduxCompanyId, 0, 9999, null, null, {
    refetchOnWindowFocus: false,
    onSuccess: (data: Pagination<Device>) => {
      const deviceOptions: DeviceDropdownOptions[] = [];
      data.content.forEach((device: Device) => {
        deviceOptions.push({ deviceNumber: device.number, deviceName: device.name });
      });
      setDeviceOptions(deviceOptions);
      if (deviceOptions.length > 0 && selectedDevice === null) {
        setSelectedDevice(deviceOptions[0]);
      }
    },
  });

  const portsQuery = useDevicePortsConfigData(selectedDevice?.deviceNumber || '', {
    enabled: selectedDevice !== null,
    refetchOnWindowFocus: false,
    onSuccess: (data: Record<number, Port>) => {
      const sensorOptions: SensorDropdownOptions[] = [];
      Object.entries(data).forEach(([index, port]: [string, Port]) => {
        sensorOptions.push({ sensorIndex: parseInt(index), sensorName: port.sensor.name });
      });
      setSelectedOptions(sensorOptions);
    },
  });

  const iconsQuery = useGetIcons(
    selectedDevice?.deviceNumber || '',
    selectedSensor ? selectedSensor.sensorIndex : null,
    reduxCompanyId,
    {
      enabled: selectedDevice !== null,
      refetchOnWindowFocus: false,
    }
  );

  const iconMutation = useUpdateIcon({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GetIcons]);
    },
    onError: (e: AxiosError) => {
      toast({
        type: 'error',
        message: e.response?.data?.message || t('icon_error_update_icon'),
      });
    },
  });
  // #endregion

  // #region Effects
  useEffect(() => {
    setSelectedSensor(null);
    if (selectedDevice) {
      portsQuery.refetch();
    }
  }, [selectedDevice]);

  // #endregion

  // #region Event Handlers
  const handleAddIconBtnClick = (): void => {
    navigate('/app/icons/add');
  };

  const handleEditIcon = (icon: DeviceIconDto): void => {
    modal({
      content: (
        <EditIconModal
          icon={icon}
          onSubmit={(id: number, body: UpdateIconDto): void => {
            const iconNoMime = body.icon.split(',')[1];
            iconMutation.mutate({ id: id, body: { ...body, icon: iconNoMime } });
            closeModal();
          }}
          onCancel={(): void => {
            closeModal();
          }}
        />
      ),
    });
  };
  // #endregion

  // #region Render
  return (
    <Header>
      <main className="flex flex-col gap-2">
        <section className="flex justify-between">
          <div className="flex gap-1.5">
            {devicesQuery.isSuccess && (
              <Autocomplete
                className="w-64"
                value={selectedDevice}
                onChange={(event: SyntheticEvent, value: DeviceDropdownOptions | null): void => {
                  setSelectedDevice(value);
                }}
                options={deviceOptions}
                getOptionLabel={(option: DeviceDropdownOptions): string =>
                  `${option.deviceName} (${option.deviceNumber})`
                }
                renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                  <TextField {...params} size="small" label={t('icon_input_device')} />
                )}
              />
            )}
            {devicesQuery.isLoading && <CircularProgress size={28} />}
            {selectedDevice !== null && portsQuery.isSuccess && (
              <Autocomplete
                className="w-64"
                value={selectedSensor}
                onChange={(event: SyntheticEvent, value: SensorDropdownOptions | null): void => {
                  setSelectedSensor(value);
                }}
                options={sensorOptions}
                getOptionLabel={(option: SensorDropdownOptions): string => option.sensorName}
                renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                  <TextField {...params} size="small" label={t('icon_input_sensor')} />
                )}
              />
            )}
            {selectedDevice !== null && portsQuery.isLoading && <CircularProgress size={28} />}
          </div>
          <div>
            <Button startIcon={<AddIcon />} onClick={handleAddIconBtnClick}>
              {t('icon_btn_add_icon')}
            </Button>
          </div>
        </section>
        {iconsQuery.isSuccess && (
          <section>
            <IconsTable rows={iconsQuery.data.icons} onEditIcon={handleEditIcon} />
          </section>
        )}
      </main>
    </Header>
  );
  // #endregion
};

interface IconsPageProps {}
