import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import {
  DeviceConfig,
  MutationOptions,
  MutationResult,
  QueryResult,
} from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions } from '@thingslog/repositories';

export function createDeviceConfigQueryClient(axios: AxiosInstance): DeviceConfigQueryClient {
  return new DeviceConfigQueryClientImp(axios);
}

class DeviceConfigQueryClientImp {
  public constructor(private axios: AxiosInstance) {}
  public useDeviceConfig = (
    deviceNumber: string,
    options?: QueryOptions<DeviceConfig>
  ): QueryResult<DeviceConfig> => {
    return useQuery<DeviceConfig, AxiosError>(
      [QueryKeys.GetDeviceConfig, deviceNumber],
      () => this.getDeviceConfig(deviceNumber),
      options
    );
  };

  public useGetDevicesConfigsBatch = (
    options?: MutationOptions<Record<string, DeviceConfig>, string[]>
  ): MutationResult<Record<string, DeviceConfig>, string[]> => {
    return useMutation<Record<string, DeviceConfig>, AxiosError, string[]>(
      [QueryKeys.GetDevicesConfigsBatch],
      (props: string[]) => this.getDevicesConfigsBatch(props),
      options
    );
  };

  public getDeviceConfig = async (deviceNumber: string): Promise<DeviceConfig> => {
    return await this.axios
      .get(`/api/devices/${deviceNumber}/config`)
      .then((response: AxiosResponse) => response.data);
  };

  public getDevicesConfigsBatch = async (
    deviceNumbers: string[]
  ): Promise<Record<string, DeviceConfig>> => {
    return await this.axios
      .post(`/api/devices/batch/config`, deviceNumbers)
      .then((response: AxiosResponse) => response.data);
  };
}

export interface DeviceConfigQueryClient {
  useDeviceConfig: (
    deviceNumber: string,
    options?: QueryOptions<DeviceConfig>
  ) => QueryResult<DeviceConfig>;
  useGetDevicesConfigsBatch: (
    options?: MutationOptions<Record<string, DeviceConfig>, string[]>
  ) => MutationResult<Record<string, DeviceConfig>, string[]>;

  getDeviceConfig: (deviceNumber: string) => Promise<DeviceConfig>;
  getDevicesConfigsBatch: (deviceNumbers: string[]) => Promise<Record<string, DeviceConfig>>;
}
