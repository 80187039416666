import { Component } from 'react';
import * as actions from '../actions';
import { connect } from 'react-redux';
import DevicesMenu from '../DevicesMenu';
import React from 'react';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { withRouter } from '../common/withRouter';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ArrowRight from '@mui/icons-material/ArrowRight';
import * as roles from '../common/role_utils';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ArticleIcon from '@mui/icons-material/Article';
import Menu from '@mui/material/Menu';
import { withTranslation } from 'react-i18next';
import GoogleAnalyticsService from '../common/GoogleAnalyticsService';
import { GaEventCategory } from '../common/GaEventCategory';
import { GaEventAction } from '../common/GaEventAction';
import LicenseValidator from '../common/LicenseValidator';
import NatureIcon from '@mui/icons-material/Nature';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import AuthUtil from '../common/AuthUtil';
import SideMenu from './Navigation/SideMenu';
import { Footer } from './footer/Footer';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: 20,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    zIndex: 20,
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: '90%',
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
});

const { featureFlags } = window['runConfig'];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.drawerOpened === null ? false : this.props.drawerOpened,
      generalOpen: false,
      deviceOpen: false,
      profileMenuOpen: false,
      anchorEl: null,
      useOldMenu: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDevicesMenuButtonClicked = this.handleDevicesMenuButtonClicked.bind(this);
    this.handleDeviceMenuClose = this.handleDeviceMenuClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleProfileMenuToggle = this.handleProfileMenuToggle.bind(this);
    this.handleProfileMenuIconClick = this.handleProfileMenuIconClick.bind(this);
  }

  componentDidMount() {
    const useOldMenu = localStorage.getItem('useOldMenu');
    if (useOldMenu) {
      this.setState({ useOldMenu: true });
    }
  }
  handleToggle() {
    this.props.devOpenCloseMenu(!this.props.menuOpen);
    if (!this.props.menuOpen === false) {
      this.switchToOldMenu(false);
    }
  }

  handleProfileMenuToggle() {
    this.setState({ profileMenuOpen: !this.state.profileMenuOpen });
  }

  handleProfileMenuIconClick = (event) => {
    this.setState({
      profileMenuOpen: !this.state.profileMenuOpen,
      anchorEl: event.currentTarget,
    });
  };

  handleClose() {
    this.props.devOpenCloseMenu(false);
    !this.props.menuOpen;
  }

  handleOpen() {
    this.props.devOpenCloseMenu(false);
    this.props.devOpenCloseDevMenu(true, true);
  }

  switchToOldMenu = (value) => {
    this.setState({ useOldMenu: value });
    localStorage.setItem('useOldMenu', 'true');
  };

  isFeatureFlagOn = (flagName) => {
    return this.props.featureFlags?.featureFlagsArray?.includes(flagName);
  };

  renderLinks() {
    // let hasUserRole = roles.hasRole(this.props.auth, roles.ROLE_USER);
    let hasAdminRole = roles.hasRole(this.props.auth, roles.ROLE_ADMIN);
    let hasSuperAdminRole = roles.hasRole(this.props.auth, roles.ROLE_SUPER_ADMIN);
    let hasBillingRole = roles.hasRole(this.props.auth, roles.ROLE_BILLING);

    const { classes } = this.props;
    let menu = [];
    if (!this.props.devMenu) {
      menu = (
        <List open={this.props.menuOpen}>
          <ListItem
            button
            onClick={() => {
              this.handleOpen();
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_SELECTED_DEVICE_PAGE_CLICK
              );
            }}
            disabled={this.props.devices.length === 0}
          >
            <ListItemText primary={this.props.t('link_selected_device')} />
            <ListItemIcon>
              <ArrowRight />
            </ListItemIcon>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push('/app/Devices');
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_DEVICES_PAGE_CLICK
              );
            }}
          >
            <ListItemText primary={this.props.t('link_devices')} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push('/app/AllAlarms');
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_ALL_ALARMS_PAGE_CLICK
              );
            }}
          >
            <ListItemText primary={this.props.t('link_alarms')} />
          </ListItem>
          {(hasAdminRole || hasSuperAdminRole) && (
            <React.Fragment>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/ReportsExport');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_REPORTS_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_reports')} />
              </ListItem>
              {this.isFeatureFlagOn('BILLING_REPORT_PAGE') && (
                <ListItem
                  button
                  onClick={() => {
                    this.props.devOpenCloseMenu(false);
                    this.props.history.push('/app/BillingReport');
                    GoogleAnalyticsService.triggerEvent(
                      GaEventCategory.MENU_NAVIGATION,
                      GaEventAction.MENU_BILLING_REPORT_PAGE_CLICK
                    );
                  }}
                >
                  <ListItemText primary={this.props.t('link_billing_report')} />
                </ListItem>
              )}
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/DeviceCountersExport');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_EXPORT_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_export')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/DeviceLocation');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_MAP_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_map')} />
              </ListItem>
              {this.isFeatureFlagOn('NEW_GRAPH_PAGE') && (
                <ListItem
                  button
                  onClick={() => {
                    this.props.devOpenCloseMenu(false);
                    this.props.history.push('/app/NewGraph');
                    GoogleAnalyticsService.triggerEvent(
                      GaEventCategory.MENU_NAVIGATION,
                      GaEventAction.MENU_GRAPH_PAGE_CLICK
                    );
                  }}
                >
                  <ListItemText primary={this.props.t('link_graph')} />
                </ListItem>
              )}
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/Transmissions');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_TRANSMISSIONS_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_transmissions')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/DeviceInventory');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_INVENTORY_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_inventory')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/LinkDevices');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_LINK_DEVICE_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_link_device')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/Users');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_USERS_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_show_users')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/Accounts');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_ACCOUNTS_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_accounts')} />
              </ListItem>
              {hasSuperAdminRole && (
                <ListItem
                  button
                  onClick={() => {
                    this.props.devOpenCloseMenu(false);
                    this.props.history.push('/app/DeviceCompanyLookup');
                  }}
                >
                  <ListItemText primary={this.props.t('link_device_account_lookup')} />
                </ListItem>
              )}
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/AccountsManager');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_MANAGE_ACCOUNTS_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_accounts_manager')} />
              </ListItem>
              {this.props.auth.hasLicensesAccess && (
                <ListItem
                  button
                  onClick={() => {
                    this.props.devOpenCloseMenu(false);
                    this.props.history.push('/app/Licenses');
                    GoogleAnalyticsService.triggerEvent(
                      GaEventCategory.MENU_NAVIGATION,
                      GaEventAction.MENU_MANAGE_LICENSES_PAGE_CLICK
                    );
                  }}
                >
                  <ListItemText primary={this.props.t('link_licenses')} />
                </ListItem>
              )}
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/DevicesBattery');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_DEVICES_BATTERY_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_battery')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/DeviceGroups');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_DEVICE_GROUP_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('device_groups')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/DevicesSignal');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_DEVICES_SIGNAL_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_signal')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push('/app/icons');
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_ICON_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_icons')} />
              </ListItem>
              {!hasBillingRole && (
                <ListItem
                  button
                  onClick={() => {
                    this.props.devOpenCloseMenu(false);
                    this.props.history.push('/app');
                    GoogleAnalyticsService.triggerEvent(
                      GaEventCategory.MENU_NAVIGATION,
                      GaEventAction.MENU_DASHBOARD_PAGE_CLICK
                    );
                  }}
                >
                  <ListItemText primary={this.props.t('link_dashboard')} />
                </ListItem>
              )}
              {(hasBillingRole || hasSuperAdminRole) && (
                <ListItem
                  button
                  onClick={() => {
                    this.props.devOpenCloseMenu(false);
                    this.props.history.push('/app/CustomerBillingReport');
                    GoogleAnalyticsService.triggerEvent(
                      GaEventCategory.MENU_NAVIGATION,
                      GaEventAction.MENU_CUSTOMER_BILLING_REPORT_PAGE_CLICK
                    );
                  }}
                >
                  <ListItemText primary={this.props.t('link_customer_billing_report')} />
                </ListItem>
              )}
            </React.Fragment>
          )}
        </List>
      );
    } else {
      if (this.props.selectMode === 'radio') {
        menu = (
          <DevicesMenu
            deviceNumber={this.props.devices}
            open={this.props.menuOpen}
            onClose={this.handleDeviceMenuClose}
          />
        );
      } else if (this.props.selectMode === 'checkbox') {
        // menu = (<MultiDevicesMenu devicesNumber={this.props.devices}
        //                           open={this.props.menuOpen}
        //                           onClose={this.handleDeviceMenuClose}
        // />);
        menu = (
          <DevicesMenu
            deviceNumber={this.props.devices[0]}
            open={this.props.menuOpen}
            onClose={this.handleDeviceMenuClose}
          />
        );
      }
    }
    if (this.props.auth.authenticated) {
      return (
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.props.menuOpen && classes.drawerPaperClose
            ),
          }}
          open={this.props.menuOpen}
        >
          <div className="flex justify-between items-center">
            <span
              className="cursor-pointer pl-5"
              onClick={() => {
                this.switchToOldMenu(false);
                localStorage.removeItem('useOldMenu');
              }}
            >
              {this.props.t('switch_to_new_menu_label')}
            </span>
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </div>
          </div>
          {menu}
        </Drawer>
      );
    } else {
      return [];
    }
  }

  handleDevicesMenuButtonClicked() {
    this.props.devOpenCloseMenu(true);
  }

  handleDeviceMenuClose() {
    this.props.devOpenCloseMenu(false);
    this.switchToOldMenu(false);
  }

  handleDrawerOpen = () => {
    this.props.devOpenCloseMenu(true);
  };

  handleDrawerClose = () => {
    this.props.devOpenCloseMenu(false);
  };

  isAgriIconVisible = () => {
    const isDashboardEnabled = AuthUtil.hasDashboard(this.props.auth);
    return isDashboardEnabled;
  };

  redirectToAgri = () => {
    const location = window.location;
    window.location.href = `${location.protocol}//${location.host}/portal`;
  };

  redirectToEnergy = () => {
    const location = window.location;
    window.location.href = `${location.protocol}//${location.host}/energy`;
  };

  render() {
    let hasAdminRole = roles.hasRole(this.props.auth, roles.ROLE_ADMIN);
    let hasSuperAdminRole = roles.hasRole(this.props.auth, roles.ROLE_SUPER_ADMIN);
    let companyName = '';
    if (this.props.auth.authenticated) {
      companyName = this.props.auth.companyName;
    }

    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.props.menuOpen && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.props.menuOpen} className={classes.toolbar}>
            {this.props.auth.authenticated && !this.props.auth.isRestricted ? (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.props.menuOpen && classes.menuButtonHidden
                )}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            ) : (
              ''
            )}
            <Typography
              component="h1"
              variant="h5"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {companyName == null
                ? 'ThingsLog'
                : this.props.company.name === null
                ? companyName
                : companyName + ' - ' + this.props.company.name}
            </Typography>
            <div>
              {false && (
                <IconButton onClick={this.redirectToEnergy} size="large">
                  <FlashOnIcon />
                </IconButton>
              )}
              {this.isAgriIconVisible() && (
                <IconButton onClick={this.redirectToAgri} size="large">
                  <NatureIcon />
                </IconButton>
              )}
              {this.props.auth.authenticated && (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    const currentUrl = new URL(window.location.href);
                    const targetUrl = new URL('/docs/tl-en.pdf', currentUrl.origin);
                    window.open(targetUrl, '_blank');
                  }}
                  size="large"
                >
                  <ArticleIcon />
                </IconButton>
              )}
              {this.props.auth.authenticated && (
                <IconButton onClick={this.handleProfileMenuIconClick} size="large">
                  <AccountCircle />
                </IconButton>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <Menu
          id="profile-menu"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.profileMenuOpen}
          onClose={this.handleProfileMenuToggle}
        >
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.switchToOldMenu(false);
              this.props.history.push('/app/Profile');
            }}
          >
            <ListItemText primary={this.props.t('link_profile')} />
          </ListItem>
          {this.isFeatureFlagOn('SETTINGS_PAGE') && (hasAdminRole || hasSuperAdminRole) && (
            <ListItem
              button
              onClick={() => {
                this.props.history.push('/app/Settings');
              }}
            >
              <ListItemText primary={this.props.t('settings_page_header')} />
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push('/app/Language');
            }}
          >
            <ListItemText primary={this.props.t('link_language')} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push('/app/signout');
            }}
          >
            <ListItemText primary={this.props.t('link_sign_out')} />
          </ListItem>
        </Menu>
        {this.isFeatureFlagOn('SIDE_MENU') ? (
          <>
            {this.state.useOldMenu ? (
              this.renderLinks()
            ) : (
              <SideMenu
                companyName={this.props.auth.companyName}
                username={this.props.auth.username}
                selectedDevice={this.props.devices[0]}
                menuOpen={this.props.menuOpen}
                switchToOldMenu={this.switchToOldMenu}
              />
            )}
          </>
        ) : (
          this.renderLinks()
        )}
        <div className="flex flex-col justify-between w-full h-screen">
          <main className={`${classes.content}`}>
            <div className={classes.appBarSpacer} />
            <Typography component="div" className={classes.chartContainer}></Typography>
            <div className={classes.tableContainer}>{this.props.children}</div>
          </main>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    devices: state.dev.devices,
    selectMode: state.dev.selectMode,
    menuOpen: state.dev.menuOpen,
    devMenu: state.dev.devMenu,
    company: state.company,
    featureFlags: state.featureFlags,
  };
}

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles)(withTranslation()(withRouter(Header))));
