import React, { useEffect, useState, ReactNode, FC } from 'react';
import { Chip } from '@mui/material';
import { DataGrid, DataGridProps, GridValueFormatterParams } from '@thingslog/ui-components';
import { GridRenderCellParams, GridValueGetterParams } from '@thingslog/ui-components/src/DataGrid';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import clsx from 'clsx';

const AlarmsTable: FC<AlarmsTableProps> = ({
  pageSize,
  autoHeight,
  rowsPerPageOptions,
  onPageSizeChange,
  ...props
}: AlarmsTableProps) => {
  const { t } = useTranslation();
  const [size, setSize] = useState(10);

  const onPageSizeChangeHandler = (pageSize: number): void => setSize(pageSize);

  return (
    <DataGrid
      pageSize={pageSize ? pageSize : size}
      rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [10, 25, 50]}
      autoHeight={autoHeight ? autoHeight : true}
      onPageSizeChange={onPageSizeChange ? onPageSizeChange : onPageSizeChangeHandler}
      columns={[
        {
          field: 'date',
          headerName: t('alarms_export_table_date'),
          type: 'date',
          flex: 0.75,
          minWidth: 140,
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            params.value ? format(new Date(params.value), 'dd MMM yyyy HH:mm') : '',
        },
        {
          field: 'deviceNumber',
          headerName: t('alarms_export_table_device_number'),
          flex: 0.75,
          minWidth: 110,
        },
        {
          field: 'deviceName',
          headerName: t('alarms_export_table_device_name'),
          flex: 0.75,
          minWidth: 150,
        },
        {
          field: 'sensorIndex',
          headerName: t('alarms_export_table_sensor_index'),
          headerAlign: 'left',
          type: 'number',
          flex: 0.6,
          minWidth: 90,
          align: 'center',
        },
        {
          field: 'sensorName',
          headerName: t('alarms_export_table_sensor_name'),
          flex: 0.7,
          minWidth: 150,
        },
        {
          field: 'alarmType',
          headerName: t('alarms_export_table_alarm_type'),
          flex: 0.9,
          minWidth: 180,
        },
        {
          field: 'alarmStatusType',
          headerName: t('alarms_export_table_alarm_status'),
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams): string | null => {
            if (params.value) {
              return params.value === 'ACTIVE'
                ? t('alarms_export_chip_status_active').toUpperCase()
                : t('alarms_export_chip_status_inactive').toUpperCase();
            } else {
              return null;
            }
          },
          minWidth: 90,
          renderCell: (params: GridRenderCellParams<string | null>): ReactNode => {
            if (params.value !== null) {
              return (
                <Chip
                  title={params.value}
                  className={clsx(
                    params.value === t('alarms_export_chip_status_active').toUpperCase() &&
                      'bg-red-400 text-white ',
                    params.value === t('alarms_export_chip_status_inactive').toUpperCase() &&
                      'bg-green-500 text-white'
                  )}
                  label={params.value}
                  variant="outlined"
                  size="small"
                />
              );
            } else {
              return <></>;
            }
          },
        },
        {
          field: 'description',
          headerName: t('alarms_export_table_description'),
          flex: 1,
          minWidth: 150,
        },
        {
          field: 'linkToGraph',
          headerName: '',
          sortable: false,
          width: 50,
          align: 'center',
          renderCell: (params: GridRenderCellParams<string | null>): ReactNode => {
            return (
              <QueryStatsIcon
                className="cursor-pointer"
                onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
                  event.stopPropagation();
                  props.onGraphIconClickCallback(params.row.deviceNumber);
                }}
              />
            );
          },
        },
      ]}
      {...props}
    />
  );
};

interface AlarmsTableProps extends Omit<DataGridProps, 'columns'> {
  onGraphIconClickCallback: (deviceNumber: string) => void;
}

export default AlarmsTable;
