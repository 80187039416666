import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Box, IconButton } from '@mui/material';
import { Device, DeviceFilterAttributes } from '@thingslog/repositories';
import { DeviceModel } from '@thingslog/repositories/src/deviceModel/DeviceModel';
import { useTranslation } from 'react-i18next';
import {
  DataGrid,
  DataGridHeader,
  DataGridProps,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from '@thingslog/ui-components';
import { CreateOrUpdateDeviceProps, UpdateDeviceProps } from './models/CreateOrUpdateDeviceProps';

const InventoryTable: FC<InventoryTableProps> = ({
  devices,
  setFilterValue,
  setAttributes,
  filterValue,
  showDeleteIcons,
  onDeleteOrUnlinkDevice,
  onUpdateDevice,
  companyId,
  showUnlinkBtn,
  ...props
}: InventoryTableProps) => {
  const [activeAttribute, setActiveAttribute] = useState<DeviceFilterAttributes | null>(null);
  const [rows, setRows] = useState<InventoryTableRow[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    let rows: InventoryTableRow[] = [];
    devices.forEach((device: Device) => {
      rows.push({
        description: device.description || '',
        name: device.name,
        deviceNumber: device.number,
        hwVersion: device.hwVersion,
        id: device.number,
        model: device.model,
        deviceIcon: device.deviceIcon,
        swVersion: device.swVersion,
      });
    });
    setRows(rows);
  }, [devices]);

  const handleFilterValueChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === '' || e.target.value === null) {
      setActiveAttribute(null);
      setAttributes(null);
      setFilterValue('');
    } else {
      setActiveAttribute(DeviceFilterAttributes[e.target.id]);
      setAttributes(DeviceFilterAttributes[e.target.id]);
      setFilterValue(e.target.value);
    }
  };

  return (
    <DataGrid
      rows={rows}
      columns={[
        {
          field: 'deviceNumber',
          headerName: t('devices_table_device_number'),
          flex: 1.2,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName!}
                attribute={DeviceFilterAttributes.deviceNumber}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'name',
          headerName: t('devices_table_device_name'),
          flex: 1.5,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName!}
                attribute={DeviceFilterAttributes.deviceName}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'hwVersion',
          headerName: t('device_inventory_hardware_version'),
          flex: 1,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName!}
                attribute={DeviceFilterAttributes.hw_version}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'swVersion',
          headerName: t('device_inventory_software_version'),
          flex: 1.5,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName!}
                attribute={DeviceFilterAttributes.sw_version}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'model',
          headerName: t('model'),
          flex: 1.2,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName!}
                attribute={DeviceFilterAttributes.model}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'description',
          headerName: t('description'),
          flex: 1.5,
          minWidth: 100,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName!} />;
          },
        },
        {
          field: 'actions',
          headerName: t('device_inventory_actions'),
          flex: 0.8,
          minWidth: 100,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName!} />;
          },
          renderCell: (params: GridRenderCellParams): ReactElement => {
            return (
              <Box>
                <IconButton
                  onClick={(e: MouseEvent): void => {
                    e.stopPropagation();
                    onUpdateDevice({ type: 'UPDATE', ...params.row } as UpdateDeviceProps);
                  }}
                >
                  <EditIcon />
                </IconButton>
                {showDeleteIcons && (
                  <IconButton
                    onClick={(e: MouseEvent): void => {
                      e.stopPropagation();
                      onDeleteOrUnlinkDevice(params.id.toString(), companyId, showUnlinkBtn);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            );
          },
        },
      ]}
      {...props}
    />
  );
};

interface InventoryTableProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  devices: Device[];
  filterValue: string;
  showDeleteIcons: boolean;
  setAttributes: (attribute: DeviceFilterAttributes | null) => void;
  setFilterValue: (filterValue: string) => void;
  onDeleteOrUnlinkDevice: (
    selectedDevice: string,
    companyId: number | null,
    showUnlinkBtn: boolean
  ) => void;
  onUpdateDevice: (deviceData: CreateOrUpdateDeviceProps) => void;
  companyId: number | null;
  showUnlinkBtn: boolean;
}

export interface InventoryTableRow {
  description: string;
  name: string;
  deviceNumber: string;
  hwVersion: string;
  id: string;
  model: DeviceModel;
  deviceIcon: string | null;
  swVersion: string;
}

export default InventoryTable;
