import * as React from 'react';

import { Form, Formik, FormikState } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../reducers';

import Button from '@mui/material/Button';
import { InputTextField } from '../../../../../components/InputTextField/InputTextField';
import { Box, Autocomplete, TextField, AutocompleteRenderInputParams } from '@mui/material';
import DeviceGroup from '../../../../../model/API/DeviceGroup/DeviceGroup';
import { FC, ReactNode, useState } from 'react';
import ColorsPicker from './components/ColorsPicker';

const AddGroupModal: FC<AddGroupModalProps> = (props: AddGroupModalProps) => {
  const { deviceGroups, handleOnCreateDeviceGroup } = props;

  const flatProps = {
    options: deviceGroups.map((deviceGroup: DeviceGroup) => deviceGroup.deviceGroupName),
  };

  const { t } = useTranslation();

  const companyId = useSelector((state: ReduxState) => state.company.id);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [parentGroupName, setParentGroupName] = useState<string | undefined>(undefined);
  const [deviceGroupColor, setDeviceGroupColor] = useState<string>('#f44336');
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);

  return (
    <Formik
      initialValues={{
        deviceGroupName: '',
      }}
      onSubmit={async (values: FormikAddGroupModalState): Promise<void> => {
        if (values.deviceGroupName) {
          let deviceGroup = {
            deviceGroupName: values.deviceGroupName,
            deviceSensors: [],
            parentDeviceGroupName: parentGroupName,
            companyId: companyId!,
            deviceGroupColor: deviceGroupColor,
          };
          handleOnCreateDeviceGroup(deviceGroup);
        } else {
          setErrorMsg(t('device_groups_sensors_add_modal_device_group_name_required'));
        }
      }}
    >
      {({ isSubmitting }: FormikState<FormikAddGroupModalState>): ReactNode => {
        return (
          <Form>
            <Box marginTop={1}>
              <InputTextField
                name="deviceGroupName"
                label={t('device_groups_group_name')}
                required
              />
            </Box>
            <Box marginTop={2}>
              <Autocomplete
                {...flatProps}
                onChange={(event: any): void => {
                  //The event renders a list of <li> options with innerText = the name of the deviceGroup
                  setParentGroupName(event.target.innerText);
                }}
                renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                  <TextField
                    name="parentDeviceGroupName"
                    {...params}
                    label={t<string>('device_groups_parent_device_group')}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Box>
            <Box marginTop={2} display="flex" justifyContent={'center'} alignItems={'center'}>
              <ColorsPicker
                isColorPickerOpen={isColorPickerOpen}
                setIsColorPickerOpen={setIsColorPickerOpen}
                color={deviceGroupColor}
                onColorChange={(color: string): void => setDeviceGroupColor(color)}
              />
            </Box>
            <div className="flex justify-center w-full">
              {!!errorMsg && (
                <span className="text-red-500 font-semibold">
                  <span>{errorMsg}</span>
                </span>
              )}
            </div>
            {!isColorPickerOpen && (
              <Box marginTop={3}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  disableElevation
                >
                  {t<string>('device_groups_btn_add')}
                </Button>
              </Box>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

interface AddGroupModalProps {
  deviceGroups: DeviceGroup[];
  handleOnCreateDeviceGroup: (deviceGroup: DeviceGroup) => Promise<void>;
}

interface FormikAddGroupModalState {
  deviceGroupName: string;
}

export default AddGroupModal;
