import React, { Component, Fragment } from 'react';
import 'react-select/dist/react-select.css';
import sensorsConfigClient from './SensorsConfigClient';
import timeZonesClient from './TimeZonesClient';
import devicesClient from './DevicesClient';
import { formulaValidatorQueryClient } from './clients/ReactQueryClients/ReactQueryClients';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import clsx from 'clsx';
import connect from 'react-redux/es/connect/connect';
import * as actions from './actions';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  FormControl as MuiFormControl,
  CircularProgress,
  InputLabel,
  IconButton,
  Snackbar,
  MenuItem,
  Tooltip,
  Select,
  Button,
} from '@mui/material';
import Switch from '@mui/material/Switch/Switch';
import SaveIcon from '@mui/icons-material/Save';
import FlowConfiguration from './components/FlowConfiguration/FlowConfiguration';
import TitleHeader from './components/TitleHeader/TitleHeader';
import Header from './components/header';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventCategory } from './common/GaEventCategory';
import { GaEventAction } from './common/GaEventAction';
import { withRouter } from './common/withRouter';
import JwtValidator from './common/JwtValidator';
import { SensorIconNames } from './model/SensorIconNames/SensorIconNames';
import { FlowTimeUnits } from './model/FlowTimeUnits/FlowTimeUnits';
import { SensorsUtil } from './common/SensorsUtil';

class SensorsConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceName: null,
      customerInfo: null,
      ports: null,
      model: null,
      allUtilityRates: [],
      utilityRatesSnapshot: null,
      utilityRatesUpdated: null,
      snackbarMsg: null,
      formulaValidity: new Map(),
      isFormulaValidated: true,
      hasInvalidFormula: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadDeviceConfigFromServer = this.loadDeviceConfigFromServer.bind(this);
    this.handleConfigSaved = this.handleConfigSaved.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.handleNameChanged = this.handleNameChanged.bind(this);
    this.handleIconNameChanged = this.handleIconNameChanged.bind(this);
    this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
    this.handleSensorGroupNameChanged = this.handleSensorGroupNameChanged.bind(this);
    this.handleSensorTypeChanged = this.handleSensorTypeChanged.bind(this);
    this.handleModelChanged = this.handleModelChanged.bind(this);
    // this.handleScaleChanged = this.handleScaleChanged.bind(this);
    // this.handlePrecisionChanged = this.handlePrecisionChanged.bind(this);
    // this.handleUnitsTypeChanged = this.handleUnitsTypeChanged.bind(this);
    this.handleSerialNumberChanged = this.handleSerialNumberChanged.bind(this);
    this.handleManufacturerChanged = this.handleManufacturerChanged.bind(this);
    this.handleDiameterChanged = this.handleDiameterChanged.bind(this);
    this.handleQ3Changed = this.handleQ3Changed.bind(this);
    this.handleRChanged = this.handleRChanged.bind(this);
    this.handlePriceChanged = this.handlePriceChanged.bind(this);
    // this.handleSensorFormulaChanged = this.handleSensorFormulaChanged.bind(this);
    this.handlePortFormulaChanged = this.handlePortFormulaChanged.bind(this);
    this.handlePortFormulaValidation = this.handlePortFormulaValidation.bind(this);
    this.handlePulseRateChanged = this.handlePulseRateChanged.bind(this);
    // this.handleInitialValueChanged = this.handleInitialValueChanged.bind(this);
    this.handleOffsetChanged = this.handleOffsetChanged.bind(this);
    this.handleFlowCoefficientChanged = this.handleFlowCoefficientChanged.bind(this);
    this.handleFlowTimeUnitsChanged = this.handleFlowTimeUnitsChanged.bind(this);
    this.handleFlowUnitsChanged = this.handleFlowUnitsChanged.bind(this);
    this.checkInvalidFlowConfigurationFields = this.checkInvalidFlowConfigurationFields.bind(this);
    this.handleIsVisibleChanged = this.handleIsVisibleChanged.bind(this);
    this.handleOrderNumberChanged = this.handleOrderNumberChanged.bind(this);
    this.handleUseInSensorsAggregationChanged =
      this.handleUseInSensorsAggregationChanged.bind(this);
    this.jwtValidator = new JwtValidator();
    this.loadUtilityRateForCompanyFromServer = this.loadUtilityRateForCompanyFromServer.bind(this);
    this.loadUtilityRateforDeviceFromServer = this.loadUtilityRateForDeviceFromServer.bind(this);
    this.handleValueLabelsChanged = this.handleValueLabelsChanged.bind(this);
    this.handleSensorInvertedReadStateChanged =
      this.handleSensorInvertedReadStateChanged.bind(this);
  }

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else if (error.response.data) {
      if (error.response.data.message) {
        alert('Error: ' + error.response.data.message);
      } else {
        alert('Error: ' + error.response.data);
      }
    }
  }

  handleSubmit(event) {
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.SENSOR_CONFIG_PAGE,
      GaEventAction.SENSOR_CONFIG_SAVE
    );

    const invalidSensorsConfigurations = this.checkInvalidFlowConfigurationFields(this.state.ports);

    if (invalidSensorsConfigurations.length === 0) {
      this.setState({ saveDisabled: true });
      sensorsConfigClient.putPortsConfig(
        this.props.match.params.deviceNumber,
        this.state.ports,
        this.handleConfigSaved,
        this.errorCallback
      );
      event.preventDefault();
    } else {
      let message = '';
      invalidSensorsConfigurations.forEach((sensor) => {
        message += t('flow_configuration_invalid_sensor_row_message', {
          sensor_index: sensor.index,
          sensor_name: sensor.name,
        });
      });
      alert(t('flow_configuration_alert_message', { message: message }));
    }
  }

  handleConfigSaved() {
    this.setState({
      open: true,
      snackbarMsg: this.props.t('successfully_saved'),
      saveDisabled: false,
    });
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  checkInvalidFlowConfigurationFields = (ports) => {
    let invalidSensorsFlowConfigurations = [];
    for (const [key, value] of Object.entries(ports)) {
      if (
        value.sensor.flowUnit?.timeUnits &&
        value.sensor.flowUnit?.timeUnits !== FlowTimeUnits.HOURS &&
        value.sensor.flowUnit?.timeUnits !== null &&
        value.sensor.flowUnit?.timeUnits !== '' &&
        (value.sensor.flowConversionCoefficient === null ||
          value.sensor.flowConversionCoefficient === '')
      ) {
        invalidSensorsFlowConfigurations.push({
          index: key,
          name:
            value.sensor.name !== '' && value.sensor.name !== null
              ? value.sensor.name
              : t('flow_configuration_sensor_name_not_set_label'),
        });
      }
    }

    return invalidSensorsFlowConfigurations;
  };

  render() {
    const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };
    let self = this;
    if (this.state.ports != null) {
      return (
        <Header>
          <div className="well" style={wellStyles}>
            <TitleHeader
              className="mb-4"
              pageType="form"
              title={this.props.t('sensor_config_header')}
              deviceNumber={this.props.match.params.deviceNumber}
              deviceName={this.state.deviceName}
              customerInfo={this.state.customerInfo}
            />
            <form>
              {Object.keys(Array.from(Array(14), (x, index) => index + 1)).map(function (key) {
                let i = Number(key);
                const portEnabled =
                  self.state.ports[i]?.enabled === true && self.state.ports[i]?.enabled === true;
                if (!portEnabled) return null;
                return (
                  <Fragment key={'fragment_' + i}>
                    <FormGroup controlId={'sensor_' + i}>
                      <ControlLabel>
                        <Tooltip
                          arrow
                          placement="top"
                          title={t('sensor_config_sensor_index_tooltip', { sensor_index: i })}
                        >
                          <span>{self.state.ports[i].sensor.name}</span>
                        </Tooltip>
                      </ControlLabel>
                      <Switch label={'Sensor ' + i} checked={self.state.ports[i].enabled} />
                    </FormGroup>
                    <div style={{ display: self.state.ports[i].enabled ? 'block' : 'none' }}>
                      {self.state.ports[i].enabled && (
                        <Fragment>
                          <FormGroup controlId="sensor_name">
                            <ControlLabel>{self.props.t('sensor_config_sensor_name')}</ControlLabel>
                            <FormControl
                              value={self.state.ports[i].sensor.name}
                              type="text"
                              placeholder={self.props.t('sensor_config_sensor_name')}
                              onChange={(event) => self.handleNameChanged(i, event)}
                            />
                          </FormGroup>
                          <FormGroup controlId="sensor_icon_name">
                            <ControlLabel>{self.props.t('sensor_config_sensor_icon')}</ControlLabel>
                            <FormControl
                              value={self.state.ports[i].sensor.iconName}
                              componentClass="select"
                              placeholder={self.props.t('sensor_config_sensor_icon')}
                              onChange={(event) => self.handleIconNameChanged(i, event)}
                            >
                              {Object.entries(SensorIconNames).map(([key, value], index) => (
                                <option value={value} key={index}>
                                  {key}
                                </option>
                              ))}
                            </FormControl>
                          </FormGroup>
                          <FormGroup controlId="sensor_description">
                            <ControlLabel>{self.props.t('sensor_config_description')}</ControlLabel>
                            <FormControl
                              value={
                                self.state.ports[i].sensor.description == null
                                  ? ''
                                  : self.state.ports[i].sensor.description
                              }
                              type="text"
                              placeholder={self.props.t('sensor_config_description')}
                              onChange={(event) => self.handleDescriptionChanged(i, event)}
                            />
                          </FormGroup>
                          {/*<FormGroup controlId="units_type">*/}
                          {/*<ControlLabel>Units Type</ControlLabel>*/}
                          {/*<FormControl value={self.state.ports[i].sensor.units}*/}
                          {/*componentClass="select"*/}
                          {/*placeholder="Units Type"*/}
                          {/*onChange={(event) => self.handleUnitsTypeChanged(i, event)}>*/}
                          {/*<option value={'PULSES'}>pulses</option>*/}
                          {/*<option value={'AMPER'}>A</option>*/}
                          {/*<option value={'MILLIAMPER'}>mA</option>*/}
                          {/*<option value={'BAR'}>bar</option>*/}
                          {/*<option value={'LITER'}>liter</option>*/}
                          {/*<option value={'CUBIC_METER'}>m^3</option>*/}
                          {/*<option value={'KWH'}>kWh</option>*/}
                          {/*<option value={'DEG_C'}>&#8451;</option>*/}
                          {/*<option value={'RELATIVE_HUMIDITY'}>RH(%)</option>*/}
                          {/*</FormControl>*/}
                          {/*</FormGroup>*/}
                          {/*<FormGroup controlId="scale">*/}
                          {/*<ControlLabel>Scale</ControlLabel>*/}
                          {/*<FormControl value={self.state.ports[i].sensor.scale == null ? "" : self.state.ports[i].sensor.scale}*/}
                          {/*type="text"*/}
                          {/*placeholder="Scale"*/}
                          {/*onChange={(event) => self.handleScaleChanged(i,event)}/>*/}
                          {/*</FormGroup>*/}
                          {/*<FormGroup controlId="precision">*/}
                          {/*<ControlLabel>Precision</ControlLabel>*/}
                          {/*<FormControl value={self.state.ports[i].sensor.precision == null ? "" : self.state.ports[i].sensor.precision}*/}
                          {/*type="text"*/}
                          {/*placeholder="Precision"*/}
                          {/*onChange={(event) => self.handlePrecisionChanged(i,event)}/>*/}
                          {/*</FormGroup>*/}
                          <FormGroup controlId="model">
                            <ControlLabel>{self.props.t('sensor_config_model')}</ControlLabel>
                            <FormControl
                              value={
                                self.state.ports[i].sensor.model == null
                                  ? ''
                                  : self.state.ports[i].sensor.model
                              }
                              type="text"
                              placeholder={self.props.t('sensor_config_model')}
                              onChange={(event) => self.handleModelChanged(i, event)}
                            />
                          </FormGroup>
                          <FormGroup controlId="serial_number">
                            <ControlLabel>
                              {self.props.t('sensor_config_serial_number')}
                            </ControlLabel>
                            <FormControl
                              value={
                                self.state.ports[i].sensor.serialNumber == null
                                  ? ''
                                  : self.state.ports[i].sensor.serialNumber
                              }
                              type="text"
                              placeholder={self.props.t('sensor_config_serial_number')}
                              onChange={(event) => self.handleSerialNumberChanged(i, event)}
                            />
                          </FormGroup>
                          <FormGroup controlId="manufacturer">
                            <ControlLabel>
                              {self.props.t('sensor_config_manufacturer')}
                            </ControlLabel>
                            <FormControl
                              value={
                                self.state.ports[i].sensor.manufacturer == null
                                  ? ''
                                  : self.state.ports[i].sensor.manufacturer
                              }
                              type="text"
                              placeholder={self.props.t('sensor_config_manufacturer')}
                              onChange={(event) => self.handleManufacturerChanged(i, event)}
                            />
                          </FormGroup>
                          {self.state.ports[i].sensor['@type'] === 'water_meter' && (
                            <Fragment>
                              <FormGroup controlId="diameter">
                                <ControlLabel>
                                  {self.props.t('sensor_config_diameter')}
                                </ControlLabel>
                                <FormControl
                                  value={self.state.ports[i].sensor.diameter}
                                  type="text"
                                  placeholder={self.props.t('sensor_config_diameter')}
                                  onChange={(event) => self.handleDiameterChanged(i, event)}
                                />
                              </FormGroup>
                              <FormGroup controlId="q3">
                                <ControlLabel>Q3</ControlLabel>
                                <FormControl
                                  value={self.state.ports[i].sensor.q3}
                                  type="text"
                                  placeholder="Q3"
                                  onChange={(event) => self.handleQ3Changed(i, event)}
                                />
                              </FormGroup>
                              <FormGroup controlId="r">
                                <ControlLabel>R</ControlLabel>
                                <FormControl
                                  value={self.state.ports[i].sensor.r}
                                  type="text"
                                  placeholder="R"
                                  onChange={(event) => self.handleRChanged(i, event)}
                                />
                              </FormGroup>
                            </Fragment>
                          )}
                          {(self.state.ports[i].sensor['@type'] === 'water_meter' ||
                            self.state.ports[i].sensor['@type'] === 'gas_flow_meter' ||
                            self.state.ports[i].sensor['@type'] === 'generic_digital_sensor' ||
                            self.state.ports[i].sensor['@type'] === 'power_meter') && (
                            <Fragment>
                              {/*<FormGroup controlId="initial_value">*/}
                              {/*<ControlLabel>Initial value</ControlLabel>*/}
                              {/*<FormControl value={self.state.ports[i].sensor.initialValue}*/}
                              {/*type="text"*/}
                              {/*placeholder="Initial value in specified units"*/}
                              {/*onChange={(event) => self.handleInitialValueChanged(i, event)}/>*/}
                              {/*</FormGroup>*/}
                              {/*<FormGroup controlId="pulse_rate">*/}
                              {/*<ControlLabel>Unit per Pulse</ControlLabel>*/}
                              {/*<FormControl value={self.state.ports[i].sensor.pulseRate}*/}
                              {/*type="text"*/}
                              {/*placeholder="Unit per pulse (e.g. m^3/pulse)"*/}
                              {/*onChange={(event) => self.handlePulseRateChanged(i, event)}/>*/}
                              {/*</FormGroup>*/}
                              <FormGroup controlId="price">
                                <ControlLabel>{self.props.t('sensor_config_price')}</ControlLabel>
                                <FormControl
                                  value={self.state.ports[i].sensor.price}
                                  type="text"
                                  placeholder={self.props.t('sensor_config_price')}
                                  onChange={(event) => self.handlePriceChanged(i, event)}
                                />
                              </FormGroup>
                              <FormGroup controlId="currency">
                                <ControlLabel>
                                  {self.props.t('sensor_config_currency')}
                                </ControlLabel>
                                <FormControl
                                  value={self.state.ports[i].sensor.currency}
                                  type="text"
                                  placeholder={self.props.t('sensor_config_currency')}
                                  onChange={(event) => self.handleCurrencyChanged(i, event)}
                                />
                              </FormGroup>
                            </Fragment>
                          )}
                          {(self.state.ports[i].sensor['@type'] === 'generic_analog_sensor' ||
                            self.state.ports[i].sensor['@type'] === 'pressure_sensor' ||
                            self.state.ports[i].sensor['@type'] === 'humidity_sensor' ||
                            self.state.ports[i].sensor['@type'] === 'temperature_sensor' ||
                            self.state.ports[i].sensor['@type'] === 'level_sensor' ||
                            self.state.ports[i].sensor['@type'] === 'weight_meter') && (
                            <Fragment></Fragment>
                          )}
                          <FormGroup controlId="port_type">
                            <ControlLabel>{self.props.t('sensor_config_port_type')}</ControlLabel>
                            <FormControl
                              value={self.state.ports[i]['@type']}
                              componentClass="select"
                              placeholder={self.props.t('sensor_config_port_type')}
                              onChange={(event) => self.handlePortTypeChanged(i, event)}
                            >
                              <option value={'digital_input_port'}>
                                {self.props.t('sensor_config_port_type_digital_input_port')}
                              </option>
                              <option value={'analog_input_port'}>
                                {self.props.t('sensor_config_port_type_analog_input_port')}
                              </option>
                              <option value={'4_20_mA_input_port'}>
                                4-20mA {self.props.t('sensor_config_port_type_analog_input_port')}
                              </option>
                              <option value={'0_5_V_input_port'}>
                                0-5V {self.props.t('sensor_config_port_type_analog_input_port')}
                              </option>
                              <option value={'pulse_s0_input_port'}>
                                {self.props.t('sensor_config_port_type_pulse_s0_input_port')}
                              </option>
                              <option value={'pulse_input_port'}>
                                {self.props.t('sensor_config_port_type_pulse_input_port')}
                              </option>
                              <option value={'on_off_input_port'}>
                                {self.props.t('sensor_config_port_type_on_off_input_port')}
                              </option>
                              <option value={'on_off_output_port'}>
                                {self.props.t('sensor_config_port_type_on_off_output_port')}
                              </option>
                            </FormControl>
                          </FormGroup>

                          <FormGroup controlId="order_number">
                            <ControlLabel>
                              {self.props.t('sensor_config_order_number')}
                            </ControlLabel>
                            <FormControl
                              type="text"
                              placeholder={self.props.t('sensor_config_order_number')}
                              onChange={(event) => self.handleOrderNumberChanged(i, event)}
                              value={self.state.ports[i].sensor.orderNumber}
                            ></FormControl>
                          </FormGroup>

                          {(self.state.ports[i]['@type'] === 'digital_input_port' ||
                            self.state.ports[i]['@type'] === 'pulse_s0_input_port' ||
                            self.state.ports[i]['@type'] === 'pulse_input_port') && (
                            <Fragment>
                              <FormGroup controlId="offset">
                                <ControlLabel>{self.props.t('sensor_config_offset')}</ControlLabel>
                                <FormControl
                                  value={self.state.ports[i].sensor.offset}
                                  type="number"
                                  placeholder={0}
                                  onChange={(event) => self.handleOffsetChanged(i, event)}
                                />
                              </FormGroup>
                            </Fragment>
                          )}

                          <FormGroup controlId="use_in_sensor_aggregation">
                            <ControlLabel>
                              {self.props.t('sensor_config_use_in_sensor_aggregation')}
                            </ControlLabel>
                            <MuiFormControl fullWidth size="small" sx={{ paddingBottom: 1 }}>
                              <InputLabel>
                                {self.props.t('sensor_config_use_in_sensor_aggregation')}
                              </InputLabel>
                              <Select
                                size="small"
                                label={self.props.t('sensor_config_use_in_sensor_aggregation')}
                                value={self.state.ports[i].sensor.useInSensorsAggregation}
                                onChange={(event) =>
                                  self.handleUseInSensorsAggregationChanged(i, event)
                                }
                              >
                                <MenuItem value={true}>
                                  {self.props.t('sensor_config_use_in_sensor_aggregation_true')}
                                </MenuItem>
                                <MenuItem value={false}>
                                  {self.props.t('sensor_config_use_in_sensor_aggregation_false')}
                                </MenuItem>
                              </Select>
                            </MuiFormControl>
                          </FormGroup>

                          <FormGroup controlId="visible">
                            <ControlLabel>{self.props.t('sensor_config_visible')}</ControlLabel>
                            <MuiFormControl fullWidth size="small" sx={{ paddingBottom: 1 }}>
                              <InputLabel>{self.props.t('sensor_config_visible')}</InputLabel>
                              <Select
                                size="small"
                                label={self.props.t('sensor_config_visible')}
                                value={self.state.ports[i].sensor.visible}
                                onChange={(event) => self.handleIsVisibleChanged(i, event)}
                              >
                                <MenuItem value={true}>
                                  {self.props.t('sensor_config_visible_true')}
                                </MenuItem>
                                <MenuItem value={false}>
                                  {self.props.t('sensor_config_visible_false')}
                                </MenuItem>
                              </Select>
                            </MuiFormControl>
                          </FormGroup>

                          <FormGroup>
                            <ControlLabel>{self.props.t('util_rates')}</ControlLabel>
                            <div className="grid grid-cols-4">
                              <div
                                className={clsx(
                                  self.state.utilityRatesUpdated[i]?.id !==
                                    self.state.utilityRatesSnapshot[i]?.id
                                    ? 'col-span-3'
                                    : 'col-span-4'
                                )}
                              >
                                <MuiFormControl fullWidth size="small" sx={{ paddingBottom: 1 }}>
                                  <InputLabel>{self.props.t('util_rates')}</InputLabel>
                                  <Select
                                    size="small"
                                    label={self.props.t('util_rates')}
                                    value={self.state.utilityRatesUpdated[i]?.id || 'none'}
                                    onChange={(event) => {
                                      const utilityRateId = event.target.value;
                                      const utilityRatesUpdated = {
                                        ...self.state.utilityRatesUpdated,
                                      };

                                      if (utilityRateId === 'none') {
                                        delete utilityRatesUpdated[i];
                                        self.setState({
                                          ...self.state,
                                          utilityRatesUpdated: utilityRatesUpdated,
                                        });
                                        return;
                                      }

                                      const utilityRate = self.state.allUtilityRates.find(
                                        (utilityRate) => utilityRate.id === utilityRateId
                                      );
                                      utilityRatesUpdated[i] = utilityRate;
                                      self.setState({
                                        ...self.state,
                                        utilityRatesUpdated: utilityRatesUpdated,
                                      });
                                    }}
                                  >
                                    <MenuItem value={'none'}>{self.props.t('none')}</MenuItem>
                                    {self.state.allUtilityRates.map((utilityRate) => (
                                      <MenuItem value={utilityRate.id}>{utilityRate.name}</MenuItem>
                                    ))}
                                  </Select>
                                </MuiFormControl>
                              </div>
                              {self.state.utilityRatesUpdated[i]?.id !==
                                self.state.utilityRatesSnapshot[i]?.id && (
                                <div className="col-span-1">
                                  <div className="flex">
                                    <IconButton
                                      onClick={(event) => {
                                        const updated = { ...self.state.utilityRatesUpdated };
                                        const snapshot = { ...self.state.utilityRatesSnapshot };

                                        if (updated[i]?.id) {
                                          sensorsConfigClient.linkUtilityRateToDeviceSensor(
                                            self.props.match.params.deviceNumber,
                                            i,
                                            updated[i].id,
                                            () => {
                                              snapshot[i] = updated[i];

                                              self.setState({
                                                ...self.state,
                                                utilityRatesSnapshot: snapshot,
                                              });
                                            },
                                            self.errorCallback
                                          );
                                        } else {
                                          sensorsConfigClient.unlinkUtilityRateFromDeviceSensor(
                                            self.props.match.params.deviceNumber,
                                            i,
                                            () => {
                                              snapshot[i] = updated[i];

                                              self.setState({
                                                ...self.state,
                                                utilityRatesSnapshot: snapshot,
                                              });
                                            },
                                            self.errorCallback
                                          );
                                        }
                                      }}
                                    >
                                      <SaveIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              )}
                            </div>
                          </FormGroup>

                          {SensorsUtil.isOnOffSensor(self.state.ports[i].sensor) && (
                            <FormGroup>
                              <ControlLabel>
                                {self.props.t('sensor_config_value_labels')}
                              </ControlLabel>
                              <div className="flex flex-col gap-2">
                                <div className="flex gap-2">
                                  <FormControl
                                    className="bg-white aspect-square w-fit font-bold text-lg items-center pointer-events-none"
                                    disabled
                                    value={0}
                                  />
                                  <div className="text-3xl pointer-events-none">→</div>
                                  <FormControl
                                    placeholder={self.props.t('sensor_config_label_for_value_zero')}
                                    onChange={(event) => self.handleValueLabelsChanged(i, event, 0)}
                                    value={self.state.ports[i].sensor.valueLabels?.[0] || ''}
                                  />
                                </div>
                                <div className="flex gap-2">
                                  <FormControl
                                    className="bg-white aspect-square w-fit font-bold text-lg items-center pointer-events-none"
                                    disabled
                                    value={1}
                                  />
                                  <div className="text-3xl pointer-events-none">→</div>
                                  <FormControl
                                    placeholder={self.props.t('sensor_config_label_for_value_one')}
                                    onChange={(event) => self.handleValueLabelsChanged(i, event, 1)}
                                    value={self.state.ports[i].sensor.valueLabels?.[1] || ''}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          )}

                          {(self.state.ports[i]['@type'] === 'on_off_input_port' ||
                            self.state.ports[i]['@type'] === 'on_off_output_port') && (
                            <FormGroup controlId="invertedReadStateValue">
                              <ControlLabel>
                                {self.props.t('sensor_config_inverted_read_state_value')}
                              </ControlLabel>
                              <MuiFormControl fullWidth size="small" sx={{ paddingBottom: 1 }}>
                                <InputLabel>
                                  {self.props.t('sensor_config_inverted_read_state_value')}
                                </InputLabel>
                                <Select
                                  size="small"
                                  label={self.props.t('sensor_config_inverted_read_state_value')}
                                  value={self.state.ports[i].sensor.invertedReadStateValue || false}
                                  onChange={(event) =>
                                    self.handleSensorInvertedReadStateChanged(i, event)
                                  }
                                >
                                  <MenuItem value={true}>
                                    {self.props.t('sensor_config_inverted_read_state_value_true')}
                                  </MenuItem>
                                  <MenuItem value={false}>
                                    {self.props.t('sensor_config_inverted_read_state_value_false')}
                                  </MenuItem>
                                </Select>
                              </MuiFormControl>
                            </FormGroup>
                          )}

                          {self.state.ports[i].sensor['@type'] === 'on_off_auto_output_sensor' && (
                            <Fragment>
                              <ControlLabel>
                                {self.props.t('sensor_config_sensor_group_name')}
                              </ControlLabel>
                              <FormGroup controlId="sensor_group_name">
                                <FormControl
                                  value={self.state.ports[i].sensor.groupName}
                                  type="text"
                                  placeholder={self.props.t('sensor_config_sensor_group_name')}
                                  onChange={(event) => self.handleSensorGroupNameChanged(i, event)}
                                />
                              </FormGroup>
                            </Fragment>
                          )}
                          {(self.state.ports[i]['@type'] === 'analog_input_port' ||
                            self.state.ports[i]['@type'] === '4_20_mA_input_port' ||
                            self.state.ports[i]['@type'] === '0_5_V_input_port') && (
                            <Fragment>
                              <FormGroup
                                controlId="price"
                                validationState={
                                  self.state.formulaValidity.get(i) === false ? 'error' : null
                                }
                              >
                                <ControlLabel>
                                  {self.props.t('sensor_config_port_conversion_formula')}
                                </ControlLabel>
                                <FormControl
                                  value={self.state.ports[i].formula}
                                  type="text"
                                  placeholder={self.props.t('sensor_config_formula')}
                                  onChange={(event) => self.handlePortFormulaChanged(i, event)}
                                />
                              </FormGroup>
                            </Fragment>
                          )}

                          {
                            <Fragment>
                              <FlowConfiguration
                                flowTimeUnit={self.state.ports[i].sensor.flowUnit?.timeUnits || ''}
                                flowUnit={self.state.ports[i].sensor.flowUnit?.units || ''}
                                coefficient={self.state.ports[i].sensor.flowConversionCoefficient}
                                value={self.state.ports[i].sensor.r}
                                handleFlowCoefficientChanged={self.handleFlowCoefficientChanged}
                                handleFlowTimeUnitsChanged={self.handleFlowTimeUnitsChanged}
                                handleFlowUnitsChanged={self.handleFlowUnitsChanged}
                                sensorIndex={i}
                              />
                            </Fragment>
                          }
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                );
              })}
              <Button
                onClick={this.handleSubmit.bind(this)}
                disabled={
                  this.state.saveDisabled ||
                  this.state.hasInvalidFormula ||
                  !this.state.isFormulaValidated
                }
              >
                {this.props.t('button_save')}
              </Button>
              <Snackbar
                open={this.state.open}
                message={this.state.snackbarMsg}
                autoHideDuration={5000}
                onClose={this.handleRequestClose}
              />
            </form>
          </div>
        </Header>
      );
    } else {
      return (
        <Header>
          <div>
            <CircularProgress size={80} thickness={5} />
          </div>
        </Header>
      );
    }
  }

  componentDidMount() {
    this.loadDeviceConfigFromServer();
    this.loadUtilityRateForDeviceFromServer();
    this.loadUtilityRateForCompanyFromServer();
    this.intervalId = setInterval(this.loadConfiguredFlag, 5000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.deviceNumber !== prevProps.match.params.deviceNumber) {
      this.setState({
        ports: null,
      });
      this.loadDeviceConfigFromServer();
      this.loadUtilityRateForDeviceFromServer();
    }
    if (
      this.props.match.params.deviceNumber !== prevProps.match.params.deviceNumber ||
      this.props.company.id !== prevProps.company.id
    ) {
      this.loadUtilityRateForCompanyFromServer();
    }
    if (this.state.formulaValidity !== prevState.formulaValidity) {
      this.setState({
        ...this.state,
        hasInvalidFormula: Array.from(this.state.formulaValidity.values()).some(
          (sensorFormulaValidity) => sensorFormulaValidity === false
        ),
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  loadUtilityRateForCompanyFromServer() {
    const companyId = this.props.company.id || this.jwtValidator.decodedToken.companyId;
    sensorsConfigClient.getUtilityRatesForCompany(
      companyId,
      (allUtilityRate) => {
        this.setState({ allUtilityRates: allUtilityRate });
      },
      this.errorCallback
    );
  }

  loadUtilityRateForDeviceFromServer() {
    sensorsConfigClient.getUtilityRatesForDevice(
      this.props.match.params.deviceNumber,
      (utilityRates) => {
        this.setState({
          utilityRatesSnapshot: utilityRates,
          utilityRatesUpdated: utilityRates,
        });
      },
      this.errorCallback
    );
  }

  loadDeviceConfigFromServer() {
    timeZonesClient.getTimeZones((timezones) => {
      devicesClient.getDevice(
        this.props.match.params.deviceNumber,
        (device) => {
          sensorsConfigClient.getBasicSensorsConfig(
            this.props.match.params.deviceNumber,
            (sensorConf) => {
              sensorsConfigClient.getPortsConfig(
                this.props.match.params.deviceNumber,
                (ports) => {
                  for (let i = 0; i <= 5; i++) {
                    ports[i].enabled = sensorConf[i] != null && sensorConf[i].enabled;
                    if (ports[i].sensor == null) {
                      ports[i].sensor = {};
                    }
                  }
                  this.setState({
                    deviceName: device.name,
                    customerInfo: device.customerInfo,
                    ports: ports,
                  });
                },
                this.errorCallback
              );
            },
            this.errorCallback
          );
        },
        this.errorCallback
      );
    }, this.errorCallback);
  }

  handleNameChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, name: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleSensorGroupNameChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, groupName: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleSensorInvertedReadStateChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, invertedReadStateValue: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleDescriptionChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, description: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleSensorTypeChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, '@type': event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handlePortTypeChanged(i, event) {
    let portType = event.target.value;
    let state = {
      ports: { ...this.state.ports, [i]: { ...this.state.ports[i], '@type': portType } },
    };
    this.setState(state);
  }

  handleValueLabelsChanged(i, event, value) {
    let valueLabel = event.target.value;
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: {
            ...this.state.ports[i].sensor,
            valueLabels: {
              ...this.state.ports[i].sensor.valueLabels,
              [value]: valueLabel.length > 0 ? valueLabel : null,
            },
          },
        },
      },
    };

    this.setState(state);
  }

  handleModelChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, model: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleFlowUnitsChanged(i, value) {
    let flowUnit = null;
    const hasFlowUnit = this.state.ports[i].sensor.flowUnit !== null;

    if (value !== null) {
      flowUnit = hasFlowUnit
        ? { ...this.state.ports[i].sensor.flowUnit, units: value }
        : { units: value, timeUnits: null };
    }

    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: {
            ...this.state.ports[i].sensor,
            flowUnit: flowUnit,
          },
        },
      },
    };

    this.setState(state);
  }

  handleFlowTimeUnitsChanged(i, value) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: {
            ...this.state.ports[i].sensor,
            flowUnit: this.state.ports[i].sensor.flowUnit
              ? {
                  ...this.state.ports[i].sensor.flowUnit,
                  timeUnits: value,
                }
              : {
                  units: null,
                  timeUnits: value,
                },
          },
        },
      },
    };

    this.setState(state);
  }

  handleIsVisibleChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: {
            ...this.state.ports[i].sensor,
            visible: event.target.value,
          },
        },
      },
    };
    this.setState(state);
  }

  handleOrderNumberChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: {
            ...this.state.ports[i].sensor,
            orderNumber: event.target.value,
          },
        },
      },
    };
    this.setState(state);
  }

  handleUseInSensorsAggregationChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: {
            ...this.state.ports[i].sensor,
            useInSensorsAggregation: event.target.value,
          },
        },
      },
    };
    this.setState(state);
  }

  handleFlowCoefficientChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: {
            ...this.state.ports[i].sensor,
            flowConversionCoefficient: event.target.value,
          },
        },
      },
    };

    this.setState(state);
  }

  // handleScaleChanged(i, event) {
  //     let state = {ports: {...this.state.ports, [i] : {...this.state.ports[i], sensor: {...this.state.ports[i].sensor, scale: event.target.value}}}};
  //     this.setState(state)
  // }

  // handlePrecisionChanged(i, event) {
  //     let state = {ports: {...this.state.ports, [i] : {...this.state.ports[i], sensor: {...this.state.ports[i].sensor, precision: event.target.value}}}};
  //     this.setState(state)
  // }

  // handleUnitsTypeChanged(i, event) {
  //     let state = {ports: {...this.state.ports, [i] : {...this.state.ports[i], sensor: {...this.state.ports[i].sensor, units: event.target.value}}}};
  //     this.setState(state)
  // }

  handleSerialNumberChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, serialNumber: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleIconNameChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, iconName: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleManufacturerChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, manufacturer: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleDiameterChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, diameter: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleOffsetChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, offset: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleQ3Changed(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, q3: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleRChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, r: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  // handleInitialValueChanged(i, event) {
  //     let state = {ports: {...this.state.ports, [i] : {...this.state.ports[i], sensor: {...this.state.ports[i].sensor, initialValue: event.target.value}}}};
  //     this.setState(state)
  // }

  handlePulseRateChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, pulseRate: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handlePriceChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, price: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  handleCurrencyChanged(i, event) {
    let state = {
      ports: {
        ...this.state.ports,
        [i]: {
          ...this.state.ports[i],
          sensor: { ...this.state.ports[i].sensor, currency: event.target.value },
        },
      },
    };
    this.setState(state);
  }

  // handleSensorFormulaChanged(i, event) {
  //     let state = {ports: {...this.state.ports, [i] : {...this.state.ports[i], sensor: {...this.state.ports[i].sensor, formula: event.target.value}}}};
  //     this.setState(state)
  // }
  //
  handlePortFormulaChanged = async (i, event) => {
    const formula = event.target.value;

    let state = {
      ports: {
        ...this.state.ports,
        [i]: { ...this.state.ports[i], formula: formula },
      },
      isFormulaValidated: false,
    };
    this.setState(state);

    this.handlePortFormulaValidation(i, formula);
  };

  handlePortFormulaValidation = _.debounce(async (i, formula) => {
    const paramsRegex = /(S(?:1[0-3]|[0-9])|x)/g;

    const formulaParams = formula.match(paramsRegex);
    const uniqueFormulaParams = _.uniq(formulaParams);

    const formulaValidationResult = await formulaValidatorQueryClient.validateFormula(
      formula,
      uniqueFormulaParams
    );

    if (formulaValidationResult.valid === false) {
      this.setState({ open: true, snackbarMsg: formulaValidationResult.errorMessage });
    }

    this.setState((prevState) => {
      const formulaValidity = new Map(prevState.formulaValidity);
      formulaValidity.set(i, formulaValidationResult.valid);

      return {
        ...prevState,
        formulaValidity,
        isFormulaValidated: true,
      };
    });
  }, 1000);
}

function mapStateToProps(state) {
  return {
    company: state.company,
  };
}

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(SensorsConfig)));
